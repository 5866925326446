import highLight from '../../../eventHandlers/helpers/highLight';
import onPaste from '../../../eventHandlers/onPaste';
import paragraph from '../../../eventHandlers/helpers/paragraph';
import getSureElement from './getSureElement';
import { onAddingChange } from '../../../eventHandlers/onChange';
import {
  contentEditableClass, tableClass, containerClass, dotContainerClass, dotBodyClass,
} from '../../../nameGenerator';
import addTable from './addTable';
import { stateNames } from '../../../index';
import addToHistory from './addToHistory';
import getFocusPath from './getFocusPath';
import { textParent, currentTransfer, currentMoveable } from '../../../eventHandlers/changeHistory';
import { replaceCaret } from '../../../eventHandlers/helpers/moveFocus';

export const saveSelection = (cut) => {
  const selectedItems = document.querySelectorAll('.derassi-selected');
  if (!selectedItems) return;
  const saved = document.createElement('div');
  saved.classList.add('derassi-copied');
  Array.from(selectedItems).forEach((selected) => {
    saved.append(selected.cloneNode(true));
    if (cut) {
      selected.remove();
    }
  });
  // currentTransfer.element = saved;
  if (saved.innerText) {
    navigator.clipboard.writeText(saved.innerText);
  }
};

export const cutSelected = (state, dispatch) => {
  saveSelection(true);
};

export const copySelected = (state, dispatch) => {
  saveSelection(false);
};

export const pasteSelected = (state, dispatch) => {
  onPaste({ event: null, state, dispatch });
};
export const pasteSelectedUnformatted = (state, dispatch) => {
  onPaste({
    event: null, state, dispatch, unformatted: true,
  });
};

export const deleteSelected = (state, dispatch) => {
  const moveable = currentMoveable.element;
  if (moveable) {
    const existing = document.querySelector('.moveable-control-box');
    if (existing) existing.remove();
    moveable.remove();
    return;
  }
  const selectedItems = document.querySelectorAll('.derassi-selected');
  selectedItems.forEach((selected) => {
    const parent = selected.parentElement;
    selected.remove();
    if (parent && !parent.textContent) {
      parent.remove();
    }
  });
};
