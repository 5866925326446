import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Insert from '@material-ui/icons/InsertLink';
import uniqid from 'uniqid';
import translate, { translateWords } from '../../../../translate';
import addImage from '../../helpers/addImage';
import { textParent } from '../../../../eventHandlers/changeHistory';
import { dotContainerClass } from '../../../../nameGenerator';


export default (props) => {
  const { state, dispatch, setMenuOpen } = props;
  const { menuFontFamily, lang } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setMenuOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleInsert = () => {
    handleClose();
    const { parent } = textParent;
    if (!parent) return;
    const refContainer = parent.closest(dotContainerClass) as HTMLElement;
    addImage({
      data: {
        src: url, id: uniqid(),
      },
      // newInsertion: true,
      state,
      dispatch,
      refContainer,
    });
    //  handleInsertImage({ image: img || blank });
  };
  return (
    <div>
      <ListItem
        dense
        button
        id="open"
        key="open"
        onClick={handleClickOpen}
      >
        <ListItemIcon><Insert /></ListItemIcon>
        <ListItemText>
          <div style={{ fontFamily: menuFontFamily }}>
            {translateWords.imageUrl[lang]}
          </div>
        </ListItemText>
      </ListItem>
      <Dialog open={open} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          <DialogContentText style={{ fontFamily: menuFontFamily, minWidth: 'auto' }}>
            {lang <= 2 ? 'URL እዚህ ይለጥፉ' : 'Paste Url Here'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="URL"
            type="text"
            fullWidth
            onChange={e => setUrl(e.currentTarget.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button style={{ fontFamily: menuFontFamily }} onClick={handleClose}>
            {translateWords.cancel[lang]}
          </Button>
          <Button style={{ fontFamily: menuFontFamily }} onClick={handleInsert}>
            {translateWords.enter[lang]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
