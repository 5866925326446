import _ from 'lodash';
import html2canvas from 'html2canvas';
import base64url from 'base64url';
import { StayCurrentPortraitOutlined } from '@material-ui/icons';
import FileSaver, { saveAs } from 'file-saver';
import update from './update';
import * as ng from '../nameGenerator';
import { stateNames } from '../index';
import historyReducer, { textParent, currentFile } from '../eventHandlers/changeHistory';
import assembledPages from './assembledPages';
import devLog from '../devLog';
import { translateWords } from '../translate';


const now = _.now();
let firstPageLength = 0;
let shouldTakePicture = false;
let base64 = '';

const updateLocal = async (body, state, dispatch) => {
  dispatch({ type: stateNames.saving, saving: true });
  const { saving } = state;
  try {
    localStorage.setItem('derassiDoc', JSON.stringify(body || {}));
    setTimeout(() => { // delay for showing spinner on local saving
      dispatch({ type: stateNames.saving, saving: false });
      Promise.resolve();
    }, 3000);
  } catch (err) {
    Promise.reject(err);
  }
};
export const updateMeta = async ({
  state, dispatch,
}): Promise<any> => {
  const { lang, currentTitle, currentID } = state;
  // const currentTitle = currentFile.title || translateWords.title[lang];
  const imageData = base64url.fromBase64(base64);
  const image = imageData.split(',')[1];
  const body = {
    name: currentTitle,
    contentHints: {
      thumbnail: {
        image,
        mimeType: 'image/png',
      },
    },
  };
  // const body = { ...oldBody, ...picture };
  // if (now <= _.now() + 3000) {
  dispatch({ type: stateNames.saving, saving: true });
  update(body, currentID, true).then((result) => {
    dispatch({ type: stateNames.saving, saving: false });
    if (result.error) {
      devLog(result.error.message);
    } else {
      devLog('save successful');
    }
    // now = _.now();
    // if (debounced) debounced.cancel();
    dispatch({ type: stateNames.saving, saving: false });
  });
  // }
};

export default async ({ id, state, dispatch }) => {
  // const { currentTitle, currentID } = state as InitialState;
  dispatch({ type: stateNames.saving, saving: true });
  const firstPage = document.querySelector('.derassi-page') as HTMLElement;
  // const { isSignedIn } = state as InitialState;
  const { isSignedIn, localFile } = state as InitialState;
  if (firstPage && firstPage.textContent && isSignedIn && !localFile) {
    const str = firstPage.innerHTML.toString();
    if (Math.abs(firstPageLength - str.length) > 50) {
      shouldTakePicture = true;
    } else {
      shouldTakePicture = false;
    }
    firstPageLength = str.length;
  }

  if (shouldTakePicture && firstPage) {
    const { width, height } = window.getComputedStyle(firstPage);
    const { scrollX, scrollY } = window;
    html2canvas(firstPage, {
      width: parseFloat(width), height: parseFloat(height), scrollX: -scrollX, scrollY: -scrollY,
    }).then((canvas) => {
      const dataUrl = (canvas as HTMLCanvasElement).toDataURL();
      base64 = dataUrl;
      updateMeta({ state, dispatch });
      dispatch({ type: stateNames.saving, saving: false });
    });
  }
  const body = assembledPages(state, dispatch);
  // if (now <= _.now() + 3000) {
  dispatch({ type: stateNames.saving, saving: true });
  if (isSignedIn) {
    try {
      update(body, id, false).then((res) => {
        dispatch({ type: stateNames.saving, saving: false });
        if (res.error) {
          devLog(`did not save remove ${res.error.message}`);
          updateLocal(body, state, dispatch).then(() => {
            devLog('..updated local, part 0');
            // dispatch({ type: stateNames.saving, saving: false });
          });
        }
      });
    } catch (err) {
      devLog(`error in saving, line 102, 'not logged in' or ${err}`);
      updateLocal(body, state, dispatch).then(() => {
        devLog('..updated local, part 1');
        // dispatch({ type: stateNames.saving, saving: false });
      });
    }
  } else {
    updateLocal(body, state, dispatch).then(() => {
      devLog('..updated local, part 2');
      // dispatch({ type: stateNames.saving, saving: false });
    });
  }
  //   now = _.now();
  // }
};


export const downloadPages = (state, dispatch) => {
  const body = assembledPages(state, dispatch);
  const { currentID, currentTitle } = state as InitialState;
  const blob = new Blob([JSON.stringify(body)], { type: 'application/json' });
  FileSaver.saveAs(blob, `${currentTitle}.ደራሲ.json`);
};
