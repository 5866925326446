
import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import _ from 'lodash';
import { translateWords } from '../../../../translate';
import EditChart from '../../helpers/beforeChartAdd';

export default (props): JSX.Element => {
  return (
    <div>
      <Grid container direction="row">
        <Grid
          id="addChart"
          key="addChart"
          xs
        >
          <EditChart key="editChart" name={translateWords.edit} id="editChart" {...props} />
        </Grid>
      </Grid>
    </div>
  );
};
