import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {
  FormGroup, DialogContentText,
  TextField, FormControl, ListItem, ListItemIcon, ListItemText, Grid, Box,
} from '@material-ui/core';
import {
  ShowChart, MultilineChart, BarChart, PieChart, DonutLarge, OpenInBrowser,
} from '@material-ui/icons';
import Chart from '@material-ui/icons/InsertChart';
import _ from 'lodash';
import { translateWords } from '../../../translate';
import onKeyDown from '../../../eventHandlers/onKeyDown';
import addChart from './addChart';
import { textParent, currentMoveable } from '../../../eventHandlers/changeHistory';
import { dotContainerClass, chartClass } from '../../../nameGenerator';
import { UpperCased } from './changeToUpper';
import devLog from '../../../devLog';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    fontFamily: props => props.menuFontFamily,
  },
  find: {
    fontFamily: props => props.menuFontFamily,
    right: 2,
  },
  input: {
    minWidth: 400,
    fontFamily: props => props.menuFontFamily,
  },
}));

const PaperComponent = (props): JSX.Element => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);


export default (props): JSX.Element => {
  const {
    setMenuOpen, name, state, dispatch, closeSubMenu,
  } = props;
  const { menuFontFamily, lang } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  // const [editing, setEditing] = React.useState({ isEditing: false, containerId: '' });
  const [chart, setChart] = React.useState({
    chartType: 'line',
    title: '',
    valueString: '',
    labelString: '',
    error: '',
    // existingDatasets: [],
  });
  const labelRef = React.useRef<HTMLInputElement>(null);
  const valueRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (labelRef && labelRef.current) {
      labelRef.current.style.fontFamily = menuFontFamily;
    }
    if (valueRef && valueRef.current) {
      valueRef.current.style.fontFamily = menuFontFamily;
    }
    if (titleRef && titleRef.current) {
      titleRef.current.style.fontFamily = menuFontFamily;
    }
  });

  const classes = useStyles({ menuFontFamily });

  const handleTitle = (event): void => {
    event.stopPropagation();
    onKeyDown({ event, ...props });
    if (titleRef && titleRef.current) {
      setChart({ ...chart, title: event.target.value });
    }
  };
  const handleClickOpen = (event): void => {
    event.stopPropagation();
    setOpen(true);
    // bring focus out of documents
    if (labelRef && labelRef.current) {
      labelRef.current.focus();
    }
    if (setMenuOpen) {
      setMenuOpen(false);
    }
  };
  const handleClose = (event): void => {
    event.stopPropagation();
    setOpen(false);
  };
  const labelKeyDown = (event): void => {
    event.stopPropagation();
    onKeyDown({ event, ...props });
    setChart({ ...chart, labelString: event.target.value });
  };
  const valueKeyDown = (event): void => {
    event.stopPropagation();
    onKeyDown({ event, ...props });
    setChart({ ...chart, valueString: event.target.value });
  };
  const handleContinue = async (data): Promise<Record<string, any>> => {
    const [values, labels] = data;
    const { chartType, title, error } = chart;
    let validated = true;
    if (labels.length < values.length) {
      validated = false;
      setChart({
        ...chart,
        error: translateWords.errorSizeTooSmall[lang],
      });
    }
    if (values.length < labels.length) {
      validated = false;
      setChart({
        ...chart,
        error: translateWords.errorSizeTooSmall[lang],
      });
    }
    for (let index = 0; index < values.length; index += 1) {
      const val = values[index];
      // const isNan = _.isNaN(val);
      if (_.isNaN(_.toNumber(val))) {
        validated = false;
        setChart({
          ...chart,
          error: `${values[index]} @ ${index} ${translateWords.errorTypeNumber[lang]}`,
        });
        break;
      }
    }
    if (validated) {
      const newData = {
        title,
        type: chartType,
        data: {
          labels,
          type: chartType,
          datasets: [
            // ...chart.existingDatasets,
            {
              chartType,
              values: _.map(values, x => _.toNumber(x)),
              name: title,
            }],
        },
      };
      return Promise.resolve(newData);
    }
    return Promise.reject(new Error(error));
  };
  const drawChart = (data): void => {
    const { parent } = textParent;
    if (parent) {
      let refContainer = parent.closest(dotContainerClass) as HTMLElement;
      let newInsertion = true;
      let modifiedData = data;
      const moveable = currentMoveable.element;
      if (moveable && moveable.classList.contains(chartClass)) {
        const exstingChart = JSON.parse(moveable.dataset.chart || '');
        const existingData = exstingChart.data;
        const { datasets, type } = existingData;
        if (type === 'line' || type === 'bar') {
          modifiedData = { ...data, type: 'axis-mixed', datasets: [...data.data.datasets, ...datasets] };
          newInsertion = false;
        }
        refContainer = moveable;
      }
      addChart({
        data: modifiedData, refContainer, newInsertion, state, dispatch,
      });
    }
    // insertChart({ data, ...props });
    // }
    // reset all
    setChart({
      chartType: 'line',
      title: 'title',
      labelString: '',
      valueString: '',
      error: '',
    });
    setOpen(false);
  };

  const handleFromComputer = (e) => {
    const csv = e.target.files[0];
    if (csv.text) {
      csv.text().then((res) => {
        const lines = _.split(res, '\n');
        const data = _.map(lines, l => l.split(/[;,]/));
        const values = data[0];
        const labels = data[1];
        handleContinue([values, labels]).then((result) => {
          drawChart(result);
        }).catch(err => devLog(err));
      });
    }
  };

  return (
    <div>
      <ListItem
        dense
        button
        onClick={handleClickOpen}
        onMouseEnter={closeSubMenu}
      >
        <ListItemIcon><Chart /></ListItemIcon>
        <ListItemText>
          <div style={{ fontFamily: menuFontFamily }}>
            {lang <= 2 ? name[lang] : name[lang]}
          </div>
        </ListItemText>
      </ListItem>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div style={{ fontFamily: menuFontFamily }}>
            { lang <= 2 ? name[lang] : name[lang] }
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: menuFontFamily }}>
            { translateWords.pastChart[lang]}
          </DialogContentText>
          <FormGroup>
            <TextField
              inputRef={titleRef}
              onKeyDown={(e): void => handleTitle(e)}
              margin="dense"
              label={(
                <div style={{ fontFamily: menuFontFamily }}>
                  { translateWords.title[lang]
                    }
                </div>
                  )}
              variant="outlined"
            />
          </FormGroup>
          <Grid container direction="row">
            <Button startIcon={<BarChart />} variant={chart.chartType === 'bar' ? 'outlined' : 'text'} disabled={chart.chartType === 'bar'} onClick={() => setChart({ ...chart, chartType: 'bar' })}>
              { lang <= 2 
                ? (<UpperCased word={translateWords.bar[lang]} state={state} />)
                : translateWords.bar[lang]}
            </Button>
            <Button startIcon={<ShowChart />} variant={chart.chartType === 'line' ? 'outlined' : 'text'} disabled={chart.chartType === 'line'} onClick={() => setChart({ ...chart, chartType: 'line' })}>
              {lang <= 2 
                ? (<UpperCased word={translateWords.line[lang]} state={state} />)
                : translateWords.line[lang]}
            </Button>
            <Button startIcon={<MultilineChart />} variant={chart.chartType === 'area' ? 'outlined' : 'text'} disabled={chart.chartType === 'area'} onClick={() => setChart({ ...chart, chartType: 'area' })}>
              { lang <= 2 
                ? (<UpperCased word={translateWords.area[lang]} state={state} />)
                : translateWords.area[lang]}
            </Button>
            <Button startIcon={<PieChart />} variant={chart.chartType === 'pie' ? 'outlined' : 'text'} disabled={chart.chartType === 'pie'} onClick={() => setChart({ ...chart, chartType: 'pie' })}>
              {lang <= 2 
                ? (<UpperCased word={translateWords.pie[lang]} state={state} />)
                : translateWords.pie[lang]}
            </Button>
            <Button startIcon={<DonutLarge />} variant={chart.chartType === 'donut' ? 'outlined' : 'text'} disabled={chart.chartType === 'donut'} onClick={() => setChart({ ...chart, chartType: 'donut' })}>
              { lang <= 2 
                ? (<UpperCased word={translateWords.donut[lang]} state={state} />)
                : translateWords.donut[lang]}
            </Button>
            {/* <Button startIcon={<BubbleChartOutlined />} disabled={chart.chartType === 'heatmap'} onClick={() => setChart({ ...chart, chartType: 'heatmap' })}>
              { menuToggle
                ? (<UpperCased word={translateWords.heatMap[lang]} state={state} />)
                : translateWords.heatMap[lang]}
            </Button> */}
          </Grid>
          <br />
          <label htmlFor="fileupload">
            <span
              className="derassi-upload-button"
              style={{
                fontFamily: menuFontFamily,
              }}
            >
              {translateWords.fromComputer[lang] }
              {<OpenInBrowser style={{ transform: 'translate(4px,7px)' }} />}
            </span>
            <input onChange={e => handleFromComputer(e)} id="fileupload" name="fileupload" style={{ opacity: 0 }} type="file" accept=".csv" />
          </label>
          <Box style={{
            fontFamily: menuFontFamily,
            color: 'tomato',
            paddingTop: 17,
          }}
          >
            {chart.error}
          </Box>

          {/* <Button type endIcon={<OpenInBrowser />} variant="contained" color="primary"> from file </Button> */}
          <FormGroup>
            <FormControl>
              <TextField
                onKeyDown={(e): void => valueKeyDown(e)}
                margin="dense"
                inputRef={valueRef}
                label={(
                  <div style={{ fontFamily: menuFontFamily }}>
                    { translateWords.yValue[lang]}
                  </div>
                  )}
                helperText={(
                  <span style={{ fontFamily: menuFontFamily }}>numbers only</span>)}
                type="text"
                fullWidth
              />
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl>
              <TextField
                onKeyDown={(e): void => labelKeyDown(e)}
                margin="dense"
                inputRef={labelRef}
                label={(
                  <div style={{ fontFamily: menuFontFamily }}>
                    { translateWords.xValue[lang]
                      }
                  </div>
                    )}
                helperText={(<span style={{ fontFamily: menuFontFamily }}>labels</span>)}
                type="text"
                fullWidth
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} color="secondary" onClick={(e): void => handleClose(e)}>
            { translateWords.cancel[lang]}
          </Button>
          <Button
            className={classes.button}
            color="primary"
            onClick={() => handleContinue([
              _.split(chart.valueString, ','),
              _.split(chart.labelString, ','),
            ]).then((result) => {
              drawChart(result);
            }).catch(err => devLog(err))
          }
          >
            { translateWords.enter[lang] }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
