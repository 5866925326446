import _ from 'lodash';
import { Pages } from '@material-ui/icons';
import {
  moveLastImage,
  moveLastChart,
  moveLineByLine,
  moveLastList,
  moveLastTable,
  moveLastUnformatted,
  moveLastAny,
} from './helpers/moveForward';
import {
  moveBackLastImage,
  moveBackLastChart,
  moveBackLineByLine,
  moveBackLastList,
  moveBackLastTable,
  moveBackLastUnformatted,
  moveBackLastAny,
} from './helpers/moveBackward';

import paragraph from './helpers/paragraph';
// import { appendPage, removePage } from '../components/editor/body/pageManager';
import { moveCaretInside, replaceCaret } from './helpers/moveFocus';
import changeState, { textParent, undoState } from './changeHistory';
import * as ng from '../nameGenerator';
// import { scrollToView } from '../components/editor/helpers/reFocus';
import addPage from './helpers/addPage';
import { stateNames } from '..';

const focusAndScroll = (target) => {
  const sel = document.getSelection();
  if (!sel) return;
  const { focusNode, focusOffset, anchorOffset } = sel;
  if (!focusNode) return;
  const thisBody = target.closest(ng.dotBodyClass);
  if (thisBody && thisBody.firstElementChild) {
    if (anchorOffset === 0 && thisBody.firstElementChild.contains(focusNode)) {
      const prevPage = ng.getPrevPage(target.id);
      if (prevPage) {
        if (prevPage && prevPage.lastElementChild) {
          if (prevPage.lastElementChild.textContent) {
            replaceCaret(prevPage.lastElementChild);
          } else {
            moveCaretInside(prevPage.lastElementChild);
          }
        }
      }
      // return;
    }
  }


  const parent = focusNode.nodeType === Node.TEXT_NODE ? focusNode.parentElement : focusNode;
  if (parent) {
    //  const para = (parent as HTMLElement).closest(ng.dotPageClass);
    const container = (parent as HTMLElement).closest(ng.dotContainerClass);
    if (container) {
      let index = 0;
      if (parent.childNodes) {
        const nodes = Array.from(parent.childNodes) as Node[];
        index = nodes.indexOf(focusNode);
      }
      textParent.parent = parent as HTMLElement;
      textParent.focusOffset = focusOffset;
      textParent.index = index;
    }
    (parent as HTMLElement).scrollIntoView({ block: 'center', inline: 'center' });
  }
};

/**
 * Listens to backSpacing (deletion event of onInput Event) and moves lines
 * from next page </br>
 * 1. If current focus is above 80% of the page delete last page </br>
 * 2. If the focus is at the very begining of page, move foucs up
 * @param {HTMLElement} target - the page target of current focused page
 * @param {Function} dispatch - the global React.reducer
 * function to dispatch when nextpage reached deletion
 */

export const onDeletingChange = (props) => {
  const { target, state, dispatch } = props;
  if (!target) return;
  focusAndScroll(target);
  const { offsetHeight } = target;
  if (!offsetHeight) return;
  const flexBox = target.closest('[id*=box]');
  if (!flexBox) return;
  const thisPage = target.closest(ng.dotPageClass);
  const nextPage = ng.getNextPage(thisPage.id);
  const { height } = window.getComputedStyle(flexBox);
  const headerFooter = (target as HTMLElement).classList.contains(ng.headerFooterClass);
  if (headerFooter) return;
  if (!nextPage) return;

  const body = nextPage.querySelector(ng.dotBodyClass);
  const { firstElementChild } = body as HTMLElement;
  // const { lastElementChild } = target;
  if (!firstElementChild) return;
  // const range = document.createRange();
  // range.setStart(firstElementChild, 0);
  // range.setEnd(lastElementChild, 0);

  const paragraphContainer = firstElementChild.classList.contains('derassi-paragraph');
  const imageContainer = firstElementChild.classList.contains('derassi-image');
  const chartContainer = firstElementChild.classList.contains('derassi-chart');
  const tableContainer = firstElementChild.classList.contains('derassi-table');
  const listContainer = firstElementChild.classList.contains('derassi-list');
  const unformattedContainer = firstElementChild.classList.contains('derassi-unformatted');
  if (imageContainer) {
    moveBackLastImage(target, firstElementChild, state, dispatch);
  } else if (listContainer) {
    moveBackLastList(target, firstElementChild, state, dispatch);
  } else if (chartContainer) {
    moveBackLastChart(target, firstElementChild, state, dispatch);
  } else if (tableContainer) {
    moveBackLastTable(target, firstElementChild, state, dispatch);
  } else if (paragraphContainer) {
    moveBackLineByLine(target, firstElementChild, state, dispatch);
  } else if (unformattedContainer) {
    moveBackLastUnformatted(target, firstElementChild, state, dispatch);
  } else {
    moveBackLastAny(target, firstElementChild, state, dispatch);
  }

  // const { pages } = state as InitialState;
  // const lastPageId = _.last(_.keys(pages)) || '';
  // const pageNum = ng.getIdFromString(lastPageId);
  // const lastPage = target.closest(ng.dotPageClass);
  // if (pageNum > 0 && lastPage.id === lastPageId) {
  //   // if (last) {
  //   // const lastPage = document.getElementById(last);
  //   if (lastPage) {
  //     const bdy = lastPage.querySelector(ng.dotBodyClass);
  //     if (bdy) {
  //     }
  //     if (bdy) {
  //       if (!bdy.textContent || (bdy.textContent && bdy.textContent.length <= 0)) {
  //         const picked = pages;
  //         delete picked[lastPageId];
  //         dispatch({ type: stateNames.pages, pages: picked });
  //       }
  //     }
  //   }
  //   // }
  // }
  // }
};
export const onAttributeChange = (props) => {

};


export const onAddingChange = (props, guard = 0): void => {
  if (undoState.backspacing) return;
  const { target, state, dispatch } = props;
  if (!target) return;
  const { offsetHeight, lastElementChild } = target;
  if (!offsetHeight || !lastElementChild) return;
  const flexBox = target.closest('[id*=box]');
  if (!flexBox) return;
  const thisPage = target.closest(ng.dotPageClass);
  const nextPage = ng.getNextPage(thisPage.id);
  const { height } = window.getComputedStyle(flexBox);
  const headerFooter = (target as HTMLElement).classList.contains(ng.headerFooterClass);
  const refHeight: number = parseFloat(height);
  const pageAddingPoint = 0.6; // 60%
  if (!headerFooter && (offsetHeight > pageAddingPoint * refHeight)) {
    if (!nextPage) {
      addPage({ state, dispatch, thisPage });
    }
  }
  const paragraphContainer = lastElementChild.classList.contains('derassi-paragraph');
  const imageContainer = lastElementChild.classList.contains('derassi-image');
  const chartContainer = lastElementChild.classList.contains('derassi-chart');
  const tableContainer = lastElementChild.classList.contains('derassi-table');
  const listContainer = lastElementChild.classList.contains('derassi-list');
  const unformattedContainer = lastElementChild.classList.contains('derassi-unformatted');
  if (offsetHeight >= refHeight) {
    if (headerFooter) return;
    if (imageContainer) {
      moveLastImage(target, state, dispatch);
    } else if (listContainer) {
      moveLastList(target, state, dispatch);
    } else if (chartContainer) {
      moveLastChart(target, state, dispatch);
    } else if (tableContainer) {
      moveLastTable(target, state, dispatch);
    } else if (paragraphContainer) {
      moveLineByLine(target, state);
    } else if (unformattedContainer) {
      moveLastUnformatted(target, state, dispatch);
    } else {
      moveLastAny(target, state, dispatch);
    }
  }

  if ((offsetHeight > refHeight) && guard < 5) {
    onAddingChange({ target, ...props }, guard + 1);
  }
};
