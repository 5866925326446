import _ from 'lodash';
import uniqid from 'uniqid';
import paragraph from './paragraph';
import { moveCaretInside } from './moveFocus';
import addList from '../../components/editor/helpers/addList';
// import * as ng from '../../nameGenerator';
import addChart from '../../components/editor/helpers/addChart';
import addImage from '../../components/editor/helpers/addImage';
import attachControl from '../../components/editor/helpers/attachControl';
import {
  getNextPage, headerFooterClass, paragraphForwarding, getIdFromString, generatePageId, paragraphClass, imageClass, getNextPageId, tableClass, chartClass, dotPageClass, unformattedClass, containerClass,
} from '../../nameGenerator';
import addPage from './addPage';
import splitParagraph from './splitParagraph';
import normalizePara from './normalizePara';
import normalizeBody from './normalizeBody';

/**
 * moves paragraph line by line
 * @param target the derassi @page object
 * @param state the React reducer state
 */
export const moveLineByLine = (target, state): any => {
  const { lastElementChild } = target;
  normalizePara(lastElementChild);
  normalizeBody(target, true);
  if (!lastElementChild) return;
  const nextPage = getNextPage(target.id);
  const sel = window.getSelection();
  const headerFooter = target.classList.contains(headerFooterClass);
  if (lastElementChild.textContent.length === 0) {
    let focus;
    let moveFocus;
    if (sel) {
      focus = sel.focusNode;
    }
    if (focus) {
      moveFocus = lastElementChild.contains(focus);
    }
    // remove it if it empty
    lastElementChild.remove();
    if (!headerFooter && nextPage) {
      // if it was transfering, it should be removed but not moved to nextpage
      if (!lastElementChild.classList.contains(paragraphForwarding)) {
        const clone = lastElementChild.cloneNode(false);
        clone.id = `derassi-paragraph-${uniqid()}`;
        nextPage.insertAdjacentElement('afterbegin', clone);
      }
      if (sel && nextPage.firstChild) {
        if (moveFocus) {
          moveCaretInside(nextPage.firstChild as HTMLElement);
        }
      }
    }
    // if this is in header or footer, just remove it
    if (headerFooter) {
      if (sel) {
        const headerFooterId = getIdFromString(target.id);
        const pageId = generatePageId(headerFooterId);
        const body = document.getElementById(pageId);
        if (body && moveFocus) {
          moveCaretInside(body);
        }
      }
    }
    return;
  }
  const targetId = getIdFromString(target.id);
  const flexId = `derassi-body-box-${targetId}`;
  const flexBox = document.getElementById(flexId);
  if (!flexBox) return;
  const {
    height, paddingTop, paddingBottom, maxHeight,
  } = window.getComputedStyle(flexBox);
  let refHeight = parseFloat(height);
  if (headerFooter) {
    refHeight = parseFloat(maxHeight) - parseFloat(paddingTop) - parseFloat(paddingBottom);
  }
  const iterator = document.createNodeIterator(lastElementChild, NodeFilter.SHOW_TEXT);
  let currentNode = iterator.nextNode();
  if (!currentNode) return;
  const firstNode = currentNode;
  let wentOver = false;
  // let left = '';
  // let right = '';
  const going = document.createElement('span');
  while (currentNode && !wentOver) {
    let len = 0;
    if (currentNode.textContent && currentNode.textContent.length) {
      len = currentNode.textContent.length;
    }
    const changingRange = document.createRange();
    changingRange.setStart(firstNode, 0);
    for (let index = 0; index < len && !wentOver; index += 1) {
      changingRange.setEnd(currentNode, index);
      const changingHeight = changingRange.getBoundingClientRect().height;
      const charOffset = lastElementChild.offsetTop - target.offsetTop + changingHeight; // - charHeight;
      wentOver = charOffset > refHeight;
      if (wentOver) {
        // const dsplitter = document.createElement('dsplit');
        let splitIndex = index - 1;
        if (splitIndex < 0) {
          splitIndex = 0;
        }
        const splitRange = document.createRange();
        splitRange.setStart(currentNode, splitIndex);
        splitRange.setEnd(lastElementChild.lastChild, lastElementChild.lastChild.children.length);
        const extract = splitRange.extractContents();
        const brs = extract.querySelectorAll('br');
        Array.from(brs).forEach(br => br.remove());
        going.appendChild(extract);
        // cases where ther is a single child and wentOver does not recoginze them
        if (firstNode === currentNode) {
          if (!going.textContent || (going.textContent && going.textContent.length === 0)) {
            going.appendChild(currentNode.parentElement || currentNode);
            wentOver = true; // get it out of here
          }
        }
        // const splitedAT = (currentNode as Text).splitText(splitIndex);
        // if (currentNode.parentElement) {
        //   currentNode.parentElement.insertBefore(dsplitter, splitedAT);
        // }
        // const res = splitParagraph(lastElementChild, dsplitter);
        // // eslint-disable-next-line prefer-destructuring
        // left = res.left;
        // // eslint-disable-next-line prefer-destructuring
        // right = res.right;
        // right = res.right;
        // const splitted = dsplitter.nextElementSibling;
        // if (splitted) {
        //   going = splitted.cloneNode(true) as HTMLElement;
        //   splitted.remove();
        //   dsplitter.remove();
        // }

        // alert("..found...")
        // return;
        // break;
      }
    }
    currentNode = iterator.nextNode();
  }
  // // cases where ther is a single child and wentOver does not recoginze them
  // if (firstNode === currentNode) {
  //   if (!wentOver) {
  //     curr
  //   }
  // }


  // let breakOut = false;
  // // const tags: RegExpMatchArray = [];
  // const parents: HTMLElement[] = [];
  // let nodesLen = textNodes.length - 1;
  // currentNode = textNodes[nodesLen];
  // while (!breakOut && currentNode) {
  //   let len = 0;
  //   if (currentNode.textContent && currentNode.textContent.length) {
  //     len = currentNode.textContent.length;
  //   }
  //   const changingRange = document.createRange();
  //   changingRange.setStart(textNodes[0], 0);
  //   for (let index = 0; index < len; index += 1) {
  //     const charRange = document.createRange();
  //     charRange.setStart(currentNode, index);
  //     charRange.setEnd(currentNode, index);
  //     changingRange.setEnd(currentNode, index);
  //     const changingHeight = changingRange.getBoundingClientRect().height;
  //     const charOffset = lastElementChild.offsetTop - target.offsetTop + changingHeight; // - charHeight;
  //     const wentOver = charOffset > refHeight;
  //     if (wentOver) {
  //       const dsplitter = document.createElement('dsplit');
  //       let splitIndex = index - 1;
  //       if (splitIndex < 0) {
  //         splitIndex = 0;
  //       }
  //       const splitedAT = (currentNode as Text).splitText(splitIndex);
  //       if (currentNode.parentElement) {
  //         currentNode.parentElement.insertBefore(dsplitter, splitedAT);
  //       }
  //       let parent = currentNode.parentElement;
  //       while (parent && !parent.classList.contains(paragraphClass)) {
  //         parents.push(parent);
  //         parent = parent.parentElement;
  //         // const { innerHTML } = parent as HTMLElement;

  //         // const tag = innerHTML.match(/<.+?>/);
  //         // if (tag) {
  //         //   tags.push(tag[0]);
  //         // }
  //       }
  //       breakOut = true;
  //       break;
  //     }
  //   }
  //   nodesLen -= 1;
  //   currentNode = textNodes[nodesLen]; // .nextNode();
  // }
  // const paraHTML = lastElementChild.innerHTML;
  // const splitPara = paraHTML.split('<dsplit></dsplit>');
  // let staying = splitPara[0];
  // let going = splitPara[1];
  // lastElementChild.innerHTML = staying;
  // // parents.forEach(() => {
  // staying = `${staying}${right}`;
  // // });
  // // parents.reverse().forEach((tag) => {
  // //   const outer = tag.outerHTML;
  // //   const openingTag = outer.match(/<.+?>/);
  // going = `${left}${going}`;
  // });
  // lastElementChild.innerHTML = staying;
  // if (headerFooter) {
  //   return;
  // }
  if (!nextPage || !going) return;
  const nextPageFirstChild = nextPage.firstChild;
  if (!nextPageFirstChild) {
    const clone = lastElementChild.cloneNode(false);
    clone.id = `derassi-paragraph-${uniqid()}`;
    nextPage.append(clone);
  }
  if (lastElementChild
     && lastElementChild.classList.contains(paragraphForwarding)) {
    (nextPageFirstChild as HTMLElement).insertAdjacentHTML('afterbegin', going.innerHTML); // + nextPageFirstChild.innerHTML;
  } else {
    lastElementChild.classList.add(paragraphForwarding);
    // const newParagraph = paragraph(state);
    const clone = lastElementChild.cloneNode(false);
    clone.id = `derassi-paragraph-${uniqid()}`;
    clone.style.backgroundColor = 'azures';
    clone.insertAdjacentHTML('afterbegin', going.innerHTML);
    nextPage.insertAdjacentElement('afterbegin', clone);
  }
  normalizePara(nextPageFirstChild);
  if (sel) {
    const moveFocus = lastElementChild.contains(sel.focusNode);
    if (nextPage && nextPage.firstChild && moveFocus) {
      moveCaretInside(nextPage.firstChild);
    }
  }
};

/**
 * moves/jumps images to next page
 * @param target the derassi @page object
 * @param state the React reducer state
 * @param dispatch the React recuer dispatch
 */
export const moveLastImage = (target, state, dispatch): void => {
  const headerFooter = target.classList.contains('derassi-header-footer');
  if (headerFooter) {
    target.lastElementChild.remove();
    return;
  }
  const container = target.lastElementChild as HTMLElement;
  if (!container) return;
  if (!container.classList.contains(imageClass)) {
    return;
  }
  const nextId = getNextPageId(target.id);
  const nextPage = document.getElementById(nextId);
  const thisPage = target.closest(dotPageClass);
  if (!nextPage) {
    return;
  }
  const copy = container.cloneNode(true) as HTMLElement;
  nextPage.insertAdjacentElement('afterbegin', copy);
  // attach after insertion and get a hot element
  // so moveable controller knows the size of the elemnt
  // const cloned = document.getElementById(copy.id);
  attachControl({
    container: copy, image: true, state, dispatch,
  });
  container.remove();
};

export const moveLastUnformatted = (target, state, dispatch): void => {
  const headerFooter = target.classList.contains('derassi-header-footer');
  if (headerFooter) {
    target.lastElementChild.remove();
    return;
  }
  const container = target.lastElementChild as HTMLElement;
  if (!container) return;
  if (!container.classList.contains(unformattedClass)) {
    return;
  }
  const nextId = getNextPageId(target.id);
  const nextPage = document.getElementById(nextId);
  const thisPage = target.closest(dotPageClass);
  if (!nextPage) {
    return;
  }
  const copy = container.cloneNode(true) as HTMLElement;
  nextPage.insertAdjacentElement('afterbegin', copy);
  // attach after insertion and get a hot element
  // so moveable controller knows the size of the elemnt
  // const cloned = document.getElementById(copy.id);
  attachControl({
    container: copy, state, dispatch,
  });
  container.remove();
};


/**
 * moves/jumps lists
 * @param target the derassi @page object
 * @param state the React reducer state
 * @param dispatch the React recuer dispatch
 */
export const moveLastList = (target, state, dispatch): void => {
  const headerFooter = target.classList.contains('derassi-header-footer');
  if (headerFooter) return;
  const container = target.lastElementChild;
  const list = container.querySelector('ul') || container.querySelector('ol');
  const start = list.children ? list.children.length : 0;
  if (!container.classList.contains('derassi-list')) {
    return;
  }
  if (!container) return;
  if (container.classList.contains('derassi-list-forwarding')) {
    if (list.children.length === 0) {
      container.remove();
      return;
    }
  }
  const nextPage = getNextPage(target.id);
  if (!nextPage) return;
  const li = list.lastChild;
  li.remove();
  if (!container.classList.contains('derassi-list-forwarding')) {
    container.classList.add('derassi-list-forwarding');
    const type = list.nodeName.toLowerCase();
    addList(type, 'inherite', nextPage, li, start);
  } else {
    const nextContainer = (nextPage as HTMLElement).firstElementChild as HTMLElement;
    const nextList = nextContainer.querySelector('ol') || nextContainer.querySelector('ul');
    if (nextList) {
      nextList.setAttribute('start', start);
      nextList.insertAdjacentElement('afterbegin', li);
    }
  }
};

/**
 * moves tables row at a time.
 * @param target the derassi @page object
 * @param state the React reducer state
 * @param dispatch the React recuer dispatch
 */
export const moveLastTable = (target: HTMLElement, state, dispatch) => {
  const headerFooter = target.classList.contains(headerFooterClass);
  if (headerFooter) return;
  const container = target.lastElementChild as HTMLElement;
  if (!container) return;
  if (!container.classList.contains(tableClass)) {
    return;
  }
  const nextPage = getNextPage(target.id);
  if (!nextPage) return;

  if (!container.classList.contains('derassi-table-forwarding')) {
    container.classList.add('derassi-table-forwarding');
    const trs = Array.from(container.querySelectorAll('tr'));
    if (trs.length <= 1) {
      container.remove();
      return;
    }
    const clone = container.cloneNode(true) as HTMLElement;
    // the clone is not forwarding so remove this className
    clone.classList.remove('derassi-table-forwarding');
    if (trs) {
      const last = _.last(trs);
      if (last) last.remove();
    }
    const copyTrs = Array.from(clone.querySelectorAll('tr'));
    if (copyTrs) {
      const inBetween = _.drop(_.dropRight(copyTrs));
      for (let index = 0; index < inBetween.length; index += 1) {
        const element = inBetween[index];
        element.remove();
      }
    }
    const last = _.last(Array.from(trs));
    if (last) {
      last.remove();
    } else {
      container.remove();
    }
    nextPage.insertAdjacentElement('afterbegin', clone);
    // attach moveable after insertion and get a hot element
    // so it knows the exact size
    const cloned = document.getElementById(clone.id);
    attachControl({ container: cloned, state, dispatch });
  } else {
    const nextPageContainer = nextPage.firstChild as HTMLElement;
    if (!nextPageContainer.classList.contains(tableClass)) return;
    const trs = Array.from(container.querySelectorAll('tr'));
    if (trs.length <= 1) { // if only head is left
      container.remove();
      // attach moveable after insertion and get a hot element
      // so it knows the exact size
      const nextContainer = document.getElementById(nextPageContainer.id);
      attachControl({ container: nextContainer, state, dispatch });
      return;
    }
    if (trs) {
      const last = _.last(trs);
      if (last) {
        const firstTr = nextPageContainer.querySelector('tr');
        if (firstTr) {
          const copy = last.cloneNode(true) as HTMLTableRowElement;
          firstTr.insertAdjacentElement('afterend', copy);
          last.remove();
        }
      } else {
        container.remove();
      }
    } else {
      container.remove();
    }
    // attach moveable after insertion and get a hot element
    // so it knows the exact size
    const nextContainer = document.getElementById(nextPageContainer.id);
    attachControl({ container: nextContainer, state, dispatch });
  }
};

/**
 * moves charts to next page
 * @param target the derassi @page object
 * @param state the React reducer state
 * @param dispatch the React reducer dispatch
 */
export const moveLastChart = (target: HTMLElement,
  state: InitialState, dispatch: Function): void => {
  const headerFooter = target.classList.contains(headerFooterClass);
  if (headerFooter) return;
  const container = target.lastElementChild as HTMLElement;
  if (!container) return;
  if (!container.classList.contains(chartClass)) {
    return;
  }
  const nextPage = getNextPage(target.id);
  if (!nextPage) return;
  const clone = container.cloneNode(true) as HTMLElement;
  nextPage.insertAdjacentElement('afterbegin', clone);
  container.remove();
  const refContainer = document.getElementById(clone.id);
  if (refContainer) {
    const chart = JSON.parse(refContainer.dataset.chart || '');
    const data = { ...chart };
    addChart({
      data, refContainer, newInsertion: false, state, dispatch,
    });
  }
};


export const moveLastAny = (target: HTMLElement,
  state: InitialState, dispatch: Function): void => {
  const headerFooter = target.classList.contains(headerFooterClass);
  if (headerFooter) return;
  const container = target.lastElementChild as HTMLElement;
  if (!container) return;

  const nextPage = getNextPage(target.id);
  if (!nextPage) return;
  const clone = container.cloneNode(true) as HTMLElement;
  nextPage.insertAdjacentElement('afterbegin', clone);
  container.remove();
};
