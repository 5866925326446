
import * as React from 'react';
import { SketchPicker } from 'react-color';
import FormatColorText from '@material-ui/icons/FormatColorText';
import Colorize from '@material-ui/icons/Colorize';
import BorderColor from '@material-ui/icons/BorderColor';
import Forground from '@material-ui/icons/FormatColorFill';
import formatDoc from '../../helpers/formatting';
import { currentMoveable } from '../../../../eventHandlers/changeHistory';


const Icon = (props) => {
  const { cmd, onClick, color } = props;
  switch (cmd) {
    case 'color':
      return <FormatColorText style={{ color }} onClick={onClick} />;
    case 'background-color':
      return <Colorize style={{ color }} onClick={onClick} />;
    case 'border-color':
      return <BorderColor style={{ color }} onClick={onClick} />;
    case 'forground-color':
      return <Forground style={{ color }} onClick={onClick} />;
    default:
      return <></>;
  }
};
export default (props): JSX.Element => {
  const { cmd, state } = props;
  const { showThirdMenu } = state as InitialState;
  // const { currentMoveable } = state as InitialState;
  const [color, setColor] = React.useState('');
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const handleClick = (): void => {
    setDisplayColorPicker(true);
  };
  const handleClose = (): void => {
    if (showThirdMenu === 'editText') return;
    if (cmd === 'forground-color') {
      formatDoc({
        attr: 'color', value: color,
      });
      formatDoc({
        attr: 'background-color', value: color,
      });
    } else if (cmd === 'border-color') {
      if (currentMoveable.element && color) {
        currentMoveable.element.style.borderColor = color;
      }
    } else {
      formatDoc({
        attr: cmd, value: color,
      });
    }
    setDisplayColorPicker(false);
  };
  const handleMouseLeave = (): void => {
    setDisplayColorPicker(false);
  };
  const handleChange = (clr): void => {
    if (clr && clr.hex) {
      setColor(clr.hex);
    }
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <div style={{ borderBottom: '1px solid black' }}>
      <Icon cmd={cmd} color={color} onClick={handleClick} />
      { displayColorPicker ? (
        <div
          role="presentation"
          style={popover as React.CSSProperties}
          onMouseLeave={handleMouseLeave}
          onDoubleClick={handleClose}
        >
          <div style={cover as React.CSSProperties} />
          <SketchPicker color={color} onChange={(e): void => handleChange(e)} />
        </div>
      ) : null }

    </div>
  );
};
