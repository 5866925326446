import React from 'react';
import ReactDOM from 'react-dom';
import paragraph from './paragraph';
import { getNextPageId } from '../../nameGenerator';
import PageContainer from '../../components/editor/body/page';
import { stateNames } from '../..';
import devLog from '../../devLog';
import { derassiHeader, derassiFooter } from '../changeHistory';


export default (props: {
  state: InitialState; dispatch: any; thisPage: HTMLElement; }): void => {
  try {
    const { state, dispatch, thisPage } = props;
    // const { pages, derassiHeader, derassiFooter } = state as InitialState;
    const para = paragraph(state);
    const page: Page = {
      headerHTML: '', footerHTML: '', bodyHTML: `${para.outerHTML}`,
    };

    const newPageId = getNextPageId(thisPage.id);
    // const newPage: Record<string, Page> = {};
    // newPage[newPageId] = page;

    // if (Object.entries(state.pages).length === 0) return;
    // window.addEventListener('reload', () => {
    //  dispatch({ type: stateNames.pages, pages: { ...pages, ...newPage } });
    //});
    const newPage = <PageContainer id={newPageId} state={state} dispatch={dispatch} page={page} />;
    const container = document.createElement('div');
    thisPage.insertAdjacentElement('afterend', container);
    ReactDOM.render(newPage, container);
    // removes the container that the react compoent is mounted on
    container.replaceWith(...Array.from(container.childNodes));
    // return Promise.resolve(newPageId);
  } catch (err) {
    devLog(err);
    // return Promise.reject(err);
  }
};
