import {
  dotHeaderClass, dotFooterClass, dotBodyClass, generatePageId,
} from '../nameGenerator';
import paragraph from '../eventHandlers/helpers/paragraph';
import {
  textParent, currentFont,
  derassiHeader, derassiFooter,
} from '../eventHandlers/changeHistory';

export default (state, dispatch, createNew?: boolean): Record<string, Page> => {
  const { parent, focusOffset, index } = textParent;
  let focusParentId;
  if (parent && parent.id) {
    focusParentId = parent.id;
  }
  const {
    currentTitle, menuFontFamily,
  } = state as InitialState;
  let body: Record<string, any> = {
    reFocus: {
      focusParentId,
      focusOffset,
      index,
    },
    other: {},
    menuFontFamily,
    fontFamily: currentFont.family,
    fontSize: currentFont.size,
    name: currentTitle,
    derassiFooter,
    derassiHeader,
  };


  // body = { ...body, pages: assembledPages() }; // .pages = savingPage;
  if (createNew) {
    const newParagraph = paragraph(state);
    const blankPage: Page = { headerHTML: '', bodyHTML: newParagraph.outerHTML, footerHTML: '' };
    const firstPage: Record<string, Page> = {};
    firstPage[`${generatePageId(0)}`] = blankPage;
    return firstPage;
  }
  const currentPages: Record<string, Page> = {};
  const allPages = document.querySelectorAll('.derassi-page');
  if (allPages) {
    Array.from(allPages).forEach((page) => {
      const header = page.querySelector(dotHeaderClass);
      const footer = page.querySelector(dotFooterClass);
      const pageBody = page.querySelector(dotBodyClass);
      const wrapperId = page.id;
      const pageParts: Page = {
        headerHTML: header ? header.innerHTML : '',
        footerHTML: footer ? footer.innerHTML : '',
        bodyHTML: pageBody ? pageBody.innerHTML : '',
      };
      currentPages[wrapperId] = pageParts;
    });
  }
  body = { ...body, pages: currentPages };
  return body;
};
