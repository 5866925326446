import React from 'react';
import { render } from 'react-dom';
import Home from './components/App';
import { generatePageId, paragraphClass } from './nameGenerator';
import paragraph from './eventHandlers/helpers/paragraph';
import useGapi from './services/useGapi';

const para = paragraph();
const blankPage: Page = { headerHTML: '', bodyHTML: para.outerHTML, footerHTML: '' };
const firstPage: Record<string, Page> = {};
firstPage[`${generatePageId(0)}`] = blankPage;

export const stateNames = {
  isFirstPage: 'isFirstPage',
  localFile: 'localFile',
  openAlert: 'openAlert',
  dontReFocus: 'dontReFocus',
  showThirdMenu: 'showThirdMenu',
  setTable: 'setTable',
  currentMoveable: 'currentMoveable',
  currentTransfer: 'currentTransfer',
  currentPath: 'currentPath',
  bodyZoom: 'bodyZoom',
  currentFocus: 'currentFocus',
  findReplaceOpen: 'findReplaceOpen',
  derassiFooter: 'derassiFooter',
  derassiHeader: 'derassiHeader',
  // headerFooter: 'headerFooter',
  // headerPageNum: 'headerPageNum',
  // footerPageNum: 'footerPageNum',
  setUndo: 'setUndo',
  setRedo: 'setRedo',
  bodyToggle: 'bodyToggle',
  // menuToggle: 'menuToggle',
  lang: 'lang',
  menuInited: 'menuInited',
  defaultFontFamily: 'defaultFontFamily',
  menuDefaultFontFamily: 'menuDefaultFontFamily',
  // fontFamily: 'fontFamily',
  fontChange: 'fontChange',
  menuFontFamily: 'menuFontFamily',
  fontFamilyCaped: 'fontFamilyCaped',
  menuFontFamilyCaped: 'menuFontFamilyCaped',
  currentTitle: 'currentTitle',
  currentID: 'currentID',
  pages: 'pages',
  resetPages: 'resetPages',
  isSignedIn: 'isSignedIn',
  logout: 'logout',
  login: 'login',
  loading: 'loading',
  pageLoading: 'pageLoading',
  saving: 'saving',
  gapi: 'gapi',
  // accessToken: 'accessToken',
  // API_KEY: 'API_KEY',
  name: 'name',
  email: 'email',
  imageURL: 'imageURL',
};


export const initialState: InitialState = {
  openAlert: {
    title: 'dialog',
    contentText: 'open',
    first: 'canel',
    second: 'enter',
    open: false,
    choice: '',
    invoke: null,
  },
  isFirstPage: false,
  localFile: false,
  showThirdMenu: '',
  // dontReFocus: false,
  // setTable: { tableData: null, tableId: '' },
  // currentMoveable: null,
  // currentTransfer: null,
  bodyZoom: 1.0,
  // currentFocus: { parent: null, focusOffset: 0 },
  // currentPath: {
  //   containerId: '', path: {}, focusOffset: 0, textParent: null,
  // },
  derassiFooter: {
    showPageNumber: false,
    pageNumberSide: 'right',
    pageNumberLabel: '',
    firstPageContent: '',
    otherPagesContent: '',
  },
  derassiHeader: {
    showPageNumber: false,
    pageNumberSide: 'right',
    pageNumberLabel: '',
    firstPageContent: '',
    otherPagesContent: '',
  },
  findReplaceOpen: false,
  setUndo: false,
  setRedo: false,
  bodyToggle: true,
  // menuToggle: true,
  lang: 0,
  menuInited: false,
  defaultFontFamily: 'Mulat Abay',
  menuDefaultFontFamily: 'Mulat Abay',
  // fontSize: 12,
  fontChange: false,
  menuFontFamily: 'Mulat Abay',
  fontFamilyCaped: 'Mulat Abay Caped',
  menuFontFamilyCaped: 'Mulat Abay Caped',
  currentTitle: 'ዐርስት/title',
  currentID: '',
  pages: firstPage,
  logout: false,
  login: false,
  loading: true,
  pageLoading: false,
  saving: false,
  gapi: null,
  // accessToken: null,
  // API_KEY: '',
  isSignedIn: false,
  name: '',
  email: '',
  imageURL: '',
};


function reducer(state, action) {
  // devLog(action);
  switch (action.type) {
    case stateNames.openAlert:
      return { ...state, openAlert: action.openAlert };
    case stateNames.localFile:
      return { ...state, localFile: action.localFile };
    case stateNames.isFirstPage:
      return { ...state, isFirstPage: action.isFirstPage };
    case stateNames.showThirdMenu:
      return { ...state, showThirdMenu: action.showThirdMenu };
    // case stateNames.dontReFocus:
    //   return { ...state, dontReFocus: action.dontReFocus };
    // case stateNames.setTable:
    //   return { ...state, setTable: action.setTable };
    // case stateNames.currentMoveable:
    //   return { ...state, currentMoveable: action.currentMoveable };
    // case stateNames.currentTransfer:
    //   return { ...state, currentTransfer: action.currentTransfer };
    // case stateNames.currentPath:
    //   return { ...state, currentPath: action.currentPath };
    case stateNames.bodyZoom:
      return { ...state, bodyZoom: action.bodyZoom };
    // case stateNames.currentFocus:
    //   return { ...state, currentFocus: action.currentFocus };
    case stateNames.findReplaceOpen:
      return { ...state, findReplaceOpen: action.findReplaceOpen };
    case stateNames.derassiFooter:
      return { ...state, derassiFooter: action.derassiFooter };
    case stateNames.derassiHeader:
      return { ...state, derassiHeader: action.derassiHeader };
    case stateNames.setUndo:
      return { ...state, setUndo: action.setUndo };
    case stateNames.setRedo:
      return { ...state, setRedo: action.setRedo };
    case stateNames.currentID:
      return { ...state, currentID: action.currentID };
    case stateNames.currentTitle:
      return { ...state, currentTitle: action.currentTitle };
    // case stateNames.editing:
    //   return { ...state, editing: action.editing };
    case stateNames.menuInited:
      return { ...state, menuInited: action.menuInited };
    case stateNames.bodyToggle:
      return { ...state, bodyToggle: !state.bodyToggle };
    // case stateNames.menuToggle:
    //   return { ...state, menuToggle: !state.menuToggle };
    case stateNames.lang:
      return { ...state, lang: action.lang };
    case stateNames.fontChange:
      return { ...state, fontChange: !state.fontChange };
    // case stateNames.fontFamily:
    //   return { ...state, fontFamily: action.fontFamily, fontFamilyCaped: `${action.fontFamily} Caped` };
    // case 'fontStyleCap':
    //   return { ...state, fontStyleCap: action.font };
    case stateNames.menuFontFamily:
      return { ...state, menuFontFamily: action.menuFontFamily };
    case stateNames.defaultFontFamily:
      return { ...state, defaultFontStyle: action.defaultFontFamily };
    case stateNames.menuDefaultFontFamily:
      return { ...state, menuDefaultFontFamily: action.menuDefaultFontFamily };
    case stateNames.pages:
      return { ...state, pages: action.pages };
    case stateNames.resetPages:
      return { ...state, pages: firstPage };
    // user
    case stateNames.isSignedIn:
      return { ...state, isSignedIn: action.isSignedIn };
    case stateNames.logout:
      return { ...state, logout: true, login: false };
    case stateNames.login:
      return { ...state, login: true, logout: false };
    case stateNames.loading:
      return { ...state, loading: action.loading };
    case stateNames.pageLoading:
      return { ...state, pageLoading: action.pageLoading };
    case stateNames.saving:
      return { ...state, saving: action.saving };
    // case stateNames.accessToken:
    //   return { ...state, accessToken: action.accessToken };
    // case stateNames.API_KEY:
    //   return { ...state, API_KEY: action.API_KEY };
    case stateNames.gapi:
      return { ...state, gapi: action.gapi };
    case stateNames.name:
      return { ...state, name: action.name };
    case stateNames.email:
      return { ...state, email: action.email };
    case stateNames.imageURL:
      return { ...state, imageURL: action.imageURL };
    default:
      throw new Error();
  }
}

const App = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  useGapi(state, dispatch);
  return (<Home state={state} dispatch={dispatch} />);
};

render(<App />, document.getElementById('root'));
