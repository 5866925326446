export default async (fileID) => {
  const win = (window as any);
  if (!win) return {};
  const { gapi } = win;
  const accessToken = gapi.client.getToken().access_token;
  const url = `https://www.googleapis.com/drive/v3/files/${fileID}?alt=media`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.blob().then(
    blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => { 
        resolve(reader.result);
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(blob);
    }),
  );
};
