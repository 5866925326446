import React from 'react';
import ReactDOM from 'react-dom';
import { Chart } from 'frappe-charts/dist/frappe-charts.min.esm';
import uniqid from 'uniqid';
import { makeStyles } from '@material-ui/core';
import * as ng from '../../../nameGenerator';
import attachControl from './attachControl';
import save from '../../../services/save';
import { saveHistory } from '../../../eventHandlers/changeHistory';


const useStyles = makeStyles(() => ({
  chart: (props): any => ({
    '& > *': {
      fontFamily: `${props.fontFamily} !important`,
    },
  }),
}));

const randomColor = () => {
  const color = Math.floor(Math.random() * 16777215).toString(16);
  return `#${color}`;
};

const DerassiChart = (props): JSX.Element => {
  const {
    container, data, state,
  } = props;
  const { fontFamily } = state;
  const classes = useStyles({ fontFamily });
  const ref = React.useRef<HTMLDivElement>(null);
  const chartRef = React.useRef<any>(null);
  const computedHeight = parseFloat(window.getComputedStyle(container).height);
  const [height, setHeight] = React.useState(computedHeight || 400);
  const [colors, setColors] = React.useState([]);

  const setChartHeight = (h) => {
    setHeight(h);
  };
  React.useEffect(() => {
    setColors(data.data.datasets.map(() => randomColor()));
  }, []);

  React.useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.width = container.width;
      ref.current.style.height = container.height;
      const chart = new Chart(ref.current, {
        title: data.title,
        data: data.data,
        type: data.type, // : 'donut', //'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
        height,
        colors,
      });
      chartRef.current = chart;
    }
  }, [height]);

  React.useEffect(() => {
    attachControl({
      container, setChartHeight, chart: true, ...props,
    });
  }, []);

  return (
    <div
      ref={ref}
      className={classes.chart}
    />
  );
};

const addChart = (props): void => {
  const {
    container, data,
  } = props;
  ReactDOM.render(<DerassiChart {...props} />,
    document.getElementById(container.id), () => {
      container.dataset.chart = JSON.stringify(data);
    });
};


export default (props: {
  data: any; state: InitialState; dispatch: Function;
  refContainer: HTMLElement; newInsertion: boolean; backspacing?: boolean;
}): void => {
  const {
    data, refContainer, newInsertion, state, dispatch,
  } = props;

  if (!newInsertion) {
    const container = refContainer;
    // container.dataset.chart = JSON.stringify(data);
    addChart({ container, data, ...props });
    // attachControl({ container, state, dispatch });
    return;
  }
  const someId = uniqid();
  const id = ng.generateId(someId, 'chart'); // `derassi-${type}-${someId}`;
  const klass = ng.generateClass('chart');
  const container = document.createElement('div');
  // const height = 250;
  const width = 500;
  container.id = id;
  container.classList.add(klass, ng.containerClass, ng.moveableClass);
  container.style.height = 'auto';
  container.style.width = `${width}px`;
  container.style.paddingTop = '5px';
  container.style.paddingBottom = '15px';
  container.style.display = 'block';
  container.style.breakInside = 'avoid';
  container.style.pageBreakInside = 'avoid';
  refContainer.insertAdjacentElement('afterend', container);
  if (container) {
    addChart({ container, data, ...props });
   // save({ state, dispatch });
    // saveHistory();
  }
};
