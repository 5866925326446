import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import MoreIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Button, Breadcrumbs, Divider, Box, IconButton, MenuList,
} from '@material-ui/core';
import _ from 'lodash/string';
import { Capitalized, UpperCased } from './editor/helpers/changeToUpper';
import Title from './editor/headings/title';
import translate, { translateWords } from '../translate';
import { stateNames } from '../index';
import mulatlogo from '../images/mulatlogo.png';
import derassilogo from '../images/derassilogo.png';
import { login, logout } from '../services/useGapi';

const useStyles = makeStyles(theme => ({
  appBar: {
    paddingTop: 2,
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    flexGrow: 1,
  },
  menuToggle: {
    padding: 4,
    marginRight: 10,
  },
  userState: {
    padding: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logout: {
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: theme.palette.background.paper,
  },
  googleLogo: {
    paddingTop: 4,
    paddingLeft: 10,
    paddingBottom: 4,
    paddingRight: 10,
    cursor: 'pointer',
    width: 'auto',
    minWidth: 100,
    '&: hover': {
      backgroundColor: 'lightgrey',
    },
  },
  lang: {
    padding: 1,
    textTransform: 'none',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));


const ToggleMenu = (props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch } = props;
  const { lang } = state as InitialState;
  const moreRef = React.useRef<HTMLDivElement>(null);
  const dropRef = React.useRef<HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const handleMobileMenuOpen = () => {
    setOpen(!open);
  };
  React.useEffect(() => {
    window.addEventListener('mousedown', (e) => {
      if (dropRef && dropRef.current) {
        if ((e.target as HTMLElement).contains(dropRef.current)) {
          setOpen(false);
        }
      }
    });
  }, []);
  const handleClick = (num) => {
    dispatch({ type: stateNames.lang, lang: num });
    setOpen(false);
  };
  const renderButtons = (
    <div>
      <Button
        onClick={() => handleClick(0)}
        disabled={lang === 0}
        style={lang === 0 ? { border: '1px solid #3f51b5' } : { border: 'initial' }}
        className={classes.lang}
      >
        {<Capitalized state={state} word="ዐማርኛ" />}
      </Button>
      <Button
        onClick={() => handleClick(1)}
        disabled={lang === 1}
        style={lang === 1 ? { border: '1px solid #3f51b5' } : { border: 'initial' }}
        className={classes.lang}
      >
        {<Capitalized state={state} word="ትግርኛ" />}
      </Button>
      <Button
        onClick={() => handleClick(2)}
        disabled={lang === 2}
        style={lang === 2 ? { border: '1px solid #3f51b5' } : { border: 'initial' }}
        className={classes.lang}
      >
        {<Capitalized state={state} word="ዖሮምኛ" />}
      </Button>
      {/* <Button disabled>-</Button> */}
      <Button
        onClick={() => handleClick(3)}
        disabled={lang === 3}
        style={lang === 3 ? { border: '1px solid #3f51b5' } : { border: 'initial' }}
        className={classes.lang}
      >
        {'Afaan'}
      </Button>
      <Button
        onClick={() => handleClick(4)}
        disabled={lang === 4}
        style={lang === 4 ? { border: '1px solid #3f51b5' } : { border: 'initial' }}
        className={classes.lang}
      >
        {'English'}
      </Button>
    </div>
  );
  return (
    <div>
      <Grid
        container
        direction="row"
        className={classes.sectionDesktop}
        style={{ float: 'right', paddingLeft: 20, paddingRight: 20 }}
      >
        {renderButtons}
      </Grid>
      <div className={classes.sectionMobile}>
        <Paper
          // className={classes.dropdown}
          ref={dropRef}
          style={open ? {
            display: 'inline-block',
            position: 'absolute',
            top: moreRef.current ? moreRef.current.offsetTop + 70 : 70,
            left: moreRef.current ? moreRef.current.offsetLeft : 70,
          } : { display: 'none' }}
        >
          <Grid container direction="column">
            {renderButtons}
          </Grid>
        </Paper>
        <div ref={moreRef}>
          <IconButton
            onClick={handleMobileMenuOpen}
          >
            <MoreIcon />
          </IconButton>
        </div>
      </div>
    </div>
  // <Button
  //   className={classes.menuToggle}
  //   color="primary"
  //   variant="text"
  //   style={{ textTransform: 'none', fontWeight: 'normal' }}
  //   onClick={() => ('>..')}
  // >
  //   { lang <= 2
  //     ? _.capitalize('english')
  //     : (
  //       <Capitalized
  //         word="amahric"
  //         {...props}
  //       />
  //     )
  //   }
  // </Button>
  );
};

const UserState = (props): JSX.Element => {
  const {
    state, dispatch, history, isBodyEditor,
  } = props;
  const {
    loading, isSignedIn, imageURL, name, lang,
  } = state as InitialState;

  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const showLogoutButton = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = (e): void => {
    e.stopPropagation();
    dispatch({ type: stateNames.logout, log: true });
    logout(state, dispatch);
    handleClose();
    history.push('/');
  };
  const handleLogin = (e): void => {
    e.stopPropagation();
    login(state, dispatch);
    // dispatch({ type: stateNames.login, login: true });
  };
  if (window.navigator.onLine && loading) return <CircularProgress />;
  if (isSignedIn) {
    return (
      <Paper className={classes.userState} elevation={0}>
        <Avatar
          style={isBodyEditor ? { border: '2px solid orange' } : { border: '2px solid royalBlue' }}
          src={imageURL}
          title={name}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={showLogoutButton}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={(e): void => handleLogout(e)} className={classes.logout}>
            { lang <= 2
              ? <UpperCased word={translateWords.logout[lang]} {...props} />
              : translateWords.logout[lang]}
          </MenuItem>
        </Menu>
      </Paper>
    );
  }

  return (
    <Paper className={classes.googleLogo} id="login" onClick={e => handleLogin(e)} role="presentation">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" style={{ marginRight: 18 }}>
        <g>
          <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
          <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
          <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
          <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
          <path fill="none" d="M0 0h48v48H0z" />
        </g>
      </svg>
      { lang <= 2
        ? <UpperCased word={translateWords.login[lang]} {...props} />
        : translateWords.login[lang]}
    </Paper>
  );
};

const Logo = (props): JSX.Element => {
  const { logo } = props;
  return (
    <img src={logo} height="35px" width="auto" alt="logo" />
  );
};

// // const { menuFontStyle, menuToggle } = state;
// const MyDocs = ({ state, dispatch }) => (
//   <Link
//     to="/"
//     onClick={(): void => dispatch({ type: 'editing', editing: false })}
//     style={{ boxShadow: '2px 2px #ccc' }}
//   >
//     <img src={derassilogo} height="35px" width="auto" alt="logo" />
//   </Link>
// );

export default (props) => {
  const {
    state, dispatch, isBodyEditor, showTitle,
  } = props;
  const { menuDefaultFontFamily, lang } = state as InitialState;
  const classes = useStyles();

  return (
    <div style={{ fontFamily: menuDefaultFontFamily }}>
      <AppBar elevation={0} className={classes.appBar} position="fixed">
        <Toolbar>
          <Toolbar>
            {isBodyEditor
              ? (
                <Toolbar disableGutters>
                  <a href="/">
                    <Logo logo={derassilogo} />
                  </a>
                  {showTitle ? (<Title state={state} dispatch={dispatch} />) : (<></>)}
                </Toolbar>
              )
              : <a href="https://mulat.org"><Logo logo={mulatlogo} /></a>
             }
          </Toolbar>
          <Toolbar style={{ position: 'absolute', right: 20 }}>
            <ToggleMenu {...props} />
            <UserState {...props} />
            {/* {renderMenu} */}
          </Toolbar>
        </Toolbar>
      </AppBar>
    </div>
  );
};
