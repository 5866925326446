import React from 'react';
import {
  Grid, Button, CircularProgress,
} from '@material-ui/core';
import AspectRatio from '@material-ui/icons/AspectRatio';
import { translateWords } from '../../../../translate';
import { addControl } from '../../helpers/attachControl';
import addImage from '../../helpers/addImage';
import { currentMoveable } from '../../../../eventHandlers/changeHistory';
import { imageClass } from '../../../../nameGenerator';
import { stateNames } from '../../../..';

export default (props): JSX.Element => {
  const { state, dispatch } = props;
  const { menuFontFamily, lang } = state as InitialState;
  const [, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const handleMove = () => {
    const moveable = currentMoveable.element;
    if (moveable) {
      //  if (container.classList.contains(ng.imageClass)) {
      // const container = moveable as HTMLElement;
      addControl({
        container: moveable, signature: true, state, dispatch,
      });
    }
  };
  const deleteSig = () => {
    const moveable = currentMoveable.element;
    if (moveable && moveable.classList.contains('derassi-signature')) {
      moveable.remove();
      dispatch({ type: stateNames.showThirdMenu, showThirdMenu: '' });
    }
  };
  const saveSig = () => {
    setSaved(true);
    setSaving(true);
    const moveable = currentMoveable.element;
    if (moveable) {
      const canvas = moveable.querySelector('canvas') as HTMLCanvasElement;
      if (canvas) {
        const url = canvas.toDataURL();
        moveable.classList.add(imageClass);
        const data = { src: url, name: '' };
        addImage({
          data, replacingContainer: moveable, state, dispatch,
        });
        setSaving(false);
      }
    }
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: '' });
  };

  return (
    <div>
      <Grid container direction="row">
        <Button
          variant="outlined"
          color="primary"
          style={{
            fontFamily: menuFontFamily,
            marginLeft: 5,
            paddingTop: 1,
            paddingBottom: 1,
          }}
          onClick={handleMove}
        >
          <AspectRatio style={{ paddingRight: 10 }} />
          {translateWords.add[lang]}

        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{
            fontFamily: menuFontFamily,
            marginLeft: 5,
            paddingTop: 1,
            paddingBottom: 1,
          }}
          onClick={() => saveSig()}
        >
          {saving ? <CircularProgress size={10} /> : translateWords.add[lang]}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          style={{
            fontFamily: menuFontFamily,
            marginLeft: 5,
            paddingTop: 1,
            paddingBottom: 1,
          }}
          onClick={deleteSig}
        >
          { translateWords.delete[lang] }
        </Button>
      </Grid>
    </div>
  );
};
