
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Delete from '@material-ui/icons/Delete';
import Copy from '@material-ui/icons/FileCopy';
import Undo from '@material-ui/icons/UndoSharp';
import Redo from '@material-ui/icons/RedoSharp';
import {
  ListItem, ListItemIcon, ListItemText, Divider,
} from '@material-ui/core';
import { Capitalized } from '../../helpers/changeToUpper';
import { translateWords } from '../../../../translate';
import useStyles from './commonStyles';
import {
  cutSelected, copySelected, pasteSelected, deleteSelected, pasteSelectedUnformatted,
} from '../../helpers/changeContnet';
import undo from '../../helpers/undo';
import redo from '../../helpers/redo';
import reEnter from '../../helpers/reFocus';
import { stateNames } from '../../../..';
import RightCommand from '../../helpers/rightCommand';
import paste from '../../../../images/paste.png';


export default (props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch } = props;
  const {
    menuFontFamily, menuInited, lang,
  } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = (): void => {
    reEnter();
    if (!open) {
      dispatch({ type: 'menuInited', menuInited: true });
    }
    // reEnter();
    setOpen(!open);
  };
  const handleMouseLeave = (): void => {
    if (open) {
      setOpen(false);
    }
  };
  const handleMouseEnter = (): void => {
    if (!open) {
      if (menuInited) {
        setOpen(true);
      }
    }
  };
  const handleUndo = (): void => {
    undo({ state, dispatch });
    setOpen(false);
  };
  const handleRedo = (): void => {
    redo({ state, dispatch });
    setOpen(false);
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper elevation={10} className={classes.dropdown} style={open ? { display: 'inline-block' } : { display: 'none' }}>
        <List onClick={(): void => dispatch({ type: 'menuInited', menuInited: false })}>
          <Divider />
          <ListItem
            dense
            id="undo"
            key="undo"
            button
            onClick={handleUndo}
          >
            <ListItemIcon><Undo /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.undo[lang] }
              </div>
            </ListItemText>
            <RightCommand cmd letter="z" fontFamily={menuFontFamily} />
          </ListItem>
          <ListItem
            dense
            id="redo"
            key="redo"
            button
            onClick={handleRedo}
          >
            <ListItemIcon><Redo /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.redo[lang] }
              </div>
            </ListItemText>
            <RightCommand cmd shift letter="z" fontFamily={menuFontFamily} />
          </ListItem>
          <Divider />
          <ListItem
            dense
            id="cut"
            key="cut"
            button
            onClick={() => { cutSelected(state, dispatch); setOpen(false); }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <path d="M9.64 7.64c.23-.5.36-1.05.36-1.64 0-2.21-1.79-4-4-4S2 3.79 2 6s1.79 4 4 4c.59 0 1.14-.13 1.64-.36L10 12l-2.36 2.36C7.14 14.13 6.59 14 6 14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64L12 14l7 7h3v-1L9.64 7.64zM6 8c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm0 12c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5zM19 3l-6 6 2 2 7-7V3z" />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.cut[lang] }
              </div>
            </ListItemText>
            <RightCommand cmd letter="x" fontFamily={menuFontFamily} />
          </ListItem>
          <ListItem
            dense
            id="copy"
            key="copy"
            button
            onClick={() => { copySelected(state, dispatch); setOpen(false); }}
          >
            <ListItemIcon><Copy /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.copy[lang] }
              </div>
            </ListItemText>
            <RightCommand cmd letter="c" fontFamily={menuFontFamily} />
          </ListItem>
          <ListItem
            dense
            id="paste"
            key="paste"
            button
            onClick={() => { pasteSelected(state, dispatch); setOpen(false); }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <path d="M19 2h-4.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V4h2v3h10V4h2v16z" />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.paste[lang] }
              </div>
            </ListItemText>
            <RightCommand cmd letter="v" fontFamily={menuFontFamily} />
          </ListItem>
          {/* <ListItem
            dense
            id="pasteUnformatted"
            key="pasteUnformatted"
            button
            onClick={() => { pasteSelectedUnformatted(state, dispatch); setOpen(false); }}
          >
            <ListItemIcon>
              <img style={{ opacity: 0.7 }} src={paste} alt="" width="21" height="21" />
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {`${translateWords.paste[lang]} ${translateWords.unformatted[lang]}` }
              </div>
            </ListItemText>
            <RightCommand cmd shift letter="v" fontFamily={menuFontFamily} />
          </ListItem> */}
          <ListItem
            dense
            id="delete"
            key="delete"
            button
            onClick={() => { deleteSelected(state, dispatch); setOpen(false); }}
          >
            <ListItemIcon><Delete /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.delete[lang] }
              </div>
            </ListItemText>
            <RightCommand cmd letter="dlt" fontFamily={menuFontFamily} />
          </ListItem>
          <Divider />
          <ListItem
            dense
            id="findAndReplace"
            key="findAndReplace"
            button
            onClick={() => {
              dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'findAndReplace' });
              setOpen(false);
            }}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.findAndReplace[lang] }
              </div>
            </ListItemText>
            <RightCommand cmd letter="f" fontFamily={menuFontFamily} />
          </ListItem>
          {/* <FindReplace {...props} menuSetOpen={setOpen} name={translateWords.findAndReplace} /> */}
        </List>
      </Paper>
      <IconButton
        className={classes.icon}
        onMouseEnter={handleMouseEnter}
        ref={anchorRef}
        onClick={handleToggle}
      >
        {lang <= 2 ? (
          <Capitalized
            word={translateWords.edit[lang]}
            {...props}
          />
        ) : translateWords.edit[lang]}
      </IconButton>
    </div>
  );
};
