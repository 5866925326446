import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {
  FormGroup, DialogContentText,
  TextField, ListItem, ListItemIcon, ListItemText, Grid,
} from '@material-ui/core';
import {
  OpenInBrowser,
} from '@material-ui/icons';
import _ from 'lodash';
import Table from '@material-ui/icons/TableChart';
import { translateWords } from '../../../translate';
import onKeyDown from '../../../eventHandlers/onKeyDown';
import addChart from './addChart';
import { textParent } from '../../../eventHandlers/changeHistory';
import { dotContainerClass, chartClass } from '../../../nameGenerator';
import addTable from './addTable';
import reEnter from './reFocus';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const PaperComponent = (props): JSX.Element => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);


export default (props): JSX.Element => {
  const {
    setMenuOpen, name, state, dispatch, closeSubMenu,
  } = props;
  const {
    menuFontFamily, lang,
  } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ menuFontFamily });
  const handleClick = (letter): void => {
    const { parent, index, focusOffset } = textParent;
    if (parent && focusOffset) {
      const textNode = parent.childNodes[index];
      if (textNode && textNode.nodeType === Node.TEXT_NODE) {
        let text = textNode.textContent;
        if (text) {
          text = text.substr(0, focusOffset - 1)
          + letter
          + text.substr(focusOffset, text.length);
          textNode.textContent = text;
          const range = document.createRange();
          const sel = window.getSelection();
          if (sel) {
            range.setStart(textNode, focusOffset);
            range.setEnd(textNode, focusOffset);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
          }
        }
      }
    }
    setOpen(false);
  };
  const handleClickOpen = (event): void => {
    event.stopPropagation();
    setOpen(true);
    if (setMenuOpen) {
      setMenuOpen(false);
    }
  };
  const handleClose = (event): void => {
    event.stopPropagation();
    setOpen(false);
    reEnter();
  };
  return (
    <div>
      <ListItem
        dense
        button
        id="open"
        key="open"
        onClick={handleClickOpen}
        onMouseEnter={closeSubMenu}
      >
        <ListItemText>
          <div style={{ fontFamily: menuFontFamily }}>
            {name[lang]}
          </div>
        </ListItemText>
      </ListItem>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div style={{ fontFamily: menuFontFamily }}>
            { name[lang]}
          </div>
        </DialogTitle>
        <DialogContent>
          {
      '፩፪፫፬፭፮፯፰፱፲፳፴፵፶፷፸፹፺፻፼ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρστυφχψω'.split('').map(l => <Button id={l} key={l} onClick={() => handleClick(l)} style={{ fontFamily: menuFontFamily }}>{l}</Button>)
            }
        </DialogContent>
        <DialogActions>
          <Button style={{ fontFamily: menuFontFamily }} color="secondary" onClick={(e): void => handleClose(e)}>
            { translateWords.cancel[lang]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
