import _ from 'lodash';
import { moveCaretInside, replaceCaret } from './helpers/moveFocus';
import paragraph from './helpers/paragraph';
import keyPressed from './helpers/keyPressed';
import { stateNames } from '../index';
import { onDeletingChange } from './onChange';
import undo from '../components/editor/helpers/undo';
import redo from '../components/editor/helpers/redo';
import { dotTableClass, dotParagraphClass, getPrevPage, dotContainerClass } from '../nameGenerator';
// import { scrollToView } from '../components/editor/helpers/reFocus';
import { textParent, currentTransfer, currentMoveable, bodyToggle } from './changeHistory';
import {
  saveSelection, copySelected, cutSelected, deleteSelected,
} from '../components/editor/helpers/changeContnet';
import splitPara from './helpers/splitPara';
import onPaste from './onPaste';

const adjustFocus = (sel, parent, offset) => {
  const range = document.createRange();
  range.setStart(parent, offset);
  range.setEnd(parent, offset);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};

const vowelState = {
  consonant: '',
  vowel: '',
};

const onKeyDown = (props): void => {
  const { event, state, dispatch } = props;
  if (!event) { return; }
  const { target } = event;
  if (!target) return;
  // const { fontFamily } = state as InitialState;
  if (event.key === 'Backspace' && event.target.nodeName !== 'INPUT') {
    return;
    // const sel = window.getSelection();
    // if (sel) {
    //   const {
    //     anchorNode, anchorOffset, focusNode, focusOffset,
    //   } = sel;
    //   const thisPara = (target as HTMLElement).querySelector(dotParagraphClass);
    //   const prevBody = getPrevPage(target.id);
    //   if (focusNode) {
    //     const focusPara = focusNode.nodeType === Node.TEXT_NODE ? focusNode.parentElement : focusNode;
    //     const closestPara = (focusPara as HTMLElement).closest(dotParagraphClass);
    //     const isFirstPara = closestPara === thisPara;
    //     if ((focusNode === thisPara || isFirstPara) && focusOffset === 0) {
    //       if (prevBody) {
    //         replaceCaret(prevBody);
    //         prevBody.scrollIntoView();
    //       }
    //     }
    //   }
    //   const firstPara = document.querySelector(dotParagraphClass);
    //   if (firstPara && firstPara.contains(anchorNode)) {
    //     if (anchorOffset === 0) {
    //       // dont delete first paragraph of first page
    //       event.preventDefault();
    //     }
    //   }
    // }
    // target.scrollIntoView({ block: 'center', inline: 'center' })
    // onDeletingChange({ target, state, dispatch });
    // target.scrollIntoView({ block: 'center', inline: 'center' });
    return;
  }

  if (event && event.key && event.metaKey && event.shiftKey && event.key.toLowerCase() === 'z') {
    event.preventDefault();
    redo({ state, dispatch });
    // dispatch({ type: stateNames.setRedo, setRedo: true });
    return; // return here so undo will not trigger because ctr-shift-z is alos ctr-z
  }
  // undo
  if (event && event.key && event.metaKey && event.key.toLowerCase() === 'z') {
    event.preventDefault();
    undo({ state, dispatch });
    // dispatch({ type: stateNames.setUndo, setUndo: true });
    return;
  }
  // swith to English
  if (event && event.key && event.metaKey && event.key.toLowerCase() === 'e') {
    event.preventDefault();
    dispatch({ type: stateNames.bodyToggle });
    bodyToggle.toggle = !bodyToggle.toggle;
    return;
  }
  // find and replace
  if (event && event.key && event.metaKey && event.key.toLowerCase() === 'f') {
    event.preventDefault();
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'findAndReplace' });
    return;
  }
  // cut
  if (event && event.key && event.metaKey && event.key.toLowerCase() === 'x') {
    // event.preventDefault();
    saveSelection(true); // cut is true
    return;
  }
  // delete
  if (event && event.key === 'delete') {
    return;
  }

  // copy
  if (event && event.key && event.metaKey && event.key.toLowerCase() === 'c') {
    // event.preventDefault();
    saveSelection(false); // cut false
    return;
  }
  // paste
  if (event && event.key && event.metaKey && event.key.toLowerCase() === 'v') {
    return;
  }
 

  const sel = window.getSelection();
  if (sel && sel.focusNode && sel.focusNode === target) {
    const newParagraph = paragraph(state);
    if (target) {
      target.insertAdjacentElement('afterbegin', newParagraph);
    }
    moveCaretInside(newParagraph);
  }

  const matchCharOrSpace = event.key.match(/\w| /);
  // match only chars like k, x, not 'Enter' , 'Tab', 'Escape', etc...
  if (matchCharOrSpace && matchCharOrSpace.input.length === 1) {
    keyPressed({ event, vowelState, ...props });
  }

  if (!sel) return;
  const { focusNode, focusOffset } = sel;
  if (!focusNode) return;
  const parent = (focusNode.nodeType === Node.TEXT_NODE
    ? focusNode.parentElement
    : focusNode) as HTMLElement;
  const para = parent.closest('.derassi-paragraph');
  const imageCaption = parent.closest('.derassi-image-caption');
  if (event && event.key === 'Tab') {
    event.preventDefault();
    if (para) {
      const initial = parseFloat((para as HTMLElement).style.textIndent);
      (para as HTMLElement).style.textIndent = initial ? 'initial' : '10%';
      // replaceCaret(focusNode);
    } else {
      const { nextElementSibling } = parent as HTMLElement;
      if (nextElementSibling) {
        moveCaretInside(nextElementSibling);
      } else {
        const { parentElement } = parent as HTMLElement;
        if (parentElement && parentElement.nextElementSibling) {
          moveCaretInside(parentElement.nextElementSibling);
        }
      }
    }
  }
  if (event && event.key === 'Enter') {
    if (para) {
      event.preventDefault();
      para.normalize();
      // newParagraph.style.fontFamily = fontFamily;
      // move content after focus to next paragraph
      if (para.textContent) {
        const newParagraph = splitPara(para, sel, focusNode, state);
        // const range = document.createRange();
        // range.setStart(focusNode, sel.focusOffset);
        // const end = para.lastElementChild ? para.lastElementChild : focusNode;
        // const endLen = para.lastElementChild
        //   ? para.lastElementChild.childNodes.length
        //   : sel.focusOffset;
        // range.setEnd(end, endLen || 0);
        // const extract = range.extractContents();
        // const span = document.createElement('span');
        // span.append(extract);
        // // extract should include br so over write br here.
        // newParagraph.innerHTML = span.innerHTML;
        para.insertAdjacentElement('afterend', newParagraph);
        if (newParagraph) {
          moveCaretInside(newParagraph);
        }
        // if at begingin , insert Tab
      } else {
        const newParagraph = paragraph(state);
        para.insertAdjacentElement('afterend', newParagraph);
        if (newParagraph) {
          moveCaretInside(newParagraph);
        }
      }
      para.scrollIntoView({ block: 'center', inline: 'center' });
      textParent.parent = para;
      textParent.focusOffset = 0;
      textParent.index = 0;
    } else if (imageCaption) {
      const container = parent.closest(dotContainerClass);
      if (container) {
        const newPara = paragraph(state);
        container.insertAdjacentElement('afterend', newPara);
        moveCaretInside(newPara);
      }
    } else {
      const { nextElementSibling } = parent as HTMLElement;
      if (nextElementSibling) {
        moveCaretInside(nextElementSibling);
      } else {
        const { parentElement } = parent as HTMLElement;
        if (parentElement && parentElement.nextElementSibling) {
          moveCaretInside(parentElement.nextElementSibling);
        }
      }
    }

    // for everything else
  }

  // Escape to get focus out of current node
  if (event && event.key === 'Escape') {
    if (sel) {
      if (focusNode) {
        if (parent) {
          if (para === parent) return;
          const { nextElementSibling } = parent as HTMLElement;
          if (nextElementSibling) {
            moveCaretInside(nextElementSibling);
          } else {
            const span = document.createElement('span');
            parent.insertAdjacentElement('afterend', span);
            moveCaretInside(span);
          }
        }
      }
    }
    // if (para) {
    //   return;
    // }
    // if (ol) {
    //   const div = ol.closest('.derassi-list');
    //   if (div && div.previousSibling) {
    //     replaceCaret(div.previousSibling);
    //   }
    // }
    //  else if (caption) {
    //   const div = caption.closest('.derassi-moveable-container');
    //   if (div && div.previousSibling) {
    //     replaceCaret(div.previousSibling);
    //   }
    // }
  }
};

export default onKeyDown;
