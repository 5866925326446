import uniqid from 'uniqid';
import * as ng from '../../../nameGenerator';
import attachControl from './attachControl';
import { stateNames } from '../../../index';
import { currentMoveable } from '../../../eventHandlers/changeHistory';


const drawLine = (ctx, x1, y1, x2, y2): void => {
  const context = ctx;
  context.beginPath();
  context.strokeStyle = 'black';
  context.lineWidth = 1;
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
  context.closePath();
};

export default (props: {
  refContainer: HTMLElement; state: InitialState; dispatch: Function;
}): void => {
  const { refContainer, state, dispatch } = props;

  // When true, moving the mouse draws on the canvas
  let isDrawing = false;
  let x = 0;
  let y = 0;
  const someId = uniqid();
  const id = ng.generateId(someId, 'signature'); // `derassi-${type}-${someId}`;
  const container = document.createElement('div');
  container.id = id;
  container.classList.add('derassi-signature', ng.unformattedClass, ng.containerClass, ng.moveableClass);
  container.style.display = 'block';
  container.addEventListener('dblclick', () => {
    attachControl({
      container, signature: true, state, dispatch,
    });
    isDrawing = false;
  });
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  container.append(canvas);
  canvas.width = 400;
  canvas.height = 300;
  canvas.style.border = '1px solid black';
  const context = canvas.getContext('2d');
  canvas.addEventListener('mousedown', (e) => {
    x = e.offsetX;
    y = e.offsetY;
    isDrawing = true;
  });

  canvas.addEventListener('mousemove', (e) => {
    if (isDrawing === true) {
      drawLine(context, x, y, e.offsetX, e.offsetY);
      x = e.offsetX;
      y = e.offsetY;
    }
  });

  window.addEventListener('mouseup', (e) => {
    if (isDrawing === true) {
      drawLine(context, x, y, e.offsetX, e.offsetY);
      x = 0;
      y = 0;
      isDrawing = false;
    }
  });
  drawLine(context, x, y, x, y);
  dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editSignature' });
  currentMoveable.element = container;
  refContainer.insertAdjacentElement('afterend', container);
};
