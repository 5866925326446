import { containerClass, dotParagraphClass } from '../../nameGenerator';

export default (p) => {
  const para = p as HTMLElement;
  // if (!para) return;
  // if (!para.classList.contains(dotParagraphClass)) return;
  // remove orphans (single parents with no textnode in them)
  const iterator = document.createNodeIterator(para, NodeFilter.SHOW_ELEMENT);
  let currentNode = iterator.nextNode();
  if (!currentNode) return;
  while (currentNode) {
    const element = currentNode as HTMLElement;
    if (element
        && element !== para
        && element.nodeName !== 'BR'
        && !element.classList.contains(containerClass)
        && element.childNodes.length === 1
        && element.childNodes[0].nodeType !== Node.TEXT_NODE) {
      element.replaceWith(...Array.from(element.childNodes));
    }
    if (element
        && element !== para
        && element.nodeName !== 'BR'
        && !element.textContent
        && !element.classList.contains(containerClass)) {
      element.remove();
    }
    currentNode = iterator.nextNode();
  }
  para.normalize(); // text normalization.
};
