import React from 'react';
import {
  ListItem, ListItemText, ListItemIcon, Button,
} from '@material-ui/core';
import Print from '@material-ui/icons/Print';
import { translateWords } from '../../translate';
import generatePDF from './generatePDF';
import RightCommand from '../editor/helpers/rightCommand';
// const RightCommand = (props) => {
//   const {
//     cmd, shift, letter, fontFamily,
//   } = props;
//   return (
//     <Button
//       size="small"
//       variant="text"
//       disabled
//       style={{
//         fontFamily,
//         padding: '1px !important',
//         transform: 'translate(5px)',
//       }}
//     >
//       {cmd ? '\u2318' : ''}
//       {shift ? '\u2303' : ''}
//       {' '}
//       {letter}
//     </Button>
//   );
// };

export default (props) => {
  const { state, setOpen } = props;
  const { menuFontFamily, lang } = state as InitialState;
  const handleClick = () => {
    window.print();
    setOpen(false);
  };
  return (
    <ListItem
      dense
      button
      onClick={handleClick}
    >
      <ListItemIcon><Print /></ListItemIcon>
      <ListItemText>
        <div style={{ fontFamily: menuFontFamily }}>
          { translateWords.to[lang] }
          {'  '}
          { translateWords.print[lang] }
        </div>
      </ListItemText>
      <RightCommand cmd letter="p" fontFamily={menuFontFamily} />
    </ListItem>
  );
};
