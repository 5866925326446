import * as ng from '../nameGenerator';
import { stateNames } from '../index';

export default (props) => {
  const { event, state, dispatch } = props;
  const target = event.target as HTMLElement;
  const dirtyMoveable = document.querySelector('.moveable-control-box');
  // if (target && !target.closest(ng.dotMoveableClass)) {
  //   // if (dirtyMoveable) {
  //   // }
  // }
};
