import * as ng from '../../../nameGenerator';
import highlight from '../../../eventHandlers/helpers/highLight';
import { stateNames } from '../../..';
import { currentMoveable } from '../../../eventHandlers/changeHistory';
import { dotParagraphClass } from '../../../nameGenerator';

export default (props:
  {
    state?: InitialState;
    dispatch?: any;
    size?: number; // to change size of fonts
    klass?: string; // for adding css classes for indentaion, floating, etc..
    attr?: string; // for adding css attributes
    value?: string; // css attribute values
    fullParagraph?: boolean;
  }): void => {
  const {
    size, klass, attr, value, fullParagraph, state, dispatch,
  } = props;
  const allSelected = document.querySelectorAll('.derassi-selected');
  allSelected.forEach((selected) => {
    if (size) {
      const { classList } = selected;
      if (classList) {
        const klasses = Array.from(classList).filter(x => x.includes('derassi-font-size'));
        klasses.forEach(klassName => selected.classList.remove(klassName));
      }
      selected.classList.add(`derassi-font-size-${size}`);
    }
    if (klass) {
      if (selected.classList.contains(klass)) {
        selected.classList.remove(klass);
      } else {
        selected.classList.add(klass);
      }
    }
    if (attr && value) {
      const currentSelected = selected;
      (currentSelected as HTMLElement).style[attr] = value;
    }
  });
  if (fullParagraph && state) {
    const moveable = currentMoveable.element;
    if (moveable) {
      const current = moveable;
      const existing = document.querySelector('.moveable-control-box');
      if (existing) existing.remove();
      if (dispatch) {
        dispatch({ type: stateNames.showThirdMenu, showThirdMenu: '' });
      }
      current.style.display = 'inline';
      if (value === 'center') {
        current.style.float = 'none';
        current.style.left = '50%';
        current.style.right = '-50%';
        current.style.display = 'inline';
      } else if (value === 'right' || value === 'left') {
        current.style.float = value || '';
      } else if (value === 'justify') {
        // current.style.transform = 'initial';
        // current.style.float = '';
        current.style.float = 'none';
        current.style.marginLeft = 'auto';
        current.style.marginRight = 'auto';
        current.style.display = 'block';
      }
    } else {
      const paras = document.querySelectorAll(dotParagraphClass);
      if (paras) {
        Array.from(paras).forEach((para) => {
          if (attr && value) {
            const currentPara = para as HTMLElement;
            currentPara.style[attr] = value;
          }
        });
      }
    }
  }

  // } else {
  //   // header of footer, use some other method to extarct selectd. like range.extractContent
  // }
};
