
export default async (body, id, meta): Promise<any> => {
  // const {
  //   loading, isSignedIn, gapi, currentID, currentTitle,
  // } = state as InitialState;
  // const accessToken = localStorage.getItem('GAPI_ACCESS_TOKEN');
  // if (loading) return { loading };
  // if (!accessToken) return { error: { message: 'not authorized', location: 'service/update' } };
  const { gapi } = window as any;
  const currentID = id;
  if (!gapi) return { error: { message: 'gapi not loaded', location: 'service/update' } };
  let url = `https://www.googleapis.com/upload/drive/v3/files/${currentID}`;
  if (meta) {
    url = `https://www.googleapis.com/drive/v3/files/${currentID}`;
  }
  try {
    const result = await gapi.client.request({
      path: url,
      method: 'PATCH',
      // header: {
      //   Accept: 'application/json',
      //   'Content-Type': 'application/json',
      // },
      params: {
        fields: '*',
        uploadType: 'multipart',
      },
      // mimeType: 'application/json',
      body: JSON.stringify(body),
    });
    return result;
  } catch (err) {
    return { error: { message: err.message, location: 'service/update' } };
  }
};
