import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    // minWidth: '100%',
    // padding: theme.spacing(-11),

  },
  dropdown: {
    position: 'absolute',
    display: 'inline-block',
    zIndex: 3,
    top: 40,
    // minWidth: '100%',
    // display: 'inline',
  },
  caret: {
    // flexGrow: 1,
    // paddingLeft: -4,
  },
  list: {
    minWidth: 200,
  },
  icon: {
    color: 'black',
    fontSize: 14,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: 'blue',
    },
  },
  nested: {
    position: 'absolute',
    // display: 'inline-block',
    display: 'none',
    left: 200,
    top: 10,
  },
}));
