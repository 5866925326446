import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    opacity: 0.6,
    float: 'right',
    textTransform: 'capitalize',
    marginLeft: 10,
    fontSize: 12,
  },
}));

export default (props) => {
  const classes = useStyles();
  const {
    cmd, shift, letter, fontFamily,
  } = props;
  return (
    <span
      className={classes.root}
      style={{
        fontFamily,
      }}
    >
      {cmd ? '\u2318' : ''}
      {shift ? '\u2303' : ''}
      {' '}
      {letter}
    </span>
  );
};
