import useGapi from './useGapi';
import paragraph from '../eventHandlers/helpers/paragraph';
import { generatePageId } from '../nameGenerator';
import _ from 'lodash';
import devLog from '../devLog';
import { currentFont } from '../eventHandlers/changeHistory';
import { stateNames } from '..';


// type GetDoc = {
//   loading?: boolean;
//   error?: { message: string; location: string };
//   doc?: DerassiDoc;
// }
const getFake = (fake) => {
  const file = localStorage.getItem('derassiDoc');
  let doc = fake;
  if (file) {
    doc = JSON.parse(file);
    if (!doc || _.isEmpty(doc.pages)) {
      return { doc: fake };
    }
  }
  return { doc };
};

export default async (fileID, state, dispatch): Promise<any> => {
  // const win = (window as any);
  // if (!win) return {};
  // const { gapi } = win;
  const {
    loading, isSignedIn, gapi, menuFontFamily, localFile,
  } = state as InitialState;
  const newParagraph = paragraph(state);
  const blankPage: Page = { headerHTML: '', bodyHTML: newParagraph.outerHTML, footerHTML: '' };
  const firstPage: Record<string, Page> = {};
  firstPage[`${generatePageId(0)}`] = blankPage;
  const fake: DerassiDoc = {
    name: '',
    id: '',
    pages: firstPage,
    reFocus: null,
    derassiHeader: null,
    derassiFooter: null,
  };
  // if local or not signed in return
  // current localStorage file,
  // this helps keep not signed work saved at reaload time
  // but sideeffect is, it shows up first when user reloads when signed
  if (localFile) {
    return getFake(fake);
  }
  if (!isSignedIn) {
    return { doc: fake };
  }
  const accessToken = localStorage.getItem('GAPI_ACCESS_TOKEN');
  if (loading) return { loading };
  if (!accessToken) return { error: { message: 'not authorized', location: 'service/get' } };
  if (!isSignedIn) return { error: { message: 'not signed in', location: 'service/get' } };
  if (!gapi) return { error: { message: 'gapi not loaded', location: 'service/get' } };
  const url = `https://www.googleapis.com/drive/v3/files/${fileID}`;
  try {
    const response = await gapi.client.request({
      path: url,
      method: 'GET',
      params: {
        fields: '*',
        alt: 'media',
      },
      // headers: {
      //   Accept: 'application/json',
      //   Authorization: `Bearer ${accessToken}`,
      //   'Content-Type': 'application/json',
      // },
    });
    const { result, status } = response;
    if (!result) {
      return {
        doc: fake,
      };
    }
    const doc: DerassiDoc = {
      name: result.name || '',
      id: result.id || '',
      pages: result.pages || { headerHTML: '', footerHTML: '', bodyHTML: '' },
      reFocus: result.reFocus || '',
      derassiHeader: result.derassiHeader,
      derassiFooter: result.derassiFooter,
      menuFontFamily: result.menuFontFamily || menuFontFamily,
      fontFamily: result.fontFamily || currentFont.family,
      fontSize: result.fontSize || currentFont.size,
    };
    dispatch({ type: stateNames.localFile, localFile: false });
    return { doc };
  } catch (err) {
    // if there is no file to fetch, get the one from localStorage
    dispatch({ type: stateNames.localFile, localFile: true });
    devLog(`error in get line 82: ${err}`);
    return getFake(fake);
    // return { error: { message: err.message, location: 'service/get' } };
  }
};
