const keyboardMap = {

  he: 'ሀ',
  hoo: 'ሁ',
  hu: 'ሁ',
  hee: 'ሂ',
  hi: 'ሂ',
  ha: 'ሃ',
  haa: 'ሄ',
  hie: 'ሄ',
  hei: 'ሄ',
  h: 'ህ',
  ho: 'ሆ',
  hao: 'ሇ',
  hoa: 'ሇ',

  hxe: 'ሐ',
  hxoo: 'ሑ',
  hxu: 'ሑ',
  hxi: 'ሒ',
  hxee: 'ሒ',
  hxa: 'ሓ',
  hxaa: 'ሔ',
  hxie: 'ሔ',
  hxei: 'ሔ',
  hx: 'ሕ',
  hxo: 'ሖ',
  hxao: 'ሗ',
  hxoa: 'ሗ',

  hce: 'ኀ',
  hcoo: 'ኁ',
  hcu: 'ኁ',
  hci: 'ኂ',
  hcee: 'ኂ',
  hca: 'ኃ',
  hcaa: 'ኄ',
  hcie: 'ኄ',
  hcei: 'ኄ',
  hc: 'ኅ',
  hco: 'ኆ',
  hcoa: 'ኋ',
  hcao: 'ኋ',

  le: 'ለ',
  loo: 'ሉ',
  lu: 'ሉ',
  li: 'ሊ',
  lee: 'ሊ',
  la: 'ላ',
  laa: 'ሌ',
  lie: 'ሌ',
  lei: 'ሌ',
  l: 'ል',
  lo: 'ሎ',
  lao: 'ሏ',
  loa: 'ሏ',

  me: 'መ',
  moo: 'ሙ',
  mu: 'ሙ',
  mi: 'ሚ',
  mee: 'ሚ',
  ma: 'ማ',
  maa: 'ሜ',
  mie: 'ሜ',
  mei: 'ሜ',
  m: 'ም',
  mo: 'ሞ',
  mao: 'ሟ',
  moa: 'ሟ',

  se: 'ሰ',
  soo: 'ሱ',
  su: 'ሱ',
  see: 'ሲ',
  si: 'ሲ',
  sa: 'ሳ',
  saa: 'ሴ',
  sei: 'ሴ',
  sie: 'ሴ',
  s: 'ስ',
  so: 'ሶ',
  sao: 'ሷ',
  soa: 'ሷ',

  she: 'ሸ',
  shoo: 'ሹ',
  shu: 'ሹ',
  shi: 'ሺ',
  shee: 'ሺ',
  sha: 'ሻ',
  shaa: 'ሼ',
  shie: 'ሼ',
  shei: 'ሼ',
  sh: 'ሽ',
  sho: 'ሾ',
  shoa: 'ሿ',
  shao: 'ሿ',

  sce: 'ሠ',
  scoo: 'ሡ',
  scu: 'ሡ',
  sci: 'ሢ',
  scee: 'ሢ',
  sca: 'ሣ',
  scaa: 'ሤ',
  scie: 'ሤ',
  scei: 'ሤ',
  sc: 'ሥ',
  sco: 'ሦ',
  scoa: 'ሧ',
  scao: 'ሧ',

  re: 'ረ',
  roo: 'ሩ',
  ru: 'ሩ',
  ri: 'ሪ',
  ree: 'ሪ',
  ra: 'ራ',
  raa: 'ሬ',
  rie: 'ሬ',
  rei: 'ሬ',
  r: 'ር',
  ro: 'ሮ',
  rao: 'ሯ',
  roa: 'ሯ',

  qe: 'ቀ',
  qoo: 'ቁ',
  qu: 'ቁ',
  qi: 'ቂ',
  qee: 'ቂ',
  qa: 'ቃ',
  qaa: 'ቄ',
  qei: 'ቄ',
  qie: 'ቄ',
  q: 'ቅ',
  qo: 'ቆ',
  qao: 'ቋ',
  qoa: 'ቋ',

  be: 'በ',
  boo: 'ቡ',
  bu: 'ቡ',
  bi: 'ቢ',
  bee: 'ቢ',
  ba: 'ባ',
  baa: 'ቤ',
  bei: 'ቤ',
  bie: 'ቤ',
  b: 'ብ',
  bo: 'ቦ',
  bao: 'ቧ',
  boa: 'ቧ',

  ve: 'ቨ',
  voo: 'ቩ',
  vu: 'ቩ',
  vi: 'ቪ',
  vee: 'ቪ',
  va: 'ቫ',
  vaa: 'ቬ',
  vei: 'ቬ',
  vie: 'ቬ',
  v: 'ቭ',
  vo: 'ቮ',
  vao: 'ቯ',
  voa: 'ቯ',

  te: 'ተ',
  too: 'ቱ',
  tu: 'ቱ',
  tee: 'ቲ',
  ti: 'ቲ',
  ta: 'ታ',
  taa: 'ቴ',
  tie: 'ቴ',
  tei: 'ቴ',
  t: 'ት',
  to: 'ቶ',
  tao: 'ቷ',
  toa: 'ቷ',

  che: 'ቸ',
  choo: 'ቹ',
  chu: 'ቹ',
  chi: 'ቺ',
  chee: 'ቺ',
  cha: 'ቻ',
  chaa: 'ቼ',
  chei: 'ቼ',
  chie: 'ቼ',
  ch: 'ች',
  cho: 'ቾ',
  chao: 'ቿ',
  choa: 'ቿ',


  txe: 'ጠ',
  txoo: 'ጡ',
  txu: 'ጡ',
  txi: 'ጢ',
  txee: 'ጢ',
  txa: 'ጣ',
  txaa: 'ጤ',
  txie: 'ጤ',
  txei: 'ጤ',
  tx: 'ጥ',
  txo: 'ጦ',
  txao: 'ጧ',
  txoa: 'ጧ',

  chxe: 'ጨ',
  chxoo: 'ጩ',
  chxu: 'ጩ',
  chxi: 'ጪ',
  chxee: 'ጪ',
  chxa: 'ጫ',
  chxaa: 'ጬ',
  chxie: 'ጬ',
  chxei: 'ጬ',
  chx: 'ጭ',
  chxo: 'ጮ',
  chxao: 'ጯ',
  chxoa: 'ጯ',


  ne: 'ነ',
  noo: 'ኑ',
  nu: 'ኑ',
  ni: 'ኒ',
  nee: 'ኒ',
  na: 'ና',
  naa: 'ኔ',
  nei: 'ኔ',
  nie: 'ኔ',
  n: 'ን',
  no: 'ኖ',
  nao: 'ኗ',
  noa: 'ኗ',

  nxe: 'ኘ',
  nxoo: 'ኙ',
  nxu: 'ኙ',
  nxi: 'ኚ',
  nxee: 'ኚ',
  nxa: 'ኛ',
  nxaa: 'ኜ',
  nxie: 'ኜ',
  nxei: 'ኜ',
  nx: 'ኝ',
  nxo: 'ኞ',
  nxao: 'ኟ',
  nxoa: 'ኟ',

  ae: 'ዐ',
  aoo: 'ዑ',
  au: 'ዑ',
  ai: 'ዒ',
  aee: 'ዒ',
  aa: 'ዓ',
  aaa: 'ዔ',
  aei: 'ዔ',
  aie: 'ዔ',
  a: 'ዕ',
  ao: 'ዖ',
  aoa: '',
  aao: '',

  oe: 'ዐ',
  ooo: 'ዑ',
  ou: 'ዑ',
  oi: 'ዒ',
  oee: 'ዒ',
  oa: 'ዓ',
  oaa: 'ዔ',
  oei: 'ዔ',
  oie: 'ዔ',
  o: 'ዕ',
  oo: 'ዖ',
  oao: '',
  ooa: '',

  ee: 'አ',
  eoo: 'ኡ',
  eu: 'ኡ',
  ei: 'ኢ',
  eee: 'ኢ',
  ea: 'ኣ',
  eaa: 'ኤ',
  eie: 'ኤ',
  eei: 'ኤ',
  e: 'እ',
  eo: 'ኦ',
  eao: 'ኧ',
  eoa: 'ኧ',

  ie: 'አ',
  ioo: 'ኡ',
  iu: 'ኡ',
  ii: 'ኢ',
  iee: 'ኢ',
  ia: 'ኣ',
  iaa: 'ኤ',
  iie: 'ኤ',
  iei: 'ኤ',
  i: 'እ',
  io: 'ኦ',
  iao: 'ኧ',
  ioa: 'ኧ',


  ke: 'ከ',
  koo: 'ኩ',
  ku: 'ኩ',
  ki: 'ኪ',
  kee: 'ኪ',
  ka: 'ካ',
  kaa: 'ኬ',
  kei: 'ኬ',
  kie: 'ኬ',
  k: 'ክ',
  ko: 'ኮ',
  kao: 'ኳ',
  koa: 'ኳ',

  ce: 'ኸ',
  coo: 'ኹ',
  cu: 'ኹ',
  ci: 'ኺ',
  cee: 'ኺ',
  ca: 'ኻ',
  caa: 'ኼ',
  cie: 'ኼ',
  cei: 'ኼ',
  c: 'ኽ',
  co: 'ኾ',
  cao: 'ዃ',
  coa: 'ዃ',

  we: 'ወ',
  woo: 'ዉ',
  wu: 'ዉ',
  wi: 'ዊ',
  wee: 'ዊ',
  wa: 'ዋ',
  waa: 'ዌ',
  wei: 'ዌ',
  wie: 'ዌ',
  w: 'ው',
  wo: 'ዎ',
  wao: 'ዏ',
  woa: 'ዏ',

  ze: 'ዘ',
  zoo: 'ዙ',
  zu: 'ዙ',
  zi: 'ዚ',
  zee: 'ዚ',
  za: 'ዛ',
  zaa: 'ዜ',
  zei: 'ዜ',
  zie: 'ዜ',
  z: 'ዝ',
  zo: 'ዞ',
  zao: 'ዟ',
  zoa: 'ዟ',

  zxe: 'ዠ',
  zxoo: 'ዡ',
  zxu: 'ዡ',
  zxi: 'ዢ',
  zxee: 'ዢ',
  zxa: 'ዣ',
  zxaa: 'ዤ',
  zxei: 'ዤ',
  zxie: 'ዤ',
  zx: 'ዥ',
  zxo: 'ዦ',
  zxao: 'ዧ',
  zxoa: 'ዧ',

  ye: 'የ',
  yoo: 'ዩ',
  yu: 'ዩ',
  yi: 'ዪ',
  yee: 'ዪ',
  ya: 'ያ',
  yaa: 'ዬ',
  yie: 'ዬ',
  yei: 'ዬ',
  y: 'ይ',
  yo: 'ዮ',
  yao: 'ዯ',
  yoa: 'ዯ',

  ue: 'የ',
  uoo: 'ዩ',
  uu: 'ዩ',
  ui: 'ዪ',
  uee: 'ዪ',
  ua: 'ያ',
  uaa: 'ዬ',
  uie: 'ዬ',
  uei: 'ዬ',
  u: 'ይ',
  uo: 'ዮ',
  uao: 'ዯ',
  uoa: 'ዯ',

  de: 'ደ',
  doo: 'ዱ',
  du: 'ዱ',
  di: 'ዲ',
  dee: 'ዲ',
  da: 'ዳ',
  daa: 'ዴ',
  dei: 'ዴ',
  die: 'ዴ',
  d: 'ድ',
  do: 'ዶ',
  dao: 'ዷ',
  doa: 'ዷ',

  je: 'ጀ',
  joo: 'ጁ',
  ju: 'ጁ',
  ji: 'ጂ',
  jee: 'ጂ',
  ja: 'ጃ',
  jaa: 'ጄ',
  jie: 'ጄ',
  jei: 'ጄ',
  j: 'ጅ',
  jo: 'ጆ',
  jao: 'ጇ',
  joa: 'ጇ',

  ge: 'ገ',
  goo: 'ጉ',
  gu: 'ጉ',
  gi: 'ጊ',
  gee: 'ጊ',
  ga: 'ጋ',
  gaa: 'ጌ',
  gie: 'ጌ',
  gei: 'ጌ',
  g: 'ግ',
  go: 'ጎ',
  gao: 'ጓ',
  goa: 'ጓ',

  tse: 'ፀ',
  tsoo: 'ፁ',
  tsu: 'ፁ',
  tsi: 'ፂ',
  tsee: 'ፂ',
  tsa: 'ፃ',
  tsaa: 'ፄ',
  tsie: 'ፄ',
  tsei: 'ፄ',
  ts: 'ፅ',
  tso: 'ፆ',
  tsao: 'ፇ',
  tsoa: 'ፇ',


  xe: 'ጸ',
  xoo: 'ጹ',
  xu: 'ጹ',
  xi: 'ጺ',
  xee: 'ጺ',
  xa: 'ጻ',
  xaa: 'ጼ',
  xei: 'ጼ',
  xie: 'ጼ',
  x: 'ጽ',
  xo: 'ጾ',
  xoa: 'ጿ',
  xao: 'ጿ',


  fe: 'ፈ',
  foo: 'ፉ',
  fu: 'ፉ',
  fi: 'ፊ',
  fee: 'ፊ',
  fa: 'ፋ',
  faa: 'ፌ',
  fei: 'ፌ',
  fie: 'ፌ',
  f: 'ፍ',
  fo: 'ፎ',
  fao: 'ፏ',
  foa: 'ፏ',

  pe: 'ፐ',
  poo: 'ፑ',
  pu: 'ፑ',
  pi: 'ፒ',
  pee: 'ፒ',
  pa: 'ፓ',
  paa: 'ፔ',
  pei: 'ፔ',
  pie: 'ፔ',
  p: 'ፕ',
  po: 'ፖ',
  pao: 'ፗ',
  poa: 'ፗ',

  pxe: 'ጰ',
  pxoo: 'ጱ',
  pxu: 'ጱ',
  pxi: 'ጲ',
  pxee: 'ጲ',
  pxa: 'ጳ',
  pxaa: 'ጴ',
  pxie: 'ጴ',
  pxei: 'ጴ',
  px: 'ጵ',
  pxo: 'ጶ',
  pxao: 'ጷ',
  pxoa: 'ጷ',

  1: '፩',
  2: '፪',
  3: '፫',
  4: '፬',
  5: '፭',
  6: '፮',
  7: '፯',
  8: '፰',
  9: '፱',
  10: '፲',
  20: '፳',
  30: '፴',
  40: '፵',
  50: '፶',
  60: '፷',
  70: '፸',
  80: '፹',
  90: '፺',
  100: '፻',
  10000: '፼',
};

export default keyboardMap;
