
import { moveCaretInside } from './helpers/moveFocus';
import paragraph from './helpers/paragraph';
import unHighlight from './helpers/unHighlight';
import changeState from './changeHistory';
import { stateNames } from '../index';
import * as ng from '../nameGenerator';

// import { detachControl } from './../components/editor/helpers/attachControl';

export default (props): void => {
  const {
    event, state, dispatch, currentMoveable,
  } = props;
  const { showThirdMenu } = state as InitialState;
  const { target, currentTarget, relatedTarget } = event;
  const elementTarget = target as HTMLElement;
  if (target && !target.closest(ng.dotContainerClass)) {
    const last = currentTarget.querySelector(ng.dotBodyClass) as HTMLElement;
    if (last) {
      const para = paragraph(state);
      last.insertAdjacentElement('beforeend', para);
      moveCaretInside(para);
    }
  }
  // remove class of all highlighted elements
  const exception = showThirdMenu === 'editSignature';
  if (!exception) {
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: '' });
  }
  unHighlight();
};
