import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import domFindAndReplace from 'dom-find-and-replace';
import {
  FormGroup, IconButton, Input, ListItemText, ListItem, TextField, Divider, Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Left from '@material-ui/icons/ChevronLeft';
import Right from '@material-ui/icons/ChevronRight';
import { translateWords } from '../../../translate';
import { stateNames } from '../../../index';
import onKeyDown from '../../../eventHandlers/onKeyDown';
import reEnter from './reFocus';
import { currentFont } from '../../../eventHandlers/changeHistory';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    fontFamily: props => props.menuFontFamily,
  },
  find: {
    fontFamily: props => props.menuFontFamily,
    right: 2,
  },
  input: {
    // backgroundColor: 'lightgrey',
    minWidth: 400,
    // borderRadius: 3,
    // cursor: 'text',
    fontFamily: props => props.menuFontFamily,
    // '& [contentEditable]': {
    //   '&:focus': {
    //     outlineColor: theme.palette.info.light,
    //     outlineWidth: 4,
    //     outlineStyle: 'solid',
    //     borderRadius: 3,
    //   },
    // },
  },
}));

const PaperComponent = (props): JSX.Element => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);


export default (props): JSX.Element => {
  const {
    menuSetOpen, name, state, dispatch,
  } = props;
  const {
    menuFontFamily, lang,
  } = state as InitialState;
  const [findReplace, setFindReplace] = React.useState({ find: '', replace: '' });
  const [follow, setFollow] = React.useState(0);
  //   const [open, setOpen] = React.useState(false);
  const classes = useStyles({ menuFontFamily });
  const findRef = React.useRef<HTMLElement>(null);
  const replaceRef = React.useRef<HTMLElement>(null);
  // const handleClickOpen = () => {
  //   dispatch({ type: stateNames.findReplaceOpen, findReplaceOpen: true });
  //   menuSetOpen(false);
  // };
  const handleClose = () => {
    reEnter();
    // remove class of all highlighted elements
    const selecteds = document.querySelectorAll('.derassi-selected');
    selecteds.forEach((element) => {
      element.classList.remove('derassi-selected');
    });
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: '' });
  };
  const handleFind = (event) => {
    event.stopPropagation();
    // setFindReplace({ ...findReplace, find: e.target.value });
    const toBeFound = findReplace.find;
    const doc = document.querySelector('#derassi-pages');
    if (doc) {
      const recover = domFindAndReplace(doc, {
        find: toBeFound,
        replace: (offsetText, foundText) => {
          const span = document.createElement('span') as HTMLSpanElement;
          span.textContent = offsetText;
          span.classList.add('derassi-selected');
          return span;
        },
      });
    }
  };
  const handleReplaceNext = () => {
    const selectes = document.querySelectorAll('.derassi-selected');
    if (follow < selectes.length && follow >= 0) {
      const current = selectes[follow] as HTMLElement;
      if (current) {
        current.innerText = findReplace.replace;
        const index = follow + 1;
        setFollow(index >= selectes.length ? selectes.length - 1 : index);
      }
    }
  };
  const handleReplaceBack = () => {
    // undo replacemnt
    const selectes = document.querySelectorAll('.derassi-selected');
    if (follow < selectes.length && follow >= 0) {
      const current = selectes[follow] as HTMLElement;
      if (current) {
        current.innerText = findReplace.find;
        const index = follow - 1;
        setFollow(index < 0 ? 0 : index);
      }
    }
  };
  const handleReplaceAll = (event) => {
    event.stopPropagation();
    const selectes = document.querySelectorAll('.derassi-selected');
    Array.from(selectes).forEach((select) => {
      const s = select as HTMLElement;
      s.innerText = findReplace.replace;
    });
  };
  const handleKeyDownFind = (event) => {
    onKeyDown({ event, ...props });
    setFindReplace({ ...findReplace, find: event.target.value });
  };
  const handleKeyDownReplace = (event) => {
    onKeyDown({ event, ...props });
    setFindReplace({ ...findReplace, replace: event.target.value });
  };
  const handlePasteFind = (event) => {
    event.stopPropagation();
    navigator.clipboard.readText().then((txt) => {
      setFindReplace({ ...findReplace, find: txt });
    });
  };
  const handlePasteReplace = (event) => {
    event.stopPropagation();
    navigator.clipboard.readText().then((txt) => {
      setFindReplace({ ...findReplace, replace: txt });
    });
  };


  // const handleReplaceAll = () => {
  //   const doc = document.querySelector('#derassi-pages');
  //   if (!doc) return;
  // };
  const handleReplace = (event) => {
    // replaces the next one
    (event as Event).stopPropagation();
    const selected = document.querySelector('.derassi-selected');
    if (!selected) return;
    const doc = document.getElementById(selected.id);
    if (!doc) return;
    const recover = domFindAndReplace(
      doc.innerHTML,
      {
        find: findReplace.find,
        replace: () => {
          const replaced = document.createElement('span');
          replaced.textContent = findReplace.replace;
          return replaced;
        },
      },
    );
    const replacer = recover;
    if (typeof replacer === 'string') {
      doc.innerHTML = replacer;
      if (doc.classList && doc.classList.contains('derassi-selected')) {
        doc.classList.remove('derassi-selected');
      }
    }
  };
  

  return (
    <div>
      <Grid container direction="row">
        <Input
          margin="none"
          ref={findRef}
          style={{ fontFamily: currentFont.family }}
          placeholder={translateWords.find[lang]}
          autoFocus
          onKeyDown={e => handleKeyDownFind(e)}
          onPaste={e => handlePasteFind(e)}
        />
        <Button variant="text" onClick={e => handleFind(e)} className={classes.find} color="primary">
          { translateWords.find[lang]}
        </Button>
        <Input
          ref={replaceRef}
          placeholder={translateWords.replace[lang]}
          style={{ fontFamily: currentFont.family }}
          onKeyDown={(e): void => handleKeyDownReplace(e)}
          onPaste={(e): void => handlePasteReplace(e)}
        />
        {/* <Button onClick={e => handleReplace(e)} className={classes.button} color="primary">
          { translateWords.replace[lang] : translateWords.replace[lang]}
        </Button> */}
        <Button size="small" onClick={handleReplaceBack} className={classes.button} color="primary">
          {/* { translateWords.previous[lang] : translateWords.previous[lang]} */}
          <Left />
        </Button>
        <Button onClick={handleReplaceNext} className={classes.button} color="primary">
          {/* { translateWords.next[lang] : translateWords.next[lang] } */}
          <Right />
        </Button>
        <Button onClick={handleReplaceAll} className={classes.button} color="primary">
          { translateWords.replaceAll[lang] }
        </Button>
        <Button onClick={handleClose} className={classes.button} color="primary">
          { translateWords.cancel[lang] }
        </Button>
      </Grid>
    </div>
  );
};
