import React from 'react';
import ReactDOM from 'react-dom';
import uniqid from 'uniqid';
import Moveable from 'moveable';
import _ from 'lodash';
import * as ng from '../../../nameGenerator';
import { stateNames } from '../../../index';
import save from '../../../services/save';
import { onAddingChange } from '../../../eventHandlers/onChange';
import getFocusPath from './getFocusPath';
import historyReducer, { textParent, saveHistory } from '../../../eventHandlers/changeHistory';
import { moveCaretInside } from '../../../eventHandlers/helpers/moveFocus';
import attachControl from './attachControl';
import devLog from '../../../devLog';

const Table = (props): JSX.Element => {
  const {
    container, data, state, dispatch,
  } = props;
  const { fontFamily } = state;
  const ref = React.useRef<HTMLTableElement>(null);

  const handleDoubleClick = () => {
    // const page = container.closest(ng.dotPageClass);
    const existing = document.querySelector('.moveable-control-box');
    if (existing) existing.remove();
    const moveable = new Moveable(container, {});
    moveable.target = container;
    moveable.throttleDrag = 0;
    moveable.keepRatio = false;
    moveable.throttleResize = 0;
    moveable.resizable = true;
    dispatch({ type: stateNames.currentMoveable, currentMoveable: container });
    /* resizable */
    moveable.on('resize', (event) => {
      const {
        width, height,
      } = event;
      container.style.width = `${width}px`;
      container.style.height = `${height}px`;
    }).on('resizeEnd', () => {
      dispatch({ type: stateNames.currentMoveable, currentMoveable: null });
      try {
        moveable.destroy();
      } catch (error) {
        devLog(error);
      }
    });
  };

  const { rows, caption } = data;
  const head = _.head(rows) as any[] || [];
  const body = _.tail(rows) as any[] || [];
  return (
    <table ref={ref} style={{ fontFamily }}>
      <caption>{caption}</caption>
      <tbody>
        <tr>
          { head.map(val => <th key={uniqid()}>{val}</th>) }
        </tr>
        { body.map(row => (
          <tr key={uniqid()}>
            { row.map(cell => <td key={uniqid()}>{cell}</td>)}
          </tr>
        )) }
      </tbody>
    </table>
  );
};

const addTable = (props): void => {
  const { container, state, dispatch } = props;
  // attachControl({ container, table: true, state, dispatch });
  ReactDOM.render(<Table {...props} />, document.getElementById(container.id));
};

export default (props: {
  data: any; state: InitialState; dispatch: Function;
  refContainer: HTMLElement; insertion: 'before' | 'after' | 'same' | 'end' | 'start';
}): void => {
  const {
    refContainer, insertion, state, dispatch,
  } = props;

  if (insertion === 'same') {
    const container = refContainer;
    addTable({ container, ...props });
    return;
  }

  const someId = uniqid();
  const id = ng.generateId(someId, 'table'); // `derassi-${type}-${someId}`;
  const klass = ng.generateClass('table');
  const container = document.createElement('div');
  const height = 150;
  const width = 400;
  container.id = id;
  container.classList.add(klass, ng.containerClass, ng.moveableClass);
  container.style.height = `${height}px`;
  container.style.width = `${width}px`;
  container.style.paddingTop = '5px';
  container.style.paddingBottom = '15px';
  container.style.breakInside = 'auto';
  container.style.pageBreakInside = 'auto';
  // attachControl({ container, state, dispatch });

  if (insertion === 'start') {
    refContainer.insertAdjacentElement('afterbegin', container);
  }
  if (insertion === 'end') {
    refContainer.insertAdjacentElement('beforeend', container);
  }
  if (insertion === 'after') {
    refContainer.insertAdjacentElement('afterend', container);
  }
  if (insertion === 'before') {
    refContainer.insertAdjacentElement('beforebegin', container);
  }

  // const target = container.closest(ng.dotContentEditableClass);
  // if (target) {
  //   onAddingChange({ target, state, dispatch });
  // }

  if (container) {
    // container.dataset.table = JSON.stringify(data);
    addTable({ container, ...props });
    // save({ state, dispatch });
    // saveHistory();
  }
};
