import { replaceCaret, moveCaretInside } from './helpers/moveFocus';
import * as ng from '../nameGenerator';
import changeState from './changeHistory';
import highLight from './helpers/highLight';
import getSureElement from '../components/editor/helpers/getSureElement';

export default (props) => {
  const { event } = props;
  const { currentTarget, target } = event;
  if (event.buttons === 1) {
    const sel = window.getSelection();
    if (sel) {
      const parent = getSureElement().parent as HTMLElement;
      if (!parent) return;
      const page = parent.closest(ng.dotBodyClass);
      if (!page) return;
      const range = sel.getRangeAt(0);
      if (!range.collapsed) {
        highLight(range);
      }
    }
    const current = currentTarget as HTMLElement;
    const body = current.querySelector(ng.dotBodyClass);
    if (body) {
      if (target && target.id.includes('header')) {
        moveCaretInside(body);
      }
      if (target && target.id.includes('footer')) {
        replaceCaret(body);
      }
    }
  }
};
