import React from 'react';
import Border from '@material-ui/icons/BorderOuter';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import reEnter from '../../helpers/reFocus';
import { currentMoveable } from '../../../../eventHandlers/changeHistory';

const useStyles = makeStyles(() => ({
  root: {
    // minWidth: '100%',
    // padding: theme.spacing(-11),

  },
  dropdown: {
    position: 'absolute',
    display: 'inline-block',
    zIndex: 3,
    top: 15,
  },
  caret: {
    // flexGrow: 1,
    // paddingLeft: -4,
  },
  item: {
    minWidth: 200,
  },
//   icon: {
//     marginLeft: 10,
//     marginRight: 10,
//   },
}));

export default (props): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = (size: number): void => {
    if (currentMoveable.element && size) {
      currentMoveable.element.style.border = `solid ${size}px black`;
    }
    setOpen(false);
  };

  const handleToggle = (): void => {
    reEnter();
    setOpen(!open);
  };
  const handleMouseLeave = (): void => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper
        className={classes.dropdown}
        style={open ? {
          display: 'inline-block',
          maxHeight: 400,
          width: 80,
          maxWidth: 80,
          overflow: 'scroll',
        } : { display: 'none' }}
      >
        <MenuList id="split-button-menu">
          <MenuItem
            onClick={(): void => handleClick(1)}
            key="border-1"
            id="border-1"
            className={classes.item}
          >
            1
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick(3)}
            key="border-3"
            id="border-3"
              // id={size}
            className={classes.item}
          >
            3
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick(6)}
            key="border-6"
            id="border-6"
            className={classes.item}
          >
            6
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick(9)}
            key="border-9"
            id="border-9"
            className={classes.item}
          >
            9
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick(12)}
            key="border-12"
            id="border-12"
            className={classes.item}
          >
            12
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick(24)}
            key="border-24"
            id="border-24"
            className={classes.item}
          >
            24
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick(36)}
            key="border-36"
            id="border-36"
            className={classes.item}
          >
            36
          </MenuItem>
        </MenuList>
      </Paper>
      <span role="presentation" ref={anchorRef} onClick={handleToggle}>
        <Border />
      </span>
    </div>
  );
};
