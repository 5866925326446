import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { stateNames } from '../../../index';
import { alertAnswer } from '../../../eventHandlers/changeHistory';
import { translateWords } from '../../../translate';

export default (props): JSX.Element => {
  const { state, dispatch } = props;
  const { openAlert, menuFontFamily, lang } = state as InitialState;
  const {
    title, contentText, first, second, open, invoke,
  } = openAlert;
  // const [open, setOpen] = React.useState(open);

  const handleClose = (): void => {
    dispatch({ type: stateNames.openAlert, openAlert: { ...openAlert, open: false } });
  };
  const handleFirst = () => {
    dispatch({
      type: stateNames.openAlert, openAlert: { ...openAlert, open: false, choice: first },
    });
    if (invoke) {
      invoke(first);
    }

    // return openAlert;
  };
  const handleSecond = () => {
    dispatch({
      type: stateNames.openAlert, openAlert: { ...openAlert, open: false, choice: second },
    });
    if (invoke) {
      invoke(second);
    }
    
    // return openAlert;
  };
  // React.useEffect(() => {
  //   if (shouldOpen) {
  //     setOpen(shouldOpen);
  //   }
  // }, [openAlert]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{<span style={{ fontFamily: menuFontFamily }}>{title}</span>}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span style={{ fontFamily: menuFontFamily }}>
              {' '}
              {contentText}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disableRipple onClick={() => handleFirst()} color="secondary">
            <span style={{ fontFamily: menuFontFamily }}>
              {' '}
              {first}
            </span>
          </Button>
          <Button disableRipple onClick={() => handleSecond()} color="primary" autoFocus>
            <span style={{ fontFamily: menuFontFamily }}>
              {' '}
              {second}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
