import devLog from "../devLog";

export default (state): any => {
  const {
    loading, isSignedIn, gapi, lang, currentID,
  } = state as InitialState;
  const accessToken = localStorage.getItem('GAPI_ACCESS_TOKEN');
  const API_KEY = localStorage.getItem('GAPI_API_KEY');
  if (loading) return { loading };
  if (!accessToken) return { error: { message: 'not authorized', location: 'service/gDrive' } };
  if (!isSignedIn) return { error: { message: 'not signed in', location: 'service/gDrive' } };
  if (!gapi) return { error: { message: 'gapi not loaded', location: 'service/gDrive' } };
  const token = accessToken;
  const share = new gapi.drive.share.ShareClient();
  share.setOAuthToken(token);
  share.setItemIds([currentID]);
  try {
    share.showSettingsDialog();
  } catch (err) {
    devLog(err);
    return { error: { message: `${err.message} in share`, location: 'service/gDrive' } };
  }
};
