
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import {
  List, Divider, MenuItem,
} from '@material-ui/core';
import _ from 'lodash';
// import { stateNames } from '../../../App';
import { translateWords } from '../../../../translate';
import { Capitalized, Titlized } from '../../helpers/changeToUpper';
import { dotParagraphClass, paragraphClass } from '../../../../nameGenerator';
import { textParent } from '../../../../eventHandlers/changeHistory';
import reEnter from '../../helpers/reFocus';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  dropdown: {
    position: 'absolute',
    display: 'inline-block',
    zIndex: 3,
    top: 35,
  },
  item: {
    minWidth: 150,
    maxHeight: 50,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default (props) => {
  const classes = useStyles();
  const { state } = props;
  const {
    lang, menuFontFamily,
  } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  // const [currentFont, setCurrentFont] = React.useState(fontFamily);
  const anchorRef = React.useRef(null);
  const clean = (p) => {
    const para = p as HTMLElement;
    // it is now being coverted to something else
    if (para.classList.contains(paragraphClass)) {
      para.classList.remove(paragraphClass);
    }
    if (para.classList.contains('derassi-title')) {
      para.classList.remove('derassi-title');
    }
    if (para.classList.contains('derassi-subtitle')) {
      para.classList.remove('derassi-subtitle');
    }
    if (para.classList.contains('derassi-h1')) {
      para.classList.remove('derassi-h1');
    }
    if (para.classList.contains('derassi-h2')) {
      para.classList.remove('derassi-h2');
    }
    if (para.classList.contains('derassi-h3')) {
      para.classList.remove('derassi-h3');
    }
  };

  const handleClick = (type) => {
    setOpen(false);
    const { parent } = textParent;
    if (parent) {
      const para = parent.closest(dotParagraphClass) as HTMLDivElement;
      if (para) {
        clean(para);
        switch (type) {
          case 'title':
            para.classList.add('derassi-title');
            break;
          case 'subtitle':
            para.classList.add('derassi-subtitle');
            // para.style.fontSize = '24px';
            // para.style.opacity = '0.7';
            // para.style.marginBottom = '20px';
            // para.style.marginTop = '20px';
            break;
          case 'h1':
            para.classList.add('derassi-h1');
            break;
          case 'h2':
            para.classList.add('derassi-h2');
            break;
          case 'h3':
            para.classList.add('derassi-h3');
            break;
          default:
            break;
        }
        reEnter();
      }
    }
  };
  const handleMouseLeave = () => {
    if (open) {
      setOpen(false);
    }
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper
        className={classes.dropdown}
        style={open ? {
          display: 'inline-block',
          maxHeight: 340,
          width: 200,
          overflow: 'scroll',
        } : { display: 'none' }}
      >
        <List id="split-button-menu">
          <MenuItem
            dense
            id="title"
            key="title"
            onClick={() => handleClick('title')}
            className={classes.item}
          >
            <span style={{ fontSize: 27 }}>
              { lang <= 2
                ? (<Titlized state={state} sentence={translateWords.mainTitle[lang]} />)
                : _.capitalize(translateWords.mainTitle[lang])}
            </span>

          </MenuItem>
          <Divider style={{ maxHeight: 1 }} />
          <MenuItem
            dense
            id="subtitle"
            key="subtitle"
            onClick={() => handleClick('subtitle')}
            className={classes.item}
          >
            <span style={{ fontSize: 20, opacity: 0.7 }}>
              { lang <= 2
                ? (<Titlized state={state} sentence={translateWords.subTitle[lang]} />)
                : _.capitalize(translateWords.subTitle[lang])}
            </span>

          </MenuItem>
          <Divider style={{ maxHeight: 1 }} />
          <MenuItem
            dense
            id="h1"
            key="h1"
            onClick={() => handleClick('h1')}
            className={classes.item}
          >
            <h1>
              { lang <= 2
                ? (<Capitalized state={state} word={translateWords.title[lang]} />)
                : _.capitalize(translateWords.title[lang])}
              {' 1'}
            </h1>
          </MenuItem>
          <MenuItem
            dense
            id="h2"
            key="h2"
            onClick={() => handleClick('h2')}
            className={classes.item}
          >
            <h2>
              { lang <= 2
                ? (<Capitalized state={state} word={translateWords.title[lang]} />)
                : _.capitalize(translateWords.title[lang])}
              {' 2'}
            </h2>
          </MenuItem>
          <MenuItem
            dense
            id="h3"
            key="h3"
            onClick={() => handleClick('h3')}
            className={classes.item}
          >
            <h3>
              { lang <= 2
                ? (<Capitalized state={state} word={translateWords.title[lang]} />)
                : _.capitalize(translateWords.title[lang])}
              {' 3'}
            </h3>
          </MenuItem>
        </List>

      </Paper>
      <IconButton
        ref={anchorRef}
        style={{ fontFamily: menuFontFamily }}
        onClick={handleToggle}
      >
        { lang <= 2
          ? (<Capitalized state={state} word={translateWords.heading[lang]} />)
          : _.capitalize(translateWords.heading[lang])}
        <ArrowDropDownIcon />
      </IconButton>
    </div>
  );
};
