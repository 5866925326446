import _ from 'lodash';
import { dotParagraphClass, paragraphForwarding, paragraphBackwarding, dotParagraphBackwarding, dotParagraphForwarding } from '../../nameGenerator';

export default (b, forward) => {
  const body = b as HTMLElement;
  const allParas = body.querySelectorAll(dotParagraphClass);
  // if (forward) {
  //   const backwards = document.querySelectorAll(dotParagraphBackwarding);
  //   Array.from(backwards).forEach(back => back.classList.remove(paragraphBackwarding));
  // } else {
  //   const forwards = document.querySelectorAll(dotParagraphForwarding);
  //   Array.from(forwards).forEach(f => f.classList.remove(paragraphForwarding));
  // }
  if (allParas.length <= 1) return;
  if (forward) {
    _.dropRight(allParas).forEach((para) => {
      if (para.classList.contains(paragraphForwarding)) {
        para.classList.remove(paragraphForwarding);
      }
    });
  } else {
    _.drop(allParas).forEach((para) => {
      if (para.classList.contains(paragraphBackwarding)) {
        para.classList.remove(paragraphBackwarding);
      }
    });
  }
};
