import changeState, { saveHistory } from '../../../eventHandlers/changeHistory';
import { moveCaretInside } from '../../../eventHandlers/helpers/moveFocus';
import save from '../../../services/save';


// const saveBoth = (state, dispatch) => {
//   save({ state, dispatch });
//   saveHistory();
// };
export default (listType, klass: string, nextPage? : HTMLElement,
  tag?: HTMLLIElement, start?: number, prevPage?: HTMLElement): void => {
  const list = document.createElement(listType) as HTMLDListElement;
  list.classList.add(klass);
  const firstLiTag = document.createElement('li') as HTMLElement;
  if (tag) {
    list.appendChild(tag);
  } else {
    list.appendChild(firstLiTag);
  }
  const container = document.createElement('div');
  // container.style.backgroundColor = '#ccc';
  container.style.breakInside = 'auto';
  container.style.pageBreakInside = 'auto';
  container.classList.add('derassi-list', 'derassi-container');
  container.append(list);
  if (nextPage && start) {
    if (list && listType === 'ol') {
      list.setAttribute('start', `${start}`);
    }
    (nextPage as any).insertAdjacentElement('afterbegin', container);
    return;
  }
  if (prevPage) {
    if (listType === 'ol') {
      list.setAttribute('start', `${start}`);
    }
    (prevPage as any).insertAdjacentElement('beforeend', container);
    return;
  }
  const sel = window.getSelection();
  if (!sel) return;
  const { focusNode } = sel;
  if (!focusNode) return;
  const currentContainer = (focusNode as HTMLElement).closest
    ? (focusNode as HTMLElement).closest('.derassi-container')
    : (focusNode as any).parentElement.closest('.derassi-container');
  if (!currentContainer) return;
  currentContainer.insertAdjacentElement('afterend', container);
  moveCaretInside(firstLiTag);
};
