import _ from 'lodash';
import uniqid from 'uniqid';
import { contentEditableClass, dotContentEditableClass } from '../nameGenerator';

export const undoState: { unDoing: boolean; reDoing: boolean; backspacing: boolean } = {
  unDoing: false,
  reDoing: false,
  backspacing: false,
};

const initialState: {past: any[]; present: any; future: any[]} = {
  past: [],
  present: null,
  future: [],
};

let history = initialState;

const historyReducer = (action) => {
  switch (action.type) {
    case 'UNDO': {
      undoState.unDoing = true;
      const previous = history.past.pop();
      if (previous) {
        history.future.unshift(history.present);
        history.present = previous;
      }
      return history;
    }
    case 'REDO':
      undoState.reDoing = true;
      { const next = history.future[0];
        if (next) {
          const newFuture = _.drop(history.future);
          history.past.push(history.present);
          history.future = newFuture;
          history.present = next;
        }
        return history;
      }
    case 'ADD':
    {
      history.past.push(action.add);
      return history;
    }
    case 'RESET':
    {
      history = initialState;
      return history;
    }
    case 'GET':
    {
      return history;
    }
    default:
      return history;
  }
};

export default historyReducer;

export const saveHistory = async (): Promise<void> => {
  // if (ref && ref.current) {
  const sel = window.getSelection();
  if (sel) {
    const { focusOffset, focusNode } = sel;
    if (focusNode) {
      const parent = (focusNode.nodeType === Node.TEXT_NODE
        ? focusNode.parentElement
        : focusNode) as HTMLElement;
      let focusId = parent.id;
      if (!focusId) {
        focusId = `derassi-focus-${uniqid()}`;
      }
      const container = parent.classList.contains(contentEditableClass)
        ? parent : parent.closest(dotContentEditableClass);
      // scrolls back to veiw
      if (container) {
        const containerId = container.id;
        const html = container.innerHTML;
        historyReducer({
          type: 'ADD',
          add: {
            containerId,
            html,
            focusId,
            focusOffset,
          },
        });
      }
    }
  }
  // }
};

export const currentTransfer: { element: HTMLElement | Element | Node | null} = {
  element: null,
};
export const currentMoveable: { element: HTMLElement | null} = {
  element: null,
};
export const alertAnswer: { answer: string } = {
  answer: '',
};

export const derassiHeader = {
  showPageNumber: false,
  pageNumberSide: 'right',
  pageNumberLabel: '',
  firstPageContent: '',
  otherPagesContent: '',
  set: (props: DerassiHeader): void => {
    const {
      showPageNumber,
      pageNumberSide,
      pageNumberLabel,
      firstPageContent,
      otherPagesContent,
    } = props;
    derassiHeader.showPageNumber = showPageNumber;
    derassiHeader.pageNumberSide = pageNumberSide;
    derassiHeader.pageNumberLabel = pageNumberLabel;
    derassiHeader.firstPageContent = firstPageContent;
    derassiHeader.otherPagesContent = otherPagesContent;
  },
  reset: (): void => {
    derassiHeader.showPageNumber = false;
    derassiHeader.pageNumberSide = 'right';
    derassiHeader.pageNumberLabel = '';
    derassiHeader.firstPageContent = '';
    derassiHeader.otherPagesContent = '';
  },
};
export const derassiFooter = {
  showPageNumber: false,
  pageNumberSide: 'right',
  pageNumberLabel: '',
  firstPageContent: '',
  otherPagesContent: '',
  set: (props: DerassiHeader): void => {
    const {
      showPageNumber,
      pageNumberSide,
      pageNumberLabel,
      firstPageContent,
      otherPagesContent,
    } = props;
    derassiFooter.showPageNumber = showPageNumber;
    derassiFooter.pageNumberSide = pageNumberSide;
    derassiFooter.pageNumberLabel = pageNumberLabel;
    derassiFooter.firstPageContent = firstPageContent;
    derassiFooter.otherPagesContent = otherPagesContent;
  },
  reset: (): void => {
    derassiFooter.showPageNumber = false;
    derassiFooter.pageNumberSide = 'right';
    derassiFooter.pageNumberLabel = '';
    derassiFooter.firstPageContent = '';
    derassiFooter.otherPagesContent = '';
  },
};

// export const currentHeaderFooter: { element: HTMLElement | null } = {
//   element: null,
// };

export const currentText: { element: HTMLElement | null; shadowStyle: string } = {
  element: null,
  shadowStyle: '',
};

export const currentFile: { id: string; title: string} = {
  id: '',
  title: '',
};

// states and dipatches don't work well with these, since they have to do with the editor,
// and editor does not depend on state updates, but menues do
// export const currentSize: {size: number } = { size: 12 };
export const currentFont: { family: string; size: number } = { family: 'Mulat Abay', size: 12 };
export const bodyToggle: { toggle: boolean } = { toggle: true };

export const textParent: {
  parent: HTMLElement | Element | null; focusOffset: number; index: number;
} = { parent: null, focusOffset: 0, index: 0 };
