import * as ng from '../../nameGenerator';
import { replaceCaret } from './moveFocus';

const isTextNode = (node: Node): boolean => node.nodeType === Node.TEXT_NODE;

const isParaNode = (node: Node): boolean => node.nodeType !== Node.TEXT_NODE
&& (node as HTMLElement).classList.contains(ng.paragraphClass);

const isBodyNode = (node: Node): boolean => node.nodeType !== Node.TEXT_NODE
&& (node as HTMLElement).classList.contains(ng.bodyClass);


export default (range: Range): void => {
  const {
    startContainer, endContainer, startOffset, endOffset, commonAncestorContainer,
  } = range;
  const isText = isTextNode(commonAncestorContainer);
  const isPara = isParaNode(commonAncestorContainer);
  const isBody = isBodyNode(commonAncestorContainer);

  // if (isText || isPara) {
  const clone = range.cloneContents();
  if (clone.childNodes.length <= 1) {
    const span = document.createElement('span');
    span.classList.add('derassi-selected');
    span.appendChild(range.extractContents());
    range.insertNode(span);
    replaceCaret(span);
  } else {
    const array = Array.from(clone.childNodes);
    const firstClone = array[0] as HTMLElement;
    const lastClone = array[array.length - 1] as HTMLElement;
    const first = document.getElementById(firstClone.id);
    const last = document.getElementById(lastClone.id);
    if (!first || !last) return;
    const extract = range.extractContents();
    const br = first.querySelector('br');
    if (br) {
      const span = document.createElement('span');
      span.classList.add('derassi-selected');
      span.innerHTML = firstClone.innerHTML;
      br.insertAdjacentElement('beforebegin', span);
    } else {
      const span = document.createElement('span');
      span.classList.add('derassi-selected');
      span.innerHTML = firstClone.innerHTML;
      first.insertAdjacentElement('beforeend', span);
    }
    const span = document.createElement('span');
    span.classList.add('derassi-selected');
    span.innerHTML = lastClone.innerHTML;
    last.insertAdjacentElement('afterbegin', span);
    let current = first;
    for (let index = 1; index < array.length - 1; index += 1) {
      const element = array[index] as HTMLElement;
      const inner = document.createElement('span');
      inner.classList.add('derassi-selected');
      inner.innerHTML = element.innerHTML;
      element.innerHTML = inner.outerHTML;
      current.insertAdjacentElement('afterend', element);
      current = element as HTMLElement;
    }
    replaceCaret(last);
  }
};
