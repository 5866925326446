import Moveable from 'moveable';
import _ from 'lodash';
import * as ng from '../../../nameGenerator';
import { stateNames } from '../../../index';
import mulatlogo from '../../../images/mulatlogo.png';
import { onAddingChange } from '../../../eventHandlers/onChange';
import { currentMoveable, currentText } from '../../../eventHandlers/changeHistory';
import devLog from '../../../devLog';
import { replaceCaret } from '../../../eventHandlers/helpers/moveFocus';

let visited: Element[] = [];
let hadFlewOver = false;

export const addControl = (props): void => {
  const {
    container, paragraph, signature, image, setChartHeight, chart, table, text, state, dispatch,
  } = props;
  const existing = document.querySelector('.moveable-control-box');
  if (existing) existing.remove();

  const moveable = new Moveable(container, {});
  moveable.updateRect();
  moveable.target = container;
  moveable.throttleDrag = 0;
  moveable.keepRatio = !image;
  moveable.edge = !image;
  moveable.keepRatio = image;
  moveable.throttleResize = 0;
  moveable.resizable = true;
  // moveable.rotatable = image;
  moveable.scalable = image;
  moveable.throttleScale = 0;
  moveable.draggable = true;
  moveable.throttleDrag = 0;
  window.addEventListener('mousedown', (e) => {
    const { target } = e;
    try {
      if (moveable && !moveable.isMoveableElement(target as HTMLElement)) {
        // moveable.target = e.target;
        moveable.destroy();
        // return;
      }
    } catch (err) {
      // if (image && moveable) {
      //   const caption = container.querySelector('.derassi-image-caption');
      //   if (caption) {
      //     replaceCaret(caption);
      //   }
      // }
      // devLog(err);
    }
  });
  // moveable.updateRect();
  currentMoveable.element = container;
  if (image) {
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editImage' });
  }
  if (table) {
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editTable' });
  }
  if (chart) {
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editChart' });
  }
  if (signature) {
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editSignature' });
  }
  if (text) {
    currentText.element = container;
    currentText.shadowStyle = currentText.element ? currentText.element.style.textShadow : '';
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editText' });
  }
  /* resizable */
  /* resizable */
  moveable.on('resizeStart', ({ target, clientX, clientY }) => {
  });
  moveable.on('resize', (event) => {
    const {
      width, height,
    } = event;
    container.style.width = `${width}px`;
    container.style.height = `${height + 25}px`;
    if (image) {
      const img = container.querySelector('img') as HTMLImageElement;
      if (img) {
        img.height = height;
        img.width = width;
      }
      const imageContainer = container.querySelector('.derassi-image-container');
      if (imageContainer) {
        imageContainer.style.height = `${height}px`;
        imageContainer.style.width = `${width}px`;
      }
    }
    if (signature) {
      const canvas = container.querySelector('canvas') as HTMLCanvasElement;
      canvas.width = width;
      canvas.height = height;
    }
    if (setChartHeight) {
      setChartHeight(height);
    }
  }).on('resizeEnd', () => {
    const editor = (container as HTMLElement).closest(ng.dotContentEditableClass);
    if (image) {
      const imageCaption = container.querySelector('.derassi-image-caption');
      if (imageCaption) {
        const copy = imageCaption.cloneNode(true);
        imageCaption.remove();
        const imageContainer = container.querySelector('.derassi-image-container') as HTMLElement;
        if (imageContainer) {
          imageContainer.insertAdjacentElement('afterend', copy);
        }
      }
    }
    if (!paragraph) {
      moveable.destroy();
      if (existing) existing.remove();
    }
  });
  moveable.on('rotate', (e) => {
    const {
      transform, clientX, clientY,
    } = e;
    container.style.transform = transform;
  }).on('rotateEnd', () => {
    moveable.destroy();
    if (existing) existing.remove();
  });
  /* draggable */
  moveable.on('dragStart', (e) => {
    const { target } = e;
  });
  moveable.on('drag', (e) => {
    const {
      transform, target, clientX, clientY,
    } = e;
    target.style.transform = transform;
    if (visited.length > 0) {
      hadFlewOver = true;
    }
    // adjuts so highlighs occure at top edge, not center.
    const { offsetLeft, offsetTop } = container;
    const flyOvers = Array.from(document.elementsFromPoint(clientX, clientY));
    const overContainers = flyOvers.filter(f => f.classList.contains(ng.containerClass) && f !== container);
    visited.forEach((v) => { const x = v as HTMLElement; x.style.outline = 'initial'; });
    visited = overContainers;
    overContainers.forEach((ov) => { const x = ov as HTMLElement; x.style.outline = 'solid 2px #3f51b5'; });
  });
  moveable.on('dragEnd', (e) => {
    const {
      clientX, clientY, target,
    } = e;
    visited.forEach((v) => { const x = v as HTMLElement; x.style.outline = 'initial'; });
    const last = _.last(Array.from(visited)) as HTMLElement;
    if (last && container) {
      last.style.outline = 'initial';
      container.style.transform = 'initial';
      const clone = container.cloneNode(true);
      last.insertAdjacentElement('afterend', clone);
      container.remove();
    } else if (hadFlewOver && !last) {
      container.style.transform = 'initial';
    }
    moveable.destroy();
    if (existing) existing.remove();
  });
};

export default (props) => {
  const {
    container, signature, image, setChartHeight, chart, table, state, dispatch,
  } = props;
  if (container.ondblclick || !container.addEventListener) return;
  container.addEventListener('dblclick', (mouseEvent) => {
    mouseEvent.preventDefault();
    addControl(props);
  });
};
