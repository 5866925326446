
import React from 'react';
import {
  Grid, Button,
} from '@material-ui/core';
import _ from 'lodash';
import { translateWords } from '../../../../translate';
import * as ng from '../../../../nameGenerator';
import attachControl from '../../helpers/attachControl';
import { currentMoveable } from '../../../../eventHandlers/changeHistory';

export default (props): JSX.Element => {
  const { state } = props;
  const { menuFontFamily, lang } = state as InitialState;
  const [toggleStyle, setToggleStyle] = React.useState(false);

  const addRow = () => {
    const moveable = currentMoveable.element;
    if (moveable && moveable.classList.contains(ng.tableClass)) {
      const container = moveable;
      const trs = Array.from(container.querySelectorAll('tr'));
      if (trs) {
        const last = _.last(trs) as HTMLTableRowElement;
        if (last) {
          const copy = last.cloneNode(true) as HTMLTableRowElement;
          const tds = Array.from(copy.querySelectorAll('td'));
          tds.forEach((td) => {
            const t = td;
            t.innerHTML = '';
          });
          last.insertAdjacentElement('afterend', copy);
          const hotContainer = document.getElementById(container.id);
          if (hotContainer) {
            attachControl({ container, ...props });
            // onAddingChange({ target: editor, ...props });
          }
        }
      }
    }
  };
  const addColumn = () => {
    const moveable = currentMoveable.element;
    if (moveable && moveable.classList.contains(ng.tableClass)) {
      const container = moveable;
      const trs = Array.from(container.querySelectorAll('tr'));
      if (trs) {
        trs.forEach((tr) => {
          const td = document.createElement('td');
          tr.append(td);
        });
        const hotContainer = document.getElementById(container.id);
        if (hotContainer) {
          attachControl({ container, ...props });
          // onAddingChange({ target: editor, ...props });
        }
      }
    }
  };
  const makeTight = () => {
    setToggleStyle(!toggleStyle);
    const moveable = currentMoveable.element;
    if (moveable && moveable.classList.contains(ng.tableClass)) {
      const container = moveable;
      const ths = Array.from(container.querySelectorAll('th'));
      const tds = Array.from(container.querySelectorAll('td'));
      const caption = container.querySelector('caption') as HTMLTableCaptionElement;
      // caption.style.fontSize = 'smaller';
      // caption.paddingTop = '1px';
      // caption.paddingBottom = '1px';
      [caption, ...ths, ...tds].forEach((element) => {
        const el = (element as HTMLElement);
        if (el && el.style) {
          el.style.fontSize = toggleStyle ? 'smaller' : 'initial';
          el.style.paddingTop = toggleStyle ? '1px' : '8px';
          el.style.paddingBottom = toggleStyle ? '1px' : '8px';
        }
      });
      // const hotContainer = document.getElementById(container.id);
      // if (hotContainer) {
      //   attachControl({ container, ...props });
      //   // onAddingChange({ target: editor, ...props });
      // }
    }
  };

  return (
    <div>
      <Grid container direction="row">
        <Button
          variant="outlined"
          color="primary"
          style={{ fontFamily: menuFontFamily, marginLeft: 5 }}
          onClick={addRow}
        >
          { lang <= 2
            ? `${translateWords.add[lang]} ${translateWords.row[lang]}`
            : `${translateWords.add[lang]} ${translateWords.row[lang]}`
            }
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ fontFamily: menuFontFamily, marginLeft: 5 }}
          onClick={addColumn}
        >
          {
              lang <= 2
                ? `${translateWords.add[lang]} ${translateWords.column[lang]}`
                : `${translateWords.add[lang]} ${translateWords.column[lang]}`
            }
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ fontFamily: menuFontFamily, marginLeft: 5 }}
          onClick={makeTight}
        >
          { lang <= 2
            ? translateWords.tight[lang]
            : translateWords.tight[lang]
            }
        </Button>
      </Grid>
    </div>
  );
};
