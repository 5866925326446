
export default async (state, dispatch): Promise<any> => {
  const {
    loading, isSignedIn, gapi, currentID,
  } = state as InitialState;
  const accessToken = localStorage.getItem('GAPI_ACCESS_TOKEN');
  if (loading) return { loading };
  if (!accessToken) return { error: { message: 'not authorized', location: 'service/update' } };
  if (!isSignedIn) return { error: { message: 'not signed in', location: 'service/update' } };
  if (!gapi) return { error: { message: 'gapi not loaded', location: 'service/update' } };
  const url = `https://www.googleapis.com/drive/v3/files/${currentID}`;

  try {
    const result = await gapi.client.request({
      path: url,
      method: 'DELETE',
      // header: {
      //   Accept: 'application/json',
      //   'Content-Type': 'application/json',
      // },
      params: {
        fields: '*',
      },
    });
    return result;
  } catch (err) {
    return { error: { message: err.message, location: 'service/update' } };
  }
};
