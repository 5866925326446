import React from 'react';
import FindAndReplace from '../helpers/findReplace';
import EditImageMenu from './upperMenus/editImage';
import EditSignature from './upperMenus/editSignature';
import EditText from './upperMenus/editText';
import EditHeaderFooter from '../helpers/editHeaderFooter';
import EditTable from './upperMenus/editTable';
import EditChart from './upperMenus/editChart';

export default (props): JSX.Element => {
  const { state, dispatch } = props;
  const { showThirdMenu } = state as InitialState;
  switch (showThirdMenu) {
    case 'findAndReplace':
      return <FindAndReplace {...props} />;
    case 'editImage':
      return <EditImageMenu {...props} />;
    // case 'editHeaderFooter':
    //   return <EditHeaderFooter {...props} />;
    case 'editTable':
      return <EditTable {...props} />;
    case 'editChart':
      return <EditChart {...props} />;
    case 'editSignature':
      return <EditSignature {...props} />;
    case 'editText':
      return <EditText {...props} />;
    default:
      return <></>;
  }
};
