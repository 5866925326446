import assembledPages from './assembledPages';

export default async (props): Promise<any> => {
  const {
    data, state, dispatch, copy, id,
  } = props;
  const body = {
    ...data,
    appProperties: { type: 'ደራሲ' },
    description: 'ለጉግል ድራይቭ ርዕስ ሊፈለግ የሚችል',
  };
  const {
    isSignedIn, gapi,
  } = state as InitialState;
  const accessToken = localStorage.getItem('GAPI_ACCESS_TOKEN');
  // if (loading) return { loading };
  if (!accessToken) return { error: { message: 'not authorized', location: 'service/create' } };
  if (!isSignedIn) return { error: { message: 'not signed in', location: 'service/create' } };
  if (!gapi) return { error: { message: 'gapi not loaded', location: 'service/create' } };

  let url = 'https://www.googleapis.com/drive/v3/files';
  if (copy && id) {
    url = `${url}/${id}/copy`;
  }
  try {
    const response = await gapi.client.request({
      path: url,
      method: 'POST',
      params: {
        fields: '*',
        // uploadType: 'multipart',
      },
      // headers: {
      //   Accept: 'application/json',
      //   Authorization: `Bearer ${accessToken}`,
      //   'Content-Type': 'application/json',
      // },
      // mimeType: 'application/json',
      body: JSON.stringify(body),
    });
    const { result } = response;
    return result;
   
  } catch (err) {
    return { error: { message: err.message, location: 'service/create' } };
  }
};
