import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Gesture from '@material-ui/icons/Gesture';
import List from '@material-ui/core/List';
import Image from '@material-ui/icons/Image';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Upload from '@material-ui/icons/Publish';
import Search from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import PictureInPicture from '@material-ui/icons/PictureInPicture';
import PictureInPictureAlt from '@material-ui/icons/PictureInPictureAlt';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import uniqid from 'uniqid';
import { Capitalized } from '../../helpers/changeToUpper';
import ImageUrl from './imageUrl';
import { translateWords } from '../../../../translate';
import handleDrivePicker from '../../helpers/beforeImageAdd';
import AddTable from '../../helpers/beforeTableAdd';
import AddText from '../../helpers/beforeTextAdd';
import AddSymbol from '../../helpers/beforeSymbolAdd';
import useStyles from './commonStyles';
import AddChart from '../../helpers/beforeChartAdd';
import drive from '../../../../images/drive.png';
import photos from '../../../../images/photos.png';
import { textParent, derassiHeader, derassiFooter } from '../../../../eventHandlers/changeHistory';
import {
  dotContainerClass, dotPageClass, getHeaderIdForPage, getFooterIdForPage, dotHeaderClass, dotFooterClass,
} from '../../../../nameGenerator';
import reEnter from '../../helpers/reFocus';
import addImage from '../../helpers/addImage';
import addSignature from '../../helpers/addSignature';
import { moveCaretInside, replaceCaret } from '../../../../eventHandlers/helpers/moveFocus';
import { stateNames } from '../../../..';
import devLog from '../../../../devLog';


export default (props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch } = props;
  const {
    menuInited, isSignedIn, menuFontFamily, lang,
  } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const [subOpen, setSubOpen] = React.useState({ left: 400, show: '', top: 40 });
  const anchorRef = React.useRef(null);
  const subOpenRef = React.useRef(null);

  React.useEffect(() => {
    if (subOpenRef) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { current } = subOpenRef as any;
      if (current) {
        const w = current.style.offsetLeft;
        setSubOpen({ ...subOpen, left: w });
      }
    }
  }, [subOpen]);

  const handleToggle = (): void => {
    reEnter();
    if (!open) {
      dispatch({ type: 'menuInited', menuInited: true });
    }
    setOpen(!open);
    // reEnter();
  };
  const handleMouseLeave = (): void => {
    if (open) {
      setOpen(false);
      setSubOpen({ ...subOpen, show: '', top: 20 });
    }
  };
  const callDrivePicker = (e): void => {
    handleDrivePicker(e, state, dispatch);
    setSubOpen({ ...subOpen, show: '', top: 20 });
  };
  const handleMouseEnter = (): void => {
    if (!open) {
      if (menuInited) {
        setOpen(true);
      }
    }
  };

  // const handleImageSebMenu = () => {
  //   setImageSubOpen(!imageSubOpen);
  // };
  // React.useEffect(() => {
  //   removeOtherSubmenus(null);
  //   return () => {
  //     removeOtherSubmenus(null);
  //   };
  // }, []);
  const handleSub = (): void => {
    setOpen(false);
    const { parent, index, focusOffset } = textParent;
    if (parent) {
      const node = parent.childNodes[index] as Node;
      if (node && node.nodeType === Node.TEXT_NODE) {
        const sub = document.createElement('sub');
        const split = (node as Text).splitText(focusOffset);
        parent.insertBefore(sub, split);
        moveCaretInside(sub);
      }
    }
  };
  const handleSuper = (): void => {
    setOpen(false);
    const { parent, index, focusOffset } = textParent;
    if (parent) {
      const node = parent.childNodes[index] as Node;
      if (node && node.nodeType === Node.TEXT_NODE) {
        const sup = document.createElement('sup');
        const split = (node as Text).splitText(focusOffset);
        parent.insertBefore(sup, split);
        moveCaretInside(sup);
      }
    }
  };
  const setPageNum = (klass, side, type) => {
    const pageNums = document.querySelectorAll(klass);
    const first = pageNums[0];
    const visible = (first as HTMLSpanElement).style.visibility === 'visible';
    Array.from(pageNums).forEach((pageNum) => {
      const page = pageNum as HTMLSpanElement;
      page.style.visibility = visible ? 'hidden' : 'visible';
      page.style.float = side === 'right' ? 'right' : 'left';
    });
    if (type === 'header') {
      derassiHeader.showPageNumber = !visible;
      derassiHeader.pageNumberSide = side;
    }
    if (type === 'footer') {
      derassiFooter.showPageNumber = !visible;
      derassiFooter.pageNumberSide = side;
    }
  };
  const handleInsert = (type, side): void => {
    setOpen(false);
    setSubOpen({ ...subOpen, show: '' });
    const kase1 = type === 'header' && side === 'right';
    const kase2 = type === 'footer' && side === 'right';
    const kase3 = type === 'header' && side === 'left';
    const kase4 = type === 'footer' && side === 'left';
    if (kase1) {
      //  case 'pageNumberHeader':
      setPageNum('.derassi-header-pagenum', 'right', 'header');
    }
    if (kase2) {
      setPageNum('.derassi-footer-pagenum', 'right', 'footer');
    }
    if (kase3) {
      setPageNum('.derassi-header-pagenum', 'left', 'header');
    }
    if (kase4) {
      setPageNum('.derassi-footer-pagenum', 'left', 'footer');
    }


    reEnter();
    // if (side === 'pageNumberHeader') {
    //   const pageNums = document.querySelectorAll('.derassi-header-pagenum');
    //   const first = pageNums[0];
    //   const visible = (first as HTMLSpanElement).style.visibility === 'visible';
    //   Array.from(pageNums).forEach((headNum) => {
    //     const head = headNum as HTMLSpanElement;
    //     head.style.visibility = visible ? 'hidden' : 'visible';
    //   });
    //   derassiHeader.showPageNumber = !visible;
    //   reEnter();
    //   return;
    // }
    // if (side === 'pageNumberFooter') {
    //   dispatch({
    //     type: stateNames.derassiFooter,
    //     derassiFooter: { ...derassiFooter, showPageNumber: !derassiFooter.showPageNumber },
    //   });
    //   return;
    // }
    // const { parent } = textParent;
    // if (parent) {
    //   const page = parent.closest(dotPageClass);
    //   if (page) {
    //     const sideId = side === 'pageHeader' ? getHeaderIdForPage(page.id) : getFooterIdForPage(page.id);
    //     const hf = document.getElementById(sideId);
    //     // hf = headerFooter
    //     if (hf) {
    //       moveCaretInside(hf);
    //       textParent.parent = hf;
    //       textParent.focusOffset = 0;
    //       textParent.index = 0;
    //       hf.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    //       // reEnter();
    //     }
    //   }
    // }
  };

  // const goTo = (klass, isFirst) => {
  //   const all = document.querySelectorAll(klass);
  //   if (isFirst) {
  //     const first = all[0] as HTMLElement;
  //     if (first) {
  //       replaceCaret(first);
  //       first.style.outline = '4px solid #44ddff';
  //       first.scrollIntoView({ inline: 'center', block: 'center' });
  //     }
  //   } else {
  //     const second = all[1] as HTMLElement;
  //     if (second) {
  //       replaceCaret(second);
  //       second.style.outline = 'solid 4px green';
  //       second.scrollIntoView({ inline: 'center', block: 'center' });
  //     }
  //   }
  //   setSubOpen({ ...subOpen, show: '', top: 20 });
  //   setOpen(false);
  // };

  const insertHeaderFooter = (type, isFirst) => {
    const klass = type === 'header' ? dotHeaderClass : dotFooterClass;
    const all = document.querySelectorAll(klass);
    if (isFirst) {
      const first = all[0] as HTMLElement;
      if (first) {
        replaceCaret(first);
        first.style.outline = 'solid 4px #44ddff';
        first.scrollIntoView({ inline: 'center', block: 'center' });
      }
    } else {
      const second = all[1] as HTMLElement;
      if (second) {
        replaceCaret(second);
        second.style.outline = 'solid 4px #44ddff';
        second.scrollIntoView({ inline: 'center', block: 'center' });
      }
    }
    setSubOpen({ ...subOpen, show: '', top: 20 });
    setOpen(false);
    // const kase1 = type === 'header' && isFirst;
    // const kase2 = type === 'footer' && isFirst;
    // const kase3 = type === 'header' && isFirst;
    // const kase4 = type === 'footer' && isFirst;

    // if (kase1) {
    //   goTo(type, isFirst);
    // }
    // if (kase2) {
    //   goTo(type, isFirst);
    // }
    // if (kase3) {
    //   goTo(dotHeaderClass, isFirst);
    // }
    // if (kase4) {
    //   goTo(dotFooterClass, isFirst);
    // }
  };
  const handleUpload = (e): void => {
    const file = e.target.files[0];
    const { name } = file;
    // file.blob().then((blob) => {
    const reader = new FileReader();
    reader.onload = (): void => {
      const dataUrl = reader.result;
      const { parent } = textParent;
      if (parent) {
        const refContainer = parent.closest(dotContainerClass) as HTMLElement;
        addImage({
          data: {
            src: dataUrl, id: uniqid(), name,
          },
          // newInsertion: true,
          state,
          dispatch,
          refContainer,
        });
      }
    };
    reader.onerror = (): void => devLog(reader.error);
    reader.readAsDataURL(file);
    // });
  };
  const handleSign = (): void => {
    const { parent } = textParent;
    if (parent) {
      const refContainer = parent.closest(dotContainerClass) as HTMLElement;
      addSignature({
        state,
        dispatch,
        refContainer,
      });
    }
    setOpen(false);
  };
  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper elevation={10} className={classes.dropdown} style={open ? { display: 'inline-block' } : { display: 'none' }}>
        <List className={classes.list} onClick={(): void => dispatch({ type: 'menuInited', menuInited: false })}>
          <Divider />
          <ListItem
            dense
            button
            onMouseEnter={(): void => setSubOpen({ ...subOpen, show: 'image', top: 40 })}
            onClick={(): void => setSubOpen({ ...subOpen, show: 'image', top: 40 })}
          >
            <ListItemIcon><Image /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.image[lang] }
              </div>
            </ListItemText>
            <ChevronRight />
          </ListItem>
          <AddChart closeSubMenu={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })} key="addChart" name={translateWords.chart} id="addChart" setMenuOpen={setOpen} {...props} />
          <AddTable closeSubMenu={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })} key="addTable" name={translateWords.table} id="addTable" setMenuOpen={setOpen} {...props} />
          <AddText closeSubMenu={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })} key="addText" name={translateWords.text} id="addText" setMenuOpen={setOpen} {...props} />
          <Divider />
          <ListItem
            id="page"
            key="page"
            dense
            button
            onClick={(): void => setSubOpen({ ...subOpen, show: 'page', top: 175 })}
            onMouseEnter={(): void => setSubOpen({ ...subOpen, show: 'page', top: 175 })}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.page[lang]}
              </div>
            </ListItemText>
            <ChevronRight />
          </ListItem>
          <Divider />
          <AddSymbol closeSubMenu={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })} key="addSymbol" name={translateWords.symbol} id="addSymbol" setMenuOpen={setOpen} {...props} />
          {/* <ListItem
            id="symbols"
            key="symbols"
            dense
            button
            onClick={handleSymbols}
            onMouseEnter={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })}
          >
            <ListItemIcon><Symbols /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.symbol[lang]}
              </div>
            </ListItemText>
          </ListItem> */}
          <ListItem
            id="super"
            key="super"
            dense
            button
            onClick={handleSuper}
            onMouseEnter={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                <span style={{ paddingRight: 10 }}>
                  <span>X</span>
                  <sup>y</sup>
                </span>
                {translateWords.add[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="sub"
            key="sub"
            dense
            button
            onClick={handleSub}
            onMouseEnter={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                <span style={{ paddingRight: 10 }}>
                  <span>X</span>
                  <sub>y</sub>
                </span>
                {translateWords.add[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            id="sign"
            key="sign"
            dense
            button
            onClick={(): void => handleSign()}
            onMouseEnter={(): void => setSubOpen({ ...subOpen, show: '', top: 20 })}
          >
            <ListItemIcon><Gesture /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.sign[lang]}
              </div>
            </ListItemText>
          </ListItem>
        </List>
      </Paper>
      <Paper
        // className={classes.nested}
        ref={subOpenRef}
        onMouseLeave={(): void => setSubOpen({ ...subOpen, show: '', top: 40 })}
        style={{
          transform: `translate(${200}px)`,
          position: 'absolute',
          left: subOpen.left,
          top: subOpen.top,
          zIndex: 100,
          display: `${subOpen.show ? 'inline-block' : 'none'}`,
        }}
      >
        <List
          component="div"
          disablePadding
          style={{ maxWidth: 200, display: `${subOpen.show === 'image' ? 'inline-block' : 'none'}` }}
        >
          <ListItem
            id="upload"
            key="upload"
            dense
            button
            // disabled={!isSignedIn}
          >
            <label
              htmlFor="imageupload"
              style={{
                fontFamily: menuFontFamily, opacity: 0.8, marginBottom: -15,
              }}
            >
              <Upload style={{ opacity: 0.8, paddingRight: 20, transform: 'translateY(7px)' }} />
              { translateWords.fromComputer[lang] }
              <input onChange={(e): void => handleUpload(e)} id="imageupload" name="imageupload" style={{ opacity: 0, height: 1 }} type="file" accept="image/*" />
            </label>
          </ListItem>
          <ListItem
            id="search"
            key="search"
            dense
            button
            disabled={!isSignedIn}
            onClick={(e): void => callDrivePicker(e)}
          >
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.search[lang] }
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="drive"
            key="drive"
            dense
            button
            disabled={!isSignedIn}
            onClick={(e): void => callDrivePicker(e)}
          >
            <ListItemIcon>
              <img style={{ opacity: '0.8', filter: 'grayscale(100%)' }} src={drive} alt="drive" width="21px" height="21px" />
            </ListItemIcon>
            <ListItemText>Drive</ListItemText>
          </ListItem>
          <ListItem
            id="photos"
            key="photos"
            dense
            button
            disabled={!isSignedIn}
            onClick={(e): void => callDrivePicker(e)}
          >
            <ListItemIcon>
              <img style={{ opacity: '0.8', filter: 'grayscale(100%)' }} src={photos} alt="photos" width="21px" height="21px" />
            </ListItemIcon>
            <ListItemText>photos</ListItemText>
          </ListItem>
          <ImageUrl {...props} setMenuOpen={setOpen} />
        </List>
        {/*
          Sub menus...
          ...........
          ...........
          ...........
        */}
        <List
          component="div"
          disablePadding
          style={{ width: 'auto', display: `${subOpen.show === 'page' ? 'inline-block' : 'none'}` }}
        >
          <ListItem
            id="pageHeaderFirst"
            key="pageHeaderFirst"
            dense
            button
            onClick={() => insertHeaderFooter('header', true)}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addHeader[lang]}
                {' ('}
                {translateWords.firstPageOnly[lang]}
                {')'}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="pageFooterFirst"
            key="pageFooterFirst"
            dense
            button
            onClick={() => insertHeaderFooter('footer', true)}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addFooter[lang]}
                {' ('}
                {translateWords.firstPageOnly[lang]}
                {')'}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="pageHeader"
            key="pageHeader"
            dense
            button
            onClick={() => insertHeaderFooter('header', false)}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addHeader[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="pageFooter"
            key="pageFooter"
            dense
            button
            onClick={() => insertHeaderFooter('footer', false)}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addFooter[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            id="pageNumberHeader"
            key="pageNumberHeader"
            dense
            button
            onClick={() => handleInsert('header', 'right')}
          >
            <ListItemIcon>
              <PictureInPicture />
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addPageNumber[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="pageNumberHeaderLeft"
            key="pageNumberHeaderLeft"
            dense
            button
            onClick={() => handleInsert('header', 'Left')}
          >
            <ListItemIcon>
              <PictureInPicture style={{ transform: 'scaleX(-1)' }} />
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addPageNumber[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="pageNumberFooter"
            key="pageNumberFooter"
            dense
            button
            onClick={() => handleInsert('footer', 'right')}
          >
            <ListItemIcon>
              <PictureInPictureAlt />
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addPageNumber[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="pageNumberFooterLeft"
            key="pageNumberFooterLeft"
            dense
            button
            onClick={() => handleInsert('footer', 'left')}
          >
            <ListItemIcon>
              <PictureInPictureAlt style={{ transform: 'scaleX(-1)' }} />
            </ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.addPageNumber[lang]}
              </div>
            </ListItemText>
          </ListItem>
        </List>
      </Paper>
      <IconButton
        onMouseEnter={handleMouseEnter}
        ref={anchorRef}
        onClick={handleToggle}
        className={classes.icon}
      >
        {lang <= 2 ? (
          <Capitalized
            word={translateWords.insert[lang]}
            {...props}
          />
        ) : translateWords.insert[lang]}
      </IconButton>
    </div>
  );
};
