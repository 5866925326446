/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import SecondMenu from './headings/lowerMenus/index';
import Doc from './body/derassiDoc';
import FirstMenu from './headings/upperMenus/index';
import '../../cropper.min.css';
// import '../../App.css';
import 'cropperjs/dist/cropper.css';
import Header from '../header';
import ThirdMenu from './headings/thirdMenu';
import Alert from './body/alert';

const useStyles = makeStyles(theme => ({
  root: {
  },
  menu: {
    // width: '100%',
    // backgroundColor: theme.palette.background.paper,
    top: 50,
    position: 'fixed',
  },
  pages: {
    top: 160,
    position: 'relative',
    zIndex: 10,
    // height: 'auto',
    // backgroundColor: 'black',
    // '& > *': { zIndex: 0 },
  },
  first: {
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    top: 50,
    zIndex: 30,
  },
  second: {
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    top: 90,
    zIndex: 20,

    // position: 'relative',
    // zIndex: 2,
    // position: 'fixed',
    // zIndex: 100,
  },
  third: {
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    with: '100%',
    minHeight: 40,
    maxHeight: 200,
    height: 'auto',
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 10,
    paddingTop: 5,
    // position: 'relative',
    // zIndex: 2,
    // position: 'fixed',
    // zIndex: 100,
  },
}));

export default (props): JSX.Element => {
 
  const { state, dispatch, children } = props;
  const {
    openAlert,
    localFile,
    showThirdMenu,
    bodyZoom,
    derassiFooter,
    derassiHeader,
    bodyToggle,
    lang,
    menuInited,
    defaultFontFamily,
    menuDefaultFontFamily,
    // fontSize,
    // fontFamily,
    fontChange,
    menuFontFamily,
    fontFamilyCaped,
    menuFontFamilyCaped,
    currentTitle,
    currentID,
    pages,
    logout,
    login,
    loading,
    pageLoading,
    saving,
    gapi,
    isSignedIn,
    name,
    email,
    imageURL,
  } = state as InitialState;
  React.useEffect(() => {
    // console.log("....started menu..")
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="no-print">
        <Header isBodyEditor {...props} showTitle />
        <AppBar elevation={0} className={classes.first}>
          {/* { React.useMemo(() => <UpperMenu {...props} />, [menuInited, menuToggle]) } */}
          <FirstMenu {...props} />
        </AppBar>
        <AppBar className={classes.second}>
          {/* { React.useMemo(() => <LowerMenu {...props} />, [menuInited, menuToggle]) } */}
          <SecondMenu {...props} />
          { pageLoading && !loading ? <LinearProgress /> : <></> }
          {(
            <div className={classes.third} style={showThirdMenu === '' ? { display: 'none' } : { display: 'block' }}>
              <ThirdMenu {...props} />
            </div>
          )}
        </AppBar>
        <Alert state={state} dispatch={dispatch} />
      </div>
      <div className={classes.pages}>
        {/* <Doc {...props} /> */}
        { React.useMemo(() => <Doc {...props} />, [
          isSignedIn, defaultFontFamily, currentTitle, currentID, bodyZoom, pages, loading, lang, login,
          derassiFooter, derassiHeader, showThirdMenu, pageLoading,
        ]) }
      </div>
    </div>
  );
};
