import React from 'react';
import {
  CircularProgress, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import { translateWords } from '../../translate';
import generatePDF from './generatePDF';
import reEnter from '../editor/helpers/reFocus';
import FileSaver from 'file-saver';
import devLog from '../../devLog';

export default (props) => {
  const { state, setOpen } = props;
  const { menuFontFamily, currentTitle, lang } = state as InitialState;
  const [loading, setLoading] = React.useState(false);

  const hanldeClick = () => {
    setLoading(true);
    generatePDF().then((blob) => {
      //const url = URL.createObjectURL(blob);
      if (blob) {
        FileSaver.saveAs(blob, currentTitle);
      }
      // if (window.navigator.msSaveBlob) {
      //   window.navigator.msSaveBlob(blob, currentTitle);
      // }
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = currentTitle;
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      setLoading(false);
      setOpen(false);
      reEnter();
      // doc.save(`${currentTitle}.pdf`);
    }).catch(err => devLog(err))
  };

  return (
    <ListItem
      dense
      key="downloadPDF"
      id="downloadPDF"
      button
      onClick={hanldeClick}
    >
      <ListItemIcon>{loading ? <CircularProgress size={20} /> : <OpenInBrowser style={{ transform: 'scaleY(-1)' }} />}</ListItemIcon>
      <ListItemText>
        <div style={{ fontFamily: menuFontFamily }}>
          {translateWords.download[lang]}
          {'  '}
          PDF
        </div>
      </ListItemText>
    </ListItem>
  );
};
