
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import Crop from '@material-ui/icons/Crop';
import Clear from '@material-ui/icons/Clear';
import {
  Button, Grid, Slider,
} from '@material-ui/core';
import Cropper from 'cropperjs';
import { translateWords } from '../../../../translate';
import { currentMoveable } from '../../../../eventHandlers/changeHistory';
import { stateNames } from '../../../..';

const useStyles = makeStyles(theme => ({
  slider: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    paddingTop: 1,
    paddingBottom: 1,
    fontSize: 'smaller',
    minWidth: 68,
    color: theme.palette.text.secondary,
  },
  buttons: {
    '& button': {
      paddingTop: 1,
      paddingBottom: 1,
      marginLeft: 3,
      marginRight: 3,
      fontSize: 'smaller',
      width: 'auto',
    },
  },
  switch: {
    color: 'tomato',
  },
}));

export default (props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch } = props;
  const { lang } = state as InitialState;
  const { menuFontFamily } = state as InitialState;
  const [imageProps, setImageProps] = React.useState({
    blur: 0, brightness: 100, grayscale: 0, hue: 0, invert: 0, opacity: 100, src: '',
  });
  const [crop, setCrop] = React.useState(false);
  const [cropper, setCropper] = React.useState<Cropper | null>(null);
  const [original, setOriginal] = React.useState<HTMLElement | Node>();

  React.useEffect(() => {
    const moveable = currentMoveable.element;
    if (moveable) {
      setOriginal(moveable.cloneNode(true));
    }
  }, []);

  const updateProps = () => {
    const moveable = currentMoveable.element;
    if (moveable) {
      const image = moveable.querySelector('img');
      if (image) {
        image.style.filter = `
        blur(${imageProps.blur}px) 
        brightness(${imageProps.brightness}%) 
        grayscale(${imageProps.grayscale}%) 
        hue-rotate(${imageProps.hue}deg) 
        invert(${imageProps.invert}%)
        opacity(${imageProps.opacity}%)
      `;
      }
    }
  };
  const reset = () => {
    const moveable = currentMoveable.element;
    if (original && moveable) {
      moveable.replaceWith(original);
    }
  };
  const invoke = (ans) => {
    if (ans === translateWords.delete[lang]) {
      const moveable = currentMoveable.element;
      if (moveable) {
        moveable.remove();
      }
    }
  };
  const handleDelete = () => {
    const moveable = currentMoveable.element;
    if (moveable) {
      dispatch({
        type: stateNames.openAlert,
        openAlert: {
          title: `${translateWords.delete[lang]} ${translateWords.image[lang]}`,
          contentText: translateWords.areYouSure[lang],
          first: translateWords.delete[lang],
          second: translateWords.cancel[lang],
          open: true,
          choice: '',
          invoke,
        },
      });
    }
  };
  let x = 1;
  const flipHorizontal = () => {
    const moveable = currentMoveable.element;
    if (moveable) {
      const image = moveable.querySelector('img')as HTMLImageElement;
      if (image) {
        image.style.transform = `scaleX(${-x})`;
        x = -x;
      }
    }
  };
  let y = 1;
  const flipVertical = () => {
    const moveable = currentMoveable.element;
    if (moveable) {
      const image = moveable.querySelector('img') as HTMLImageElement;
      if (image) {
        image.style.transform = `scaleY(${-y})`;
        y = -y;
      }
    }
  };
  let rl = 45;
  const rotateLeft = () => {
    const moveable = currentMoveable.element;
    if (moveable) {
      const image = moveable.querySelector('img') as HTMLImageElement;
      if (image) {
        image.style.transform = `rotate(${-rl}deg)`;
        rl += 45;
      }
    }
  };
  let rr = 45;
  const rotateRight = () => {
    const moveable = currentMoveable.element;
    if (moveable) {
      const image = moveable.querySelector('img') as HTMLImageElement;
      if (image) {
        image.style.transform = `rotate(${rr}deg)`;
        rr += 45;
      }
    }
  };
  const handleReady = () => {
    setCrop(!crop);
    const moveable = currentMoveable.element;
    if (moveable) {
      const moveableController = moveable.querySelector('.moveable-control-box');
      if (moveableController) {
        moveableController.remove();
      }
      if (!crop) {
        if (cropper) {
          cropper.destroy();
          return;
        }
      }
      // if (cropper) return; // if there is an instance of cropper alreay, return
      const img = moveable.querySelector('img') as HTMLImageElement;
      if (img) {
        const cpr = new Cropper(img, {
          // aspectRatio: 16 / 9,
          ready: () => {
            setCropper(cpr);
          },
        });
      }
    }
  };
  const apply = (e) => {
    e.preventDefault();
    const moveable = currentMoveable.element;
    if (!moveable) return;
    if (cropper && crop) {
      const cropped = cropper.crop();
      if (cropped) {
        const canvas = cropper.getCroppedCanvas();
        if (canvas) {
          if (canvas) {
            const url = canvas.toDataURL();
            cropper.destroy();
            const img = moveable.querySelector('img') as HTMLImageElement;
            if (img) {
              const image = new Image();
              image.height = img.height;
              image.width = img.width;
              image.src = url;
              image.onload = () => {
                img.replaceWith(image);
              };
              // img.replaceWith(image);
            }
          }
        }
      }
    }
    if (!crop) {
      const img = moveable.querySelector('img');
      if (img) {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.filter = img.style.filter;
          ctx.drawImage(img, 0, 0, img.width, img.height);
        }
        const url = canvas.toDataURL();
        const image = new Image();
        image.height = img.height;
        image.width = img.width;
        image.src = url;
        image.onload = () => {
          img.replaceWith(image);
        };
      }
    }
  };

  return (
    <div>
      <Grid container direction="row">
        <span
          style={{ fontFamily: menuFontFamily }}
          className={classes.slider}
        >
          { translateWords.blur[lang] }
          <Slider
            min={0}
            max={5}
            onChange={(e, newValue): void => {
              setImageProps({ ...imageProps, blur: newValue || newValue[0] || 0 });
              updateProps();
            }}
            value={imageProps.blur}
            disabled={crop}
          />
        </span>
        <span style={{ fontFamily: menuFontFamily }} className={classes.slider}>
          { translateWords.brighteness[lang] }
          <Slider
            min={0}
            max={200}
            defaultValue={100}
            onChange={(e, newValue): void => {
              setImageProps({ ...imageProps, brightness: newValue || newValue[0] || 100 });
              updateProps();
            }}
            value={imageProps.brightness}
            disabled={crop}
          />
        </span>
        <span style={{ fontFamily: menuFontFamily }} className={classes.slider}>
          { translateWords.grayscale[lang] }
          <Slider
            min={0}
            max={100}
                  // defaultValue={100}
            onChange={(e, newValue): void => {
              setImageProps({ ...imageProps, grayscale: newValue || newValue[0] || 100 });
              updateProps();
            }}
            value={imageProps.grayscale}
            disabled={crop}
          />
        </span>
        <span style={{ fontFamily: menuFontFamily }} className={classes.slider}>
          { translateWords.hue[lang] }
          <Slider
            min={0}
            max={360}
                  // defaultValue={100}
            onChange={(e, newValue): void => {
              setImageProps({ ...imageProps, hue: newValue || newValue[0] || 360 });
              updateProps();
            }}
            value={imageProps.hue}
            disabled={crop}
          />
        </span>
        <span style={{ fontFamily: menuFontFamily }} className={classes.slider}>
          { translateWords.invert[lang] }
          <Slider
            min={0}
            max={100}
                  // defaultValue={100}
            onChange={(e, newValue): void => {
              setImageProps({ ...imageProps, invert: newValue || newValue[0] || 100 });
              updateProps();
            }}
            value={imageProps.invert}
            disabled={crop}
          />
        </span>
        <span style={{ fontFamily: menuFontFamily }} className={classes.slider}>
          { translateWords.opacity[lang] }
          <Slider
            min={0}
            max={100}
                  // defaultValue={100}
            onChange={(e, newValue): void => {
              setImageProps({ ...imageProps, opacity: newValue || newValue[0] || 100 });
              updateProps();
            }}
            value={imageProps.opacity}
            disabled={crop}
          />
        </span>
        <span className={classes.buttons}>
          {/* <Button
            onClick={dropShadow}
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            size="small"
            color="primary"
            variant="outlined"
          >
            { translateWords.shadow[lang] }
          </Button> */}
          <Button
            onClick={flipHorizontal}
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            size="small"
            color="primary"
            variant="outlined"
            disabled={crop}
          >
            {'\u2194  '}
            {/* { translateWords.flipHorizontal[lang] } */}
          </Button>
          <Button
            onClick={flipVertical}
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            size="small"
            color="primary"
            variant="outlined"
            disabled={crop}
          >
            {'\u2195  '}
            {/* { translateWords.flipVertical[lang]} */}
          </Button>
          <Button
            onClick={rotateLeft}
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            size="small"
            color="primary"
            variant="outlined"
            disabled={crop}
          >
            {'\u27F2  '}
            {/* { translateWords.flipVertical[lang]} */}
          </Button>
          <Button
            onClick={rotateRight}
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            size="small"
            color="primary"
            variant="outlined"
            disabled={crop}
          >
            {'\u27F3  '}
            {/* { translateWords.flipVertical[lang]} */}
          </Button>
          <span>  |  </span>
          <Button
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            onClick={handleReady}
            variant="outlined"
            color="primary"
          >
            <Crop color="primary" style={{ fontSize: 'smaller', paddingRight: 10 }} />
            {translateWords.crop[lang]}
          </Button>
          <span>   |   </span>
          <Button
            onClick={e => apply(e)}
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            size="small"
            color="primary"
            variant="outlined"
            // disabled={!crop || !cropper}
          >
            {/* <Clear style={{ fontSize: 'smaller', paddingRight: 3 }} /> */}
            { translateWords.save[lang] }
          </Button>
          <span>   |   </span>
          <Button
            onClick={reset}
            style={{ fontFamily: menuFontFamily, textTransform: 'none' }}
            size="small"
            color="secondary"
            variant="outlined"
            // disabled={!crop}
          >
            <Clear style={{ fontSize: 'smaller', paddingRight: 3 }} />
            { translateWords.cancel[lang] }
          </Button>
          <span>   |   </span>
          <Button
            style={{ fontFamily: menuFontFamily, marginLeft: 20 }}
            color="secondary"
            onClick={handleDelete}
            variant="outlined"
            // disabled={!crop}
          >
            <Delete style={{ fontSize: 'smaller', paddingRight: 3 }} />
            { translateWords.delete[lang] }
          </Button>
          {/* <Button style={{ fontFamily: menuFontFamily }} color="primary" onClick={handleContinue}>
          { translateWords.enter[lang] : translateWords.enter[lang] }
        </Button> */}
        </span>
      </Grid>

    </div>
  );
};
