import setFocus from './setFocus';

/**
 * finds the last textNode recursively
 * @param {Node} node
 */
export const findLastTextNode = (node) => {
  if (node.nodeType === Node.TEXT_NODE) return node;
  const { childNodes } = node;
  for (let i = childNodes.length - 1; i >= 0; i -= 1) {
    const textNode = findLastTextNode(childNodes[i]);
    if (textNode !== null) return textNode;
  }
  return null;
};

/**
   * Focuses inside element just before the first textNode
   * @param {Node | HTMLElement} el;
   */
export const moveCaretInside = (el: Node | HTMLElement | Element): void => {
  const range = document.createRange();
  const sel = document.getSelection();
  if (!sel) return;
  range.setStart(el, 0);
  range.setEnd(el, 0);
  range.collapse(false);
  sel.removeAllRanges();
  sel.addRange(range);
  if (el instanceof HTMLElement) el.focus();
  setFocus();
};

/**
   * Finds the last textnode iside element and focus on it
   * @param {Node} el
   */
export const replaceCaret = (el) => {
  if (!el) return;
  if (!el.textContent) {
    moveCaretInside(el);
    return;
  }
  const target = findLastTextNode(el);
  const range = document.createRange();
  const sel = window.getSelection();
  if (!sel) return;
  range.setStart(target, target.length);
  range.setEnd(target, target.length);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
  if (el instanceof HTMLElement) el.focus();
  setFocus();
};

// export const moveCaretOutside = (el) => {
//   const range = document.createRange();
//   const sel = window.getSelection();
//   if (!sel) return;
//   range.setStart(target, target.length);
//   range.setEnd(target, target.length);
//   range.collapse(true);
//   sel.removeAllRanges();
//   sel.addRange(range);
//   if (el instanceof HTMLElement) el.focus();
// };
