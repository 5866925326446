const brand = 'derassi';

export const getNextPageId = (id: string): string => {
  const original = id.split('-');
  const pageNum = Number(original.pop()) + 1;
  const nextId = `${original.join('-')}-${pageNum}`;
  return nextId;
};

export const getPrevPageId = (id: string): string => {
  const original = id.split('-');
  const pageNum = Number(original.pop()) - 1;
  // const actualNum = pageNum >= 0 ? pageNum : 0;
  const prevId = `${original.join('-')}-${pageNum}`;
  return prevId;
};

// eslint-disable-next-line max-len
export const getPrevPage = (ci: string): HTMLElement | null => document.getElementById(getPrevPageId(ci));
// eslint-disable-next-line max-len
export const getNextPage = (ci: string): HTMLElement | null => document.getElementById(getNextPageId(ci));


export const getIdFromString = (id: string): number => Number(id.split('-').pop());
export const generateId = (id: number | string, str: string): string => `${brand}-${str}-${id}`;


export const generatePageId = (num: number): string => generateId(num, 'page');
export const generateBodyId = (num: number): string => generateId(num, 'body');
export const generateHeaderId = (num: number): string => generateId(num, 'header');
export const generateFooterId = (num: number): string => generateId(num, 'footer');
export const generateTableId = (num: string): string => generateId(num, 'table');
export const generateChartId = (num: string): string => generateId(num, 'chart');
export const generateListId = (num: string): string => generateId(num, 'list');
export const generateParagraphId = (num: string): string => generateId(num, 'paragraph');

export const getFooterIdForPage = (pageId: string): string => {
  const id = getIdFromString(pageId);
  return generateFooterId(id);
};

export const getHeaderIdForPage = (pageId: string): string => {
  const id = getIdFromString(pageId);
  return generateHeaderId(id);
};
// export const getWrapperIdForPage = (pageId: string): string => {
//   const id = getIdFromString(pageId);
//   return generateWrapperId(id);
// };
export const getBodyIdForPage = (pageId: string): string => {
  const id = getIdFromString(pageId);
  return generateBodyId(id);
};

// eslint-disable-next-line max-len
export const getFooterForPage = (pageId: string): HTMLElement | null => document.getElementById(getFooterIdForPage(pageId));
// eslint-disable-next-line max-len
export const getHeaderForPage = (pageId: string): HTMLElement | null => document.getElementById(getHeaderIdForPage(pageId));
// eslint-disable-next-line max-len
export const getBodyForPage = (pageId: string): HTMLElement | null => document.getElementById(getBodyIdForPage(pageId));

export const generateClass = (str: string): string => `${brand}-${str}`;
export const generatePageClass = (): string => generateClass('page');
export const generateHeaderFooterClass = (): string => generateClass('header-footer');
export const generateHeaderClass = (): string => generateClass('header');
export const generateFooterClass = (): string => generateClass('footer');
export const generateBodyClass = (): string => generateClass('body');
export const generateContentEditableClass = (): string => generateClass('content-editable');
export const generateTableClass = (): string => generateClass('table');
export const generateChartClass = (): string => generateClass('chart');
export const generateListClass = (): string => generateClass('list');
export const generateImageClass = (): string => generateClass('image');
export const generateParagraphClass = (): string => generateClass('paragraph');
export const generateChartCanvasClass = (): string => generateClass('chart-canvas');
export const generateContainerClass = (): string => generateClass('container');
export const generateUnformattedClass = (): string => generateClass('unformatted');
export const generateMoveableClass = (): string => generateClass('moveable');
export const generateParagraphForwarding = (): string => generateClass('paragraph-forwarding');
export const generateParagraphBackwarding = (): string => generateClass('paragraph-backwarding');

export const pageClass = generatePageClass();
export const headerFooterClass = generateHeaderFooterClass();
export const headerClass = generateHeaderClass();
export const footerClass = generateFooterClass();
export const bodyClass = generateBodyClass();
export const contentEditableClass = generateContentEditableClass();
export const tableClass = generateTableClass();
export const chartClass = generateChartClass();
export const listClass = generateListClass();
export const imageClass = generateImageClass();
export const paragraphClass = generateParagraphClass();
export const chartCanvasClass = generateChartCanvasClass();
export const containerClass = generateContainerClass();
export const unformattedClass = generateUnformattedClass();
export const moveableClass = generateMoveableClass();
export const paragraphForwarding = generateParagraphForwarding();
export const paragraphBackwarding = generateParagraphBackwarding();

export const dotPageClass = `.${pageClass}`;
export const dotHeaderFooterClass = `.${headerFooterClass}`;
export const dotHeaderClass = `.${headerClass}`;
export const dotFooterClass = `.${footerClass}`;
export const dotBodyClass = `.${bodyClass}`;
export const dotContentEditableClass = `.${contentEditableClass}`;
export const dotTableClass = `.${tableClass}`;
export const dotChartClass = `.${chartClass}`;
export const dotListClass = `.${listClass}`;
export const dotImageClass = `.${imageClass}`;
export const dotParagraphClass = `.${paragraphClass}`;
export const dotChartCanvasClass = `.${chartCanvasClass}`;
export const dotContainerClass = `.${containerClass}`;
export const dotMoveableClass = `.${moveableClass}`;
export const dotParagraphForwarding = `.${paragraphForwarding}`;
export const dotParagraphBackwarding = `.${paragraphBackwarding}`;
