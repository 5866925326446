import uniqid from 'uniqid';
import imageToBase64 from 'image-to-base64';
import {
  encode, decode, trim,
  isBase64, isUrlSafeBase64,
} from 'url-safe-base64';
import gDriveService from '../../../services/gDrive';
import { textParent, currentMoveable } from '../../../eventHandlers/changeHistory';
import getPictureService from '../../../services/getPicture';
import addImage from './addImage';
import { dotContainerClass } from '../../../nameGenerator';


let thisState: InitialState;
let thisDispatch: Function;

const photosCallback = (e) => {
  const { action } = e;
  let src = '';
  if (action === 'picked') {
    const { docs } = e;
    const {
      id, name, url, mimeType,
    } = docs[0];
    if (docs[0].thumbnails) {
      const loc = docs[0].thumbnails[1].url;
      const { height, width } = docs[0].thumbnails[1];
      src = loc;
      const { parent } = textParent;
      if (parent) {
        const refContainer = parent.closest(dotContainerClass) as HTMLElement;
        addImage({
          data: {
            src, id: uniqid(), name,
          },
          state: thisState,
          dispatch: thisDispatch,
          refContainer,
        });
      }
    } else { // drive and uploads
      try {
        getPictureService(id).then((data) => {
          const { parent } = textParent;
          if (parent) {
            const refContainer = parent.closest(dotContainerClass) as HTMLElement;
            addImage({
              data: {
                src: data || url, id: uniqid(), name,
              },
              // newInsertion: true,
              state: thisState,
              dispatch: thisDispatch,
              refContainer,
            });
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
};

const handleDrivePicker = (e, state, dispatch) => {
  thisState = state;
  thisDispatch = dispatch;
  switch (e.currentTarget.id) {
    case 'upload':
      gDriveService(state, [0, 1, 2, 3], photosCallback);
      return;
    case 'search':
      gDriveService(state, [1, 2, 3, 0], photosCallback);
      return;
    case 'drive':
      gDriveService(state, [2, 3, 0, 1], photosCallback);
      break;
    case 'photos':
      gDriveService(state, [3, 0, 1, 2], photosCallback);
      break;
    default:
  }
};

export default handleDrivePicker;
