import _ from "lodash";

export const translateWords = {
  add: ['ያክሉ', 'ያክሉ', 'ያክሉ', 'add', 'add'],
  all: ['ሁሉም', 'ሁሉም', 'ሁሉም', 'all', 'all'],
  tight: ['ጠባብ', 'ጠባብ', 'ጠባብ', 'tight', 'tight'],
  addFooter: ['የግርጌ ያክሉ', 'የግርጌ ያክሉ', 'የግርጌ ያክሉ', 'add footer', 'add footer'],
  addHeader: ['ራስጌ ያክሉ', 'ራስጌ ያክሉ', 'ራስጌ ያክሉ', 'add header', 'add header'],
  page: ['ገጽ', 'ገጽ', 'ገጽ', 'page', 'page'],
  addPageNumber: ['የገጽ ቁጥር', 'የገጽ ቁጥር', 'የገጽ ቁጥር', 'page number', 'page number'],
  areYouSure: ['ዕርግጥ ነው', 'ዕርግጥ ነው', 'ዕርግጥ ነው', 'are you sure', 'are you sure'],
  cancel: ['ይቅር', 'ይቅር', 'ይቅር', 'cancel', 'cancel'],
  changeFont: ['ቁምፊ ለውጥ', 'ቁምፊ ለውጥ', 'ቁምፊ ለውጥ', 'change font', 'change font'],
  chart: ['ግራፍ', 'ግራፍ', 'ግራፍ', 'chart', 'chart'],
  copy: ['ግልባጭ', 'ግልባጭ', 'ግልባጭ', 'copy', 'copy'],
  crop: ['መጠን', 'መጠን', 'መጠን', 'crop', 'crop'],
  cut: [' መቁረጥ', ' መቁረጥ', ' መቁረጥ', 'cut', 'cut'],
  delete: ['ጽዳት', 'ጽዳት', 'ጽዳት', 'delete', 'delete'],
  derassi: ['ደራሲ', 'ደራሲ', 'ደራሲ', 'derassi', 'derassi'],
  documentSaved: ['ሰነድ ተቀምጧል', 'ሰነድ ተቀምጧል', 'ሰነድ ተቀምጧል', 'document saved', 'document saved'],
  documents: ['ሰነዶች', 'ሰነዶች', 'ሰነዶች', 'documents', 'documents'],
  edit: ['ዕርማት', 'ዕርማት', 'ዕርማት', 'edit', 'edit'],
  emmbed: ['ኢሜይል ውስጥ', 'ኢሜይል ውስጥ', 'ኢሜይል ውስጥ', 'emmbed', 'emmbed'],
  enter: ['ቀጥል', 'ቀጥል', 'ቀጥል', 'enter', 'enter'],
  enterImageUrl: ['የምስል URL ያስገቡ', 'የምስል URL ያስገቡ', 'የምስል URL ያስገቡ', 'enter image url', 'enter image url'],
  errorOccured: ['ስህተት ተገኝቷል', 'ስህተት ተገኝቷል', 'ስህተት ተገኝቷል', 'error occured', 'error occured'],
  file: ['ፋይል', 'ፋይል', 'ፋይል', 'File', 'File'],
  firstPageOnly: ['የመጀመሪያ ገጽ ብቻ', 'የመጀመሪያ ገጽ ብቻ', 'የመጀመሪያ ገጽ ብቻ', 'First Page Only', 'First Page Only'],
  includeFirstPage: ['የመጀመሪያ ጥምር', 'የመጀመሪያ ጥምር', 'የመጀመሪያ ጥምር', 'First Page Only?', 'First Page Only?'],
  findAndReplace: ['ፈልጎ መተካት', 'ፈልጎ መተካት', 'ፈልጎ መተካት', 'find and replace', 'find and replace'],
  find: ['ይፈልጉ', 'ይፈልጉ', 'ይፈልጉ', 'find', 'find'],
  sign: ['ፊርማ', 'ፊርማ', 'ፊርማ', 'sign', 'sign'],
  spacing: ['ክፍተት', 'ክፍተት', 'ክፍተት', 'spacing', 'spacing'],
  replace: ['ይተኩ', 'ይተኩ', 'ይተኩ', 'replace', 'replace'],
  decorate: ['ጌጥ', 'ጌጥ', 'ጌጥ', 'decorate', 'decorate'],
  fromComputer: ['ከኮምፒዩተር', 'ከኮምፒዩተር', 'ከኮምፒዩተር', 'from computer', 'from computer'],
  heading: ['ዐርዕስት', 'ዐርዕስት', 'ዐርዕስት', 'heading', 'heading'],
  help: ['ረዳት', 'ረዳት', 'ረዳት', 'Help', 'Help'],
  image: ['ምስል', 'ምስል', 'ምስል', 'image', 'image'],
  imageUrl: ['የምስል url', 'የምስል url', 'የምስል url', 'image url', 'image url'],
  insert: ['ማስገባት', 'ማስገባት', 'ማስገባት', 'insert', 'insert'],
  keyboard: ['ኪቦርድ', 'ኪቦርድ', 'ኪቦርድ', 'keyboard', 'keyboard'],
  leftSide: ['ወደ ግራ', 'ወደ ግራ', 'ወደ ግራ', 'left side', 'left side'],
  link: ['በድር አድራሻ', 'በድር አድራሻ', 'በድር አድራሻ', 'link', 'link'],
  loading: ['ትንሽ ይጠብቁ...', 'ትንሽ ይጠብቁ...', 'ትንሽ ይጠብቁ...', 'loading...', 'loading...'],
  login: ['ይግቡ', 'ይግቡ', 'ይግቡ', 'login', 'login'],
  logout: ['ይውጡ', 'ይውጡ', 'ይውጡ', 'logout', 'logout'],
  myDocs: ['ዶክመንቶቼ', 'ዶክመንቶቼ', 'ዶክመንቶቼ', 'my docs', 'my docs'],
  new: ['ዐዲስ', 'ዐዲስ', 'ዐዲስ', 'new', 'new'],
  modify: ['ማሻሻል', 'ማሻሻል', 'ማሻሻል', 'modify', 'modify'],
  makeCopy: ['ዐዲስ ገልብጥ', 'ዐዲስ ገልብጥ', 'ዐዲስ ገልብጥ', 'make copy', 'make copy'],
  or: ['ወይም', 'ወይም', 'ወይም', 'Or', 'Or'],
  paste: ['ቅፀላ', 'ቅፀላ', 'ቅፀላ', 'paste', 'paste'],
  formatted: ['የዘነጠ', 'የዘነጠ', 'የዘነጠ', 'formatted', 'formatted'],
  unformatted: ['ዕንዳለ', 'ዕንዳለ', 'ዕንዳ', 'unformatted', 'unformatted'],
  style: ['ዕስታየል', 'ዕስታየል', 'ዕስታየል', 'style', 'style'],
  print: ['ወረቀት', 'ወረቀት', 'ወረቀት', 'Print', 'Print'],
  redo: ['እንደገና ቀይር', 'እንደገና ቀይር', 'እንደገና ቀይር', 'redo', 'redo'],
  rightSide: ['ወደ ቀኝ', 'ወደ ቀኝ', 'ወደ ቀኝ', 'right side', 'right side'],
  save: ['ለማስቀመጥ', 'ለማስቀመጥ', 'ለማስቀመጥ', 'save', 'save'],
  search: ['ይፈልጉ', 'ይፈልጉ', 'ይፈልጉ', 'search', 'search'],
  selectFromFile: ['ከፋይል ይምረጡ', 'ከፋይል ይምረጡ', 'ከፋይል ይምረጡ', 'select from file', 'select from file'],
  share: ['ሼር', 'ሼር', 'ሼር', 'share', 'share'],
  signUp: ['ይመዝገቡ', 'ይመዝገቡ', 'ይመዝገቡ', 'sign up', 'sign up'],
  shortPageName: ['አጭር የገጽ ስም: (ለምሳሌ: ገጽ 1)', 'አጭር የገጽ ስም: (ለምሳሌ: ገጽ 1)', 'አጭር የገጽ ስም: (ለምሳሌ: ገጽ 1)', 'Short page name: (ex: page 1)', 'Short page name: (ex: page 1)'],
  sorry: ['ይቅርታ ዕንጠይቃለን', 'ይቅርታ ዕንጠይቃለን', 'ይቅርታ ዕንጠይቃለን', 'sorry', 'sorry'],
  commingSoon: ['በቅርብ ቀን', 'በቅርብ ቀን', 'በቅርብ ቀን', 'comming soon', 'comming soon'],
  switchAccount: ['መለያ ቀይር', 'መለያ ቀይር', 'መለያ ቀይር', 'switch account', 'switch account'],
  symbol: ['ምልክት','ምልክት','ምልክት','symbol', 'symbol'],
  previous: ['ወደኋላ', 'ወደኋላ', 'ወደኋላ', 'previous', 'previous'],
  next: ['ወደፊት', 'ወደፊት', 'ወደፊት', 'next', 'next'],
  replaceAll: ['ሁሉም ይተካ', 'ሁሉም ይተካ', 'ሁሉም ይተካ', 'replace all', 'replace all'],
  table: ['ሰንጠረዥ', 'ሰንጠረዥ', 'ሰንጠረዥ', 'table', 'table'],
  text: ['ጽሑፍ', 'ጽሑፍ', 'ጽሑፍ', 'text', 'text'],
  title: ['ዐርስት', 'ዐርስት', 'ዐርስት', 'title', 'title'],
  mainTitle: ['ዘይቤ ዐርስት', 'ዘይቤ ዐርስት', 'ዘይቤ ዐርስት', 'Main title', 'Main title'],
  subTitle: ['ስነ ዐርስት', 'ስነ ዐርስት', 'ስነ ዐርስት', 'Sub title', 'Sub title'],
  undo: ['ተመለስ', 'ተመለስ', 'ተመለስ', 'undo', 'undo'],
  view: ['ለዓይን', 'ለዓይን', 'ለዓይን', 'view', 'view'],
  youAreNotLoggedIn: ['በመለያ አልገቡም', 'በመለያ አልገቡም', 'በመለያ አልገቡም', 'you are not logged in', 'you are not logged in'],
  pastChart: ['ከዚህ በታች ባለው ሳጥን ውስጥ የግራፍ አይነት ይምረጡ እና ይለጥፉ; በኮማ በመለያየት: (ለምሳሌ: 1,2,3,4)', 'ከዚህ በታች ባለው ሳጥን ውስጥ የገበታ አይነት ይምረጡ እና ይለጥፉ; በኮማ በመለያየት: (ለምሳሌ: 1,2,3,4)', 'ከዚህ በታች ባለው ሳጥን ውስጥ የገበታ አይነት ይምረጡ እና ይለጥፉ; በኮማ በመለያየት: (ለምሳሌ: 1,2,3,4)', 'Chose chart type and paste data below; comma separated, ex: 1,2,3,4', 'Chose chart type and paste data below; comma separated, ex: 1,2,3,4'],
  pastTable: ['ከዚህ በታች ይምረጡ', 'ከዚህ በታች ይምረጡ', 'ከዚህ በታች ይምረጡ', 'Add table by choosing from below', 'Add table by choosing from below'],
  chooseType: ['ዓይነት ይምረጡ', 'ዓይነት ይምረጡ', 'ዓይነት ይምረጡ', 'choose Type', 'choose Type'],
  yValue: ['ወደ ላይ (Y)', 'ወደ ላይ (Y)', 'ወደ ላይ (Y)', 'Y values', 'Y values'],
  xValue: ['ወደ አግድም (X)', 'ወደ አግድም (X)', 'ወደ አግድም (X)', 'X values', 'X values'],
  column: ['ወደ ላይ', 'ወደ ላይ', 'ወደ ላይ', 'column', 'column'],
  row: ['ወደ አግድም', 'ወደ አግድም', 'ወደ አግድም', 'row', 'row'],
  setting: ['ቅንጅት', 'ቅንጅት', 'ቅንጅት', 'setting', 'setting'],
  line: ['መስመር', 'መስመር', 'መስመር', 'line', 'line'],
  area: ['ሙሉ', 'ሙሉ', 'ሙሉ', 'area', 'area'],
  openPDF: ['ወደ PDF', 'ወደ PDF', 'ወደ PDF', 'To PDF', 'To PDF'],
  open: ['መክፈት', 'መክፈትF', 'መክፈት', 'Open', 'Open'],
  to: ['ወደ', 'ወደ', 'ወደ', 'To', 'To'],
  bar: ['አሞሌ', 'አሞሌ', 'አሞሌ', 'bar', 'bar'],
  pie: ['ሙሉ ክብ', 'ሙሉ ክብ', 'ሙሉ ክብ', 'pie', 'pie'],
  donut: ['ክብ', 'ክብ', 'ክብ', 'donut', 'donut'],
  heatMap: ['ነጠብጣብ', 'ነጠብጣብ', 'ነጠብጣብ', 'heat map', 'heat map'],
  zoomIn: ['ማጉላት', 'ማጉላት', 'ማጉላት', 'zoom in', 'zoom in'],
  zoomOut: ['ማሳነስ', 'ማሳነስ', 'ማሳነስ', 'zoom out', 'zoom out'],
  grayscale: ['ግራጫማ', 'ግራጫማ', 'ግራጫማ', 'grayscale', 'grayscale'],
  shadow: ['ጥላ', 'ጥላ', 'ጥላ', 'shadow', 'shadow'],
  opacity: ['ብርሃን-ከልነት', 'ብርሃን-ከልነት', 'ብርሃን-ከልነት', 'opacity', 'opacity'],
  hue: ['ድብልቅ', 'ድብልቅ', 'ድብልቅ', 'hue', 'hue'],
  saturate: ['የተስተካከለ', 'የተስተካከለ', 'የተስተካከለ', 'saturate', 'saturate'],
  brighteness: ['ብሩህነት', 'ብሩህነት', 'ብሩህነት', 'brighten', 'brighten'],
  invert: ['የተገለበጠ', 'የተገለበጠ', 'የተገለበጠ', 'inverte', 'inverte'],
  mirror: ['መስታወት', 'መስታወት', 'መስታወት', 'mirror', 'mirror'],
  blur: ['ብዥ', 'ብዥ', 'ብዥ', 'blur', 'blur'],
  reset: ['ወደ መጀመሪያ', 'ወደ መጀመሪያ', 'ወደ መጀመሪያ', 'rest', 'rest'],
  flipHorizontal: ['መገልበጥ', 'መገልበጥ', 'መገልበጥ', 'flip', 'flip'],
  flipVertical: ['መገልበጥ', 'መገልበጥ', 'መገልበጥ', 'flip', 'flip'],
  download: ['ማውረድ', 'ማውረድ', 'ማውረድ', 'download', 'download'],
  upload: ['ላይ', 'ላይ', 'ላይ', 'upload', 'upload'],
  preview: ['ቅድመ ዕይታ', 'ቅድመ ዕይታ', 'ቅድመ ዕይታ', 'preview', 'preview'],
  paragraph: ['አንቀጽ', 'አንቀጽ', 'አንቀጽ', 'paragraph', 'paragraph'],
  word: ['ቃል', 'ቃል', 'ቃል', 'word', 'word'],
  letter: ['ፊደል', 'ፊደል', 'ፊደል', 'letter', 'letter'],
  videos: ['ቪዲዮዎች', 'ቪዲዮዎች', 'ቪዲዮዎች', 'vidoes', 'vidoes'],
  contactUs: ['መልዕክቶት', 'መልዕክቶት', 'መልዕክቶት', 'contact us', 'contact us'],
  howTo: ['ዐጠቃቀም', 'ዐጠቃቀም', 'ዐጠቃቀም', 'how to', 'how to'],
  loginToSaveWork: ['ዕንዲመቾት ይግቡ', 'ዕንዲመቾት ይግቡ', 'ዕንዲመቾት ይግቡ', 'login in to save your work', 'login to save your work'],
  createdDocument: ['አዲስ ፋይል ተፈጥሯል', 'አዲስ ፋይል ተፈጥሯል', 'አዲስ ፋይል ተፈጥሯል', 'New DucmentCreated', 'New DucmentCreated'],
  backOnline: ['መስመር ላይ', 'መስመር ላይ', 'መስመር ላይ', 'back online', 'backonline'],
  error: ['አይችሉም', 'አይችሉም', 'አይችሉም', 'There was a problem', 'There was a problem'],
  errorSizeTooSmall: ['ተመሳሳይ መጠን እይደለም', 'ተመሳሳይ መጠን እይደለም', 'ተመሳሳይ መጠን እይደለም', 'size does not match', 'size does not match'],
  errorSizeTooBig: ['ተመሳሳይ መጠን እይደለም', 'ተመሳሳይ መጠን እይደለም', 'ተመሳሳይ መጠን እይደለም', 'size does not match', 'size does not match'],
  errorTypeNumber: ['ቁጥር ኣይደለም', 'ቁጥር ኣይደለም', 'ቁጥር ኣይደለም', 'is not number type', 'is not number type'],
  errorFetching: ['ሰነድዎን መጠን አይችሉም', 'ሰነድዎን መጠን አይችሉም', 'ሰነድዎን መጠን አይችሉም', 'could not fech file', 'could not fech file'],
  errorNotOnline: ['መስመር ላይ አይደሉም', 'መስመር ላይ አይደሉም', 'መስመር ላይ አይደሉም', 'you are not online', 'you are not online'],
};

// use lower case for keys
const qals: Record<string, string> = {
  'add header': 'ራስጌ ያክሉ',
  'add footer': 'የግርጌ ያክሉ',
  link: 'በድር አድራሻ',
  emmbed: 'ኢሜይል ውስጥ',
  cancel: 'ይቅር',
  copy: 'ገልብጥ',
  chart: 'ገበታ',
  'change font': 'ቁምፊ ለውጥ',
  'make copy': 'አዲስ ገልብጥ',
  derassi: 'ደራሲ',
  documents: 'ሰነዶች',
  'error occured': 'ስህተት ተገኝቷል',
  english: 'ዐበሻኛ',
  enter: 'ቀጥል',
  edit: 'ቀይር',
  'from computer': 'ከኮምፒዩተር',
  undo: 'ተመለስ',
  redo: 'እንደገና ቀይር',
  cut: 'ቁረጠ',
  paste: 'ለጥፍ',
  delete: 'ሰርዝ',
  'enter image url': 'የምስል URL ያስገቡ',
  'image url': 'የምስል url',
  insert: 'ቀፅል',
  image: 'ምስል',
  file: 'ሰነድ',
  heading: 'ርዕስ',
  help: 'እገዛ',
  'loading...': 'ትንሽ ይጠብቁ...',
  login: 'ይግቡ',
  logout: 'ይውጡ',
  'sign up': 'ይመዝገቡ',
  'my docs': 'ዶክመንቶቼ',
  new: 'አዲስ',
  'switch account': 'መለያ ቀይር',
  share: 'ሼር',
  'select from file': 'ከፋይል ይምረጡ',
  save: 'ጫን',
  sorry: 'ይቅርታ ዕንጠይቃለን',
  or: 'ወይም',
  print: 'አትም',
  title: 'ዐርስት',
  table: 'ሰንጠረዥ',
  'document saved': 'ሰነድ ተልኳል',
  'you are not logged in': 'በመለያ አልገቡም',
  'find and replace': 'ይፈልጉ እና ይተኩ',
  view: 'ዘይቤ',
};

export default (qal: string): string => qals[qal.toLowerCase()] || qal;

export const fontFamilies = {
  // 'Mulat Abay': 'ዐበሻ ለማ',
  // 'Abesha Arif': 'ዐበሻ ዐሪፍ',
  // 'Abesha Demeke': 'ዐበሻ ደመቀ',
  // 'Abesha Kitfo': 'ዐበሻ ክትፎ',
  // 'Mulat Abay Light': 'ዐበሻ ለማ ቀጭን',
  // 'Mulat Abay': 'ሙላት ዐባይ',
  // 'Mulat Abay Thin': 'ሙላት ዐባይ ቀጭን',
  // 'Abesha Tana': 'ዐበሻ ጣና',
  // 'Abesha Fasil': 'ዐበሻ ፋሲል',
  // 'Abesha Selam': 'ዐበሻ ሰላም',
  // 'Abesha Jolly': 'ዐበሻ ጆሊ',
  // 'Abesha Tibs': 'ዐበሻ ጥብስ',
  'Addis Abiy': 'ዐዲስ ዐብይ',
  'Addis Demeke': 'ዐዲስ ደመቀ',
  'Addis Gedu': 'ዐዲስ ገዱ',
  'Addis Lemma': 'ዐዲስ ለማ',
  'Addis Lemma Light': 'ዐዲስ ለማ ቀላል',
  'Addis Mustafa': 'ዐዲስ ሙስጠፋ',
  'Habesha Eritrea': 'ሀበሻ ዔርትራ',
  'Habesha Rwanda': 'ሀበሻ ርዋንዳ',
  'Habesha Somalia': 'ሀበሻ ሶማሊያ',
  'Habesha Uganda': 'ሀበሻ ዑጋንዳ',
  'Mulat Abay': 'ሙላት ዐባይ',
  'Mulat Abay Thin': 'ሙላት ዐባይ ቀላል',
  'Mulat Awash': 'ሙላት ዐዋሽ',
  'Mulat Axum': 'ሙላት ዐክሱም',
  'Mulat Fasil': 'ሙላት ፋሲል',
  'Mulat Omo': 'ሙላት ዖሞ',
  'Mulat Tana': 'ሙላት ጣና',
  'Mulat Tekeze': 'ሙላት ተከዜ',
};


export const fontsForMenu = {
  'Habesha Rwanda': 'ሀበሻ ርዋንዳ',
  'Mulat Axum': 'ሙላት ዐክሱም',
  'Addis Abiy': 'ዐዲስ ዐብይ',
  'Mulat Abay': 'ሙላት ዐባይ',
};

export const aggrigateFonts = _.memoize(() => {
  const fams = {};
  Object.entries(fontFamilies).reverse().forEach((w) => {
    const [k, v] = w;
    const [a, b, c] = k.split(' ');
    const name = a + b;
    const key = fams[name];
    if (key) {
      key.push(w);
    } else {
      fams[name] = [w];
    }
  });
  const arr: any[] = [];
  Object.entries(fams).forEach((x) => {
    const [name, value] = x;
    arr.push(value);
  });
  return arr;
});


// export const translateFromKeyboard = (abeshaArray) => {
//   let word = '';
//   if (abeshaArray instanceof Array) {
//     abeshaArray.forEach((letter) => {
//       if (keyboardMap[letter]) { word += keyboardMap[letter]; } else { word += letter; }
//     });
//     return word;
//   }
//   throw new Error('argument must be of type Array');
// };
