import React from 'react';
import {
  ListItem, ListItemText, ListItemIcon, CircularProgress,
} from '@material-ui/core';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import { translateWords } from '../../translate';
import generatePDF from './generatePDF';
import reEnter from '../editor/helpers/reFocus';
import devLog from '../../devLog';


export default (props) => {
  const { state, setOpen } = props;
  const { menuFontFamily, lang } = state as InitialState;
  const [loading, setLoading] = React.useState(false);
  const handleClick = () => {
    setLoading(true);
    generatePDF().then((blob) => {
      if (!blob) throw new Error('somthing went wrong');
      // const blob = new Blob([output('blob')], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      if (url) {
        window.open(url);
      }
      reEnter();
      setLoading(false);
      setOpen(false);
    }).catch(err => devLog(err));
  };
  return (
    <ListItem
      dense
      button
      key="openPDF"
      id="openPDF"
      onClick={handleClick}
    >
      <ListItemIcon><OpenInBrowser /></ListItemIcon>
      <ListItemText>
        {loading ? <CircularProgress size={10} />
          : (
            <div style={{ fontFamily: menuFontFamily }}>
              {translateWords.open[lang]}
              {'  '}
              PDF
            </div>
          )
}
      </ListItemText>
    </ListItem>
  );
};
