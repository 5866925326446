import iterator from 'dom-iterator';
import _ from 'lodash';
import historyReducer from '../../../eventHandlers/changeHistory';
import { moveCaretInside } from '../../../eventHandlers/helpers/moveFocus';
import { dotMoveableClass } from '../../../nameGenerator';
import attachControl from './attachControl';

const undo = (props) => {
  const { state, dispatch } = props;
  const history = historyReducer({ type: 'UNDO' });
  const { past, present } = history;
  if (!past || !present) return;
  // refocus;
  const {
    html, containerId, focusId, focusOffset,
  } = present;
  // const ref = current;
  // ref.innerHTML = html;
  const container = document.getElementById(containerId) as HTMLElement;
  // let focusContainer = container;
  // if (moveableId) {
  //   focusContainer = document.getElementById(moveableId) as HTMLElement;
  // }
  if (container) {
    container.innerHTML = html;
    const moveables = container.querySelectorAll(dotMoveableClass);
    Array.from(moveables).forEach((moveable) => {
      attachControl({ container: moveable, state, dispatch });
    });
    const focusParent = document.getElementById(focusId);
    if (focusParent && focusParent.textContent) {
      const focusNode = focusParent.firstChild;
      if (focusNode) {
        const sel = window.getSelection();
        const range = document.createRange();
        range.setStart(focusNode, focusOffset);
        range.setEnd(focusNode, focusOffset);
        if (sel) {
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if (focusParent && !focusParent.textContent) {
      moveCaretInside(focusParent);
    }
  }
};
// attempt and don't throw error/crush program
export default props => _.attempt(undo, props);
