import React from 'react';
import {
  Grid, Button, ButtonGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  BorderColor, KeyboardArrowUp, KeyboardArrowDown, FormatColorText,
} from '@material-ui/icons';
import { SketchPicker } from 'react-color';
import { translateWords } from '../../../../translate';
import { currentText } from '../../../../eventHandlers/changeHistory';

const useStyles = makeStyles(() => ({
  box: {
    '&: hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default (props): JSX.Element => {
  const { state } = props;
  const { menuFontFamily, lang } = state as InitialState;
  const classes = useStyles();
  const [shadow, setShadow] = React.useState({
    color: '',
    h: 0,
    v: 0,
    blur: 0,
  });
  const [stroke, setStroke] = React.useState({
    color: '',
    width: 0,
  });

  const [word, setWord] = React.useState(0);
  const [letter, setLetter] = React.useState(0);
  const [textColor] = React.useState('black');
  const [displayStrokePicker, setDisplayStrokePicker] = React.useState(false);
  const [displayShadowPicker, setDisplayShadowPicker] = React.useState(false);
  const [displayTextPicker, setDisplayTextPicker] = React.useState(false);

  const handleTextColor = (clr) => {
    const container = currentText.element;
    if (container) {
      if (clr && clr.hex) {
        container.style.color = clr.hex;
      }
    }
  };

  const handleShadow = (direction) => {
    setShadow({ ...shadow, v: shadow.v + direction, h: shadow.h + direction });
    const container = currentText.element;
    if (container) {
      container.style.textShadow = `${shadow.h}px ${shadow.v}px ${shadow.blur}px ${shadow.color}`;
    }
  };
  const hanleShadowReset = () => {
    const container = currentText.element;
    if (container) {
      container.style.textShadow = 'initial';
    }
  };
  const hanleStrokeReset = () => {
    const container = currentText.element;
    if (container) {
      container.style.webkitTextStroke = 'initial';
    }
  };

  const handleBlur = (direction) => {
    const container = currentText.element;
    setShadow({ ...shadow, blur: shadow.blur + direction });
    if (container) {
      container.style.textShadow = `${shadow.h}px ${shadow.v}px ${shadow.blur}px ${shadow.color}`;
    }
  };
  const handleBlurReset = () => {
    const container = currentText.element;
    if (container) {
      container.style.textShadow = `${shadow.h}px ${shadow.v}px ${0}px ${shadow.color}`;
    }
  };
  const handleWord = (direction) => {
    const container = currentText.element;
    setWord(word + direction);
    if (container) {
      container.style.wordSpacing = `${word}px`;
    }
  };
  const handleWordReset = () => {
    const container = currentText.element;
    if (container) {
      container.style.wordSpacing = 'initial';
    }
  };
  const handleLetter = (direction) => {
    const container = currentText.element;
    setLetter(letter + direction);
    if (container) {
      container.style.letterSpacing = `${letter}px`;
    }
  };
  const handleLetterReset = () => {
    const container = currentText.element;
    if (container) {
      container.style.letterSpacing = 'initial';
    }
  };


  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'relative',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };
  const handleShadowColor = (clr): void => {
    const container = currentText.element;
    if (clr && clr.hex && container) {
      setShadow({ ...shadow, color: clr.hex });
      container.style.textShadow = `${shadow.h}px ${shadow.v}px ${shadow.blur}px ${shadow.color}`;
    }
  };
  const handleStrokeColor = (clr): void => {
    const container = currentText.element;
    if (clr && clr.hex && container) {
      setStroke({ ...stroke, color: clr.hex });
      container.style.webkitTextStroke = `${stroke.width}px ${stroke.color}`;
    }
  };
  const handleStroke = (direction) => {
    setStroke({ ...stroke, width: stroke.width + direction });
    const container = currentText.element;
    if (container) {
      container.style.webkitTextStroke = `${stroke.width}px ${stroke.color}`;
    }
  };
  return (
    <div>
      <Grid container direction="row">
        <ButtonGroup
          size="small"
          variant="text"
          component="div"
          style={{
            maxHeight: 20, textTransform: 'none', outline: 'solid 1px blue', marginRight: 30,
          }}
        >
          <Button
            onClick={(e) => { e.preventDefault(); handleShadow(1); }}
            disabled={shadow.v >= 10}
          >
            <KeyboardArrowUp />
          </Button>
          <Button onClick={() => setDisplayShadowPicker(true)}>
            <BorderColor style={{ fontSize: 'smaller', color: shadow.color, marginRight: 8 }} />
            {displayShadowPicker ? (
              <div
                role="presentation"
                style={popover as React.CSSProperties}
                onMouseLeave={() => setDisplayShadowPicker(false)}
                onDoubleClick={() => setDisplayShadowPicker(false)}
              >
                <div style={cover as React.CSSProperties} />
                <SketchPicker color={textColor} onChange={(e): void => handleShadowColor(e)} />
              </div>
            ) : <span style={{ fontFamily: menuFontFamily, color: shadow.color }}>{translateWords.shadow[lang]}</span>}
          </Button>
          <Button color="secondary" onClick={() => hanleShadowReset()}>
            <span style={{ fontFamily: menuFontFamily }}>X</span>
          </Button>
          <Button
            onClick={() => handleShadow(-1)}
            disabled={shadow.v <= 0}
          >
            <KeyboardArrowDown />
          </Button>
        </ButtonGroup>
        <ButtonGroup
          size="small"
          variant="text"
          component="div"
          style={{
            maxHeight: 20, textTransform: 'none', outline: 'solid 1px blue', marginRight: 30,
          }}
        >
          <Button
            onClick={(e) => { e.preventDefault(); handleStroke(1); }}
            disabled={stroke.width >= 3}
          >
            <KeyboardArrowUp />
          </Button>
          <Button onClick={() => setDisplayStrokePicker(true)}>
            <BorderColor style={{ fontSize: 'smaller', color: stroke.color, marginRight: 8 }} />
            {displayStrokePicker ? (
              <div
                role="presentation"
                style={popover as React.CSSProperties}
                onMouseLeave={() => setDisplayStrokePicker(false)}
                onDoubleClick={() => setDisplayStrokePicker(false)}
              >
                <div style={cover as React.CSSProperties} />
                <SketchPicker color={textColor} onChange={(e): void => handleStrokeColor(e)} />
              </div>
            ) : <span style={{ fontFamily: menuFontFamily, color: shadow.color }}>{translateWords.style[lang]}</span>}
          </Button>
          <Button color="secondary" onClick={() => hanleStrokeReset()}>
            <span style={{ fontFamily: menuFontFamily }}>X</span>
          </Button>
          <Button
            onClick={() => handleStroke(-1)}
            disabled={stroke.width <= 0}
          >
            <KeyboardArrowDown />
          </Button>
        </ButtonGroup>
        <ButtonGroup
          size="small"
          variant="text"
          component="div"
          style={{
            maxHeight: 20, textTransform: 'none', outline: 'solid 1px blue', marginRight: 30,
          }}
        >
          <Button
            onClick={() => handleBlur(1)}
            disabled={shadow.blur >= 10}
          >
            <KeyboardArrowUp />
          </Button>
          <Button onClick={handleBlurReset}>
            <span style={{ fontFamily: menuFontFamily }}>{translateWords.blur[lang]}</span>
          </Button>
          <Button
            onClick={() => handleBlur(-1)}
            disabled={shadow.blur <= 0}
          >
            <KeyboardArrowDown />
          </Button>
        </ButtonGroup>
        <ButtonGroup
          size="small"
          variant="text"
          component="div"
          style={{
            maxHeight: 20, textTransform: 'none', outline: 'solid 1px blue', marginRight: 30,
          }}
        >
          <Button
            onClick={() => handleWord(1)}
            disabled={word >= 10}
          >
            <KeyboardArrowUp />
          </Button>
          <Button onClick={handleWordReset}><span style={{ fontFamily: menuFontFamily }}>{translateWords.word[lang]}</span></Button>
          <Button
            onClick={() => handleWord(-1)}
            disabled={word <= 0}
          >
            <KeyboardArrowDown />
          </Button>
        </ButtonGroup>
        <ButtonGroup
          size="small"
          variant="text"
          component="div"
          style={{
            maxHeight: 20, textTransform: 'none', outline: 'solid 1px blue', marginRight: 30,
          }}
        >
          <Button
            onClick={() => handleLetter(1)}
            disabled={letter >= 10}
          >
            <KeyboardArrowUp />
          </Button>
          <Button onClick={handleLetterReset}>
            <span style={{ fontFamily: menuFontFamily }}>{translateWords.letter[lang]}</span>
          </Button>
          <Button
            onClick={() => handleLetter(-1)}
            disabled={letter <= 0}
          >
            <KeyboardArrowDown />
          </Button>
        </ButtonGroup>
        <ButtonGroup
          size="small"
          variant="text"
          component="div"
          style={{
            maxHeight: 20, textTransform: 'none', outline: 'solid 1px blue', marginRight: 30,
          }}
        >
          <Button
            onClick={() => setDisplayTextPicker(true)}
            disabled={letter >= 10}
          >
            <FormatColorText style={{ fontSize: 'smaller' }} />
            {displayTextPicker ? (
              <div
                role="presentation"
                style={popover as React.CSSProperties}
                onMouseLeave={() => setDisplayTextPicker(false)}
                onDoubleClick={() => setDisplayTextPicker(false)}
              >
                <div style={cover as React.CSSProperties} />
                <SketchPicker color={textColor} onChange={(e): void => handleTextColor(e)} />
              </div>
            ) : null}
          </Button>
        </ButtonGroup>
      </Grid>
    </div>
  );
};
