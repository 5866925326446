import _ from 'lodash';
import uniqid from 'uniqid';
import addTable from '../../components/editor/helpers/addTable';
import addChart from '../../components/editor/helpers/addChart';
import addImage from '../../components/editor/helpers/addImage';
import paragraph from './paragraph';
import { moveCaretInside, findLastTextNode, replaceCaret } from './moveFocus';
import changeState, { textParent } from '../changeHistory';
import addList from '../../components/editor/helpers/addList';
import * as ng from '../../nameGenerator';
import attachControl from '../../components/editor/helpers/attachControl';
import normalizeBody from './normalizeBody';
import normalizePara from './normalizePara';

/**
 * Moves lines from first paragraph of folllowing page to current/focus page
 * @param target - the page where the focus/cursor is at
 */
export const moveBackLineByLine = (
  target: HTMLElement, firstElementChild, state, dispatch,
): void => {
  normalizePara(firstElementChild);
  normalizeBody(target, false);
  if (!firstElementChild.textContent
    || (firstElementChild.textContent && firstElementChild.textContent.length === 0)) {
    if (!firstElementChild.classList.contains(ng.paragraphBackwarding)) {
      const clone = firstElementChild.cloneNode(false);
      clone.id = `derassi-paragraph-${uniqid()}`;
      target.appendChild(clone);
    }
    firstElementChild.remove();
    return;
  }
  // find the height of the first line to be transfred,
  let iterator = document.createNodeIterator(firstElementChild, NodeFilter.SHOW_TEXT);
  let currentNode = iterator.nextNode();
  if (!currentNode) return;
  const range = document.createRange();
  range.setStart(currentNode, 0);
  // find min height
  const hights = new Set();
  while (currentNode) {
    const { length } = (currentNode.textContent || '');
    for (let index = 0; index < length; index += 1) {
      range.setEnd(currentNode, index);
      const ht = range.getBoundingClientRect().height;
      hights.add(ht);
    }
    currentNode = iterator.nextNode();
  }
  const heightsArray = Array.from(hights) as Array<number>;
  let neededHeight = Math.min(...heightsArray);
  const thisPage = document.getElementById(target.id);
  if (!thisPage) return;

  const flexBox = target.parentElement;
  if (!flexBox) return;
  const flexHeight = parseFloat(window.getComputedStyle(flexBox).height);
  // if not enough space to take the transferring line, return
  if (flexBox && flexHeight - target.offsetHeight < neededHeight) {
    return;
  }

  // reset nextnode
  iterator = document.createNodeIterator(firstElementChild, NodeFilter.SHOW_TEXT);
  currentNode = iterator.nextNode();
  let breakOut = false;
  const tags: RegExpMatchArray = [];
  // if ...Array size === 1, this is a one liner
  let oneLiner = false;
  if (heightsArray.length === 1) {
    oneLiner = true;
    breakOut = true;
  }
  if (oneLiner) {
    const clone = firstElementChild.cloneNode(true);
    target.append(clone);
    firstElementChild.remove();
    return;
  }
  const going = document.createElement('span');
  while (!breakOut && currentNode) {
    const length = currentNode.nodeType === Node.TEXT_NODE
      ? (currentNode.textContent || '').length
      : currentNode.childNodes.length;
    range.setEnd(currentNode, length);
    for (let index = 0; index < length; index += 1) {
      range.setEnd(currentNode, index);
      const heighByChar = range.getBoundingClientRect().height;
      const wentOver = heighByChar > neededHeight;
      if (wentOver) {
        neededHeight = heighByChar;
        const splitIndex = index > 0 ? index - 1 : 0;
        // const dsplitter = document.createElement('dsplit');
        const splitRange = document.createRange();
        splitRange.setStart(firstElementChild, 0);
        splitRange.setEnd(currentNode, splitIndex);
        const extract = splitRange.extractContents();
        const brs = extract.querySelectorAll('br');
        Array.from(brs).forEach(br => br.remove());
        going.appendChild(extract);
        // const splitedAT = (currentNode as Text).splitText(index > 0 ? index - 1 : 0);
        // if (currentNode.parentElement) {
        //   currentNode.parentElement.insertBefore(dsplitter, splitedAT);
        // }
        // let parent = currentNode.parentElement;
        // while (parent && !parent.classList.contains('derassi-paragraph')) {
        //   parent = parent.parentElement as HTMLElement;
        //   const { innerHTML } = parent;
        //   const tag = innerHTML.match(/<.+?>/);
        //   if (tag) {
        //     tags.push(tag[0]);
        //   }
        // }
        breakOut = true;
        break;
      }
    }
    currentNode = iterator.nextNode();
  }

  // split lines
  const paraHTML = firstElementChild.innerHTML;
  // const splitPara = paraHTML.split('<dsplit></dsplit>');
  // let going = splitPara[0];
  // let staying = splitPara[1] || '';
  // tags.forEach(() => {
  //   going = `${going}</span>`;
  // });
  // tags.reverse().forEach((tag) => {
  //   staying = `${tag}${staying}`;
  // });
  // const firstEC = firstElementChild;
  // firstEC.innerHTML = staying;
  // const goinParagaphHTML = going || '';

  // if firtChild of next page is not tranfering, name it with class transfrerring
  // and have it start transfering.
  // be careful about <br>
  const { lastElementChild } = target;
  if (firstElementChild.classList.contains(ng.paragraphBackwarding)) {
    if (lastElementChild && lastElementChild.querySelector('br')) {
      (lastElementChild.querySelector('br') as any).insertAdjacentHTML('beforebegin', going.innerHTML);
    } else if (lastElementChild) {
      lastElementChild.insertAdjacentHTML('beforeend', going.innerHTML);
    }
  } else {
    // const div = paragraph(state);
    const clone = firstElementChild.cloneNode(false);
    clone.id = `derassi-paragraph-${uniqid()}`;
    clone.classList.add(ng.paragraphBackwarding);
    clone.insertAdjacentHTML('afterbegin', going.innerHTML);
    target.append(clone);
  }
  // precausously remove if tranferring paragraph is empty
  const freshFirstElementChild = document.getElementById(firstElementChild.id);
  if (freshFirstElementChild && !freshFirstElementChild.textContent) {
    freshFirstElementChild.remove();
  }
};

export const moveBackLastUnformatted = (
  target, firstElementChild, state, dispatch,
): void => {
  const container = firstElementChild;
  if (!container) return;
  const computedHeight = window.getComputedStyle(container).height;
  const containerHeight = parseFloat(computedHeight);
  const flexBox = target.parentElement;
  if (!flexBox) return;
  const flexHeight = parseFloat(window.getComputedStyle(flexBox).height);
  // if not enough space to take the transferring line, return
  if (flexBox && flexHeight - target.offsetHeight < containerHeight) {
    return;
  }
  const copy = container.cloneNode(true) as HTMLElement;
  target.insertAdjacentElement('beforeend', copy);
  // attach after insertion and get a hot element
  // so moveable controller knows the size of the elemnt
  const cloned = document.getElementById(copy.id);
  attachControl({
    container: copy, state, dispatch,
  });
  container.remove();
  // const image = container.querySelector('img') as HTMLImageElement;
  // const { width, height, src } = image;
  // const data = { width, height, src };
  // if (image) {
  //   addImage({
  //     data, refContainer: target, insertion: 'end', state, dispatch,
  //   });
  // }
  // container.remove();
};


/**
 * moves images and charts up from next page
 * @param target - the current page the cursor/ focus is at
 */
export const moveBackLastImage = (
  target, firstElementChild, state, dispatch,
): void => {
  const container = firstElementChild as HTMLElement;
  if (!container) return;
  const isImage = container.querySelector('img');
  if (!isImage) return;
  const computedHeight = window.getComputedStyle(container).height;
  const containerHeight = parseFloat(computedHeight);
  const flexBox = target.parentElement;
  if (!flexBox) return;
  const flexHeight = parseFloat(window.getComputedStyle(flexBox).height);
  // if not enough space to take the transferring line, return
  if (flexBox && flexHeight - target.offsetHeight < containerHeight) {
    return;
  }

  const copy = container.cloneNode(true) as HTMLElement;
  target.insertAdjacentElement('beforeend', copy);
  // attach after insertion and get a hot element
  // so moveable controller knows the size of the elemnt
  // const cloned = document.getElementById(copy.id);
  attachControl({
    container: copy, image: true, state, dispatch,
  });
  container.remove();
  // const image = container.querySelector('img') as HTMLImageElement;
  // const { width, height, src } = image;
  // const data = { width, height, src };
  // if (image) {
  //   addImage({
  //     data, refContainer: target, insertion: 'end', state, dispatch,
  //   });
  // }
  // container.remove();
};

/**
 * moves lists (ul, li) up from next page
 * @param target - the current target where focus/cursor is at
 */
export const moveBackLastList = (
  target: HTMLElement, firstElementChild, state, dispatch,
): void => {
  const container = firstElementChild;
  const { lastElementChild } = target;
  if (!container) return;
  const list = container.querySelector('ul') || container.querySelector('ol');
  const oneLi = container.querySelector('li');
  const computedHeight = (list ? list.offsetHeight : 0) + (oneLi ? oneLi.offsetHeight : 0);
  // const computed1 = window.getComputedStyle(nextPageFristChild.querySelector('')).height;
  const containerHeight = computedHeight;
  const flexBox = target.parentElement;
  if (!flexBox) return;
  const flexHeight = parseFloat(window.getComputedStyle(flexBox).height);
  // if not enough space to take the transferring line, return
  if (flexBox && flexHeight - target.offsetHeight < containerHeight) {
    return;
  }
  const start = 1;
  if (!list) return;
  if (!container.classList.contains(ng.listClass)) {
    return;
  }
  if (container.classList.contains('derassi-list-backwarding')) {
    if (list.children.length === 0) {
      container.remove();
      return;
    }
  }
  const li = list.firstChild as HTMLLIElement;
  if (!li) return;
  li.remove();
  if (!container.classList.contains('derassi-list-backwarding')) {
    container.classList.add('derassi-list-backwarding');
    const type = list.nodeName.toLowerCase();
    addList(type, 'inherite', undefined, li, start, target);
  } else {
    const lastContainer = target.lastElementChild;
    if (!lastContainer) return;
    const lastList = lastContainer.querySelector('ol') || lastContainer.querySelector('ul');
    // lastList.setAttribute('start', start);
    if (!lastList) return;
    lastList.insertAdjacentElement('beforeend', li);
  }
};

export const moveBackLastTable = (
  target: HTMLElement,
  firstElementChild,
  state: InitialState, dispatch: Function,
): void => {
  const container = firstElementChild as HTMLElement;
  const { lastElementChild } = target;
  if (!container) return;
  if (!container.classList.contains(ng.tableClass)) {
    return;
  }
  const tr = container.querySelector('tr');
  const caption = container.querySelector('caption');

  const trh = tr ? tr.offsetHeight : 0;
  const captionH = caption ? caption.offsetHeight : 0;
  const flexBox = target.closest('[id*=box]') as HTMLElement;
  if (flexBox) {
    const flexHeight = flexBox.offsetHeight;

    // 2 * to account for table head and one rows
    if (flexHeight - target.offsetHeight + 5 < (2 * trh) + captionH) {
      return;
    }
  }
  if (!container.classList.contains('derassi-table-backwarding')) {
    container.classList.add('derassi-table-backwarding');
    // check for height
    const trs = Array.from(container.querySelectorAll('tr'));
    if (trs.length <= 1) {
      container.remove();
      return;
    }
    const clone = container.cloneNode(true) as HTMLElement;
    // the clone is not forwarding so remove this className
    clone.classList.remove('derassi-table-backwarding');
    if (trs) {
      const firstTwo = _.take(trs, 2);
      Array.from(firstTwo).forEach(f => f.remove());
    }
    const copyTrs = Array.from(clone.querySelectorAll('tr'));
    if (copyTrs) {
      const drop = _.drop(copyTrs, 2);
      for (let index = 0; index < drop.length; index += 1) {
        const element = drop[index];
        element.remove();
      }
    }
    target.insertAdjacentElement('beforeend', clone);
    // attach moveable after insertion and get a hot element
    // so it knows the exact size
    const cloned = document.getElementById(clone.id);
    attachControl({ container: cloned, state, dispatch });

    // const rawData = container.dataset.table || '';
    // const data = JSON.parse(rawData);
    // const head = data.rows[0];
    // const tail = data.rows.pop();
    // const moveData: Record<string, any> = {};
    // moveData.rows = [head, tail];
    // moveData.caption = data.caption;
    // // this page table
    // addTable({
    //   data: moveData, refContainer: target, insertion: 'end', state, dispatch,
    // });
    // // next page first table
    // addTable({
    //   data, refContainer: container, insertion: 'same', state, dispatch,
    // });
  } else {
    if (!lastElementChild || !lastElementChild.classList.contains(ng.tableClass)) return;
    const trs = Array.from(container.querySelectorAll('tr'));
    if (!trs) { // if only caption is left or nothng is left
      container.remove();
      return;
    }
    if (trs) {
      const first = trs[0];
      if (first) {
        const tbody = lastElementChild.querySelector('tbody');
        if (tbody) {
          const copy = first.cloneNode(true) as HTMLTableRowElement;
          tbody.insertAdjacentElement('beforeend', copy);
          first.remove();
        }
      } else {
        container.remove();
      }
    } else {
      container.remove();
    }
    // attach moveable after insertion and get a hot element
    // so it knows the exact size
    const lastContainer = document.getElementById(lastElementChild.id);
    attachControl({ container: lastContainer, state, dispatch });
  }
};
export const moveBackLastChart = (target: HTMLElement,
  firstElementChild,
  state: InitialState, dispatch: Function): void => {
  const headerFooter = target.classList.contains(ng.headerFooterClass);
  if (headerFooter) return;
  // const nextPage = ng.getNextPage(target.id);
  // if (!nextPage) return;
  const container = firstElementChild as HTMLElement;
  if (!container) return;
  if (!container.classList.contains(ng.chartClass)) {
    return;
  }
  const computedHeight = window.getComputedStyle(container).height;
  const containerHeight = parseFloat(computedHeight);
  const flexBox = target.parentElement;
  if (!flexBox) return;
  const flexHeight = parseFloat(window.getComputedStyle(flexBox).height);
  // if not enough space to take the transferring line, return
  if (flexBox && flexHeight - target.offsetHeight < containerHeight) {
    return;
  }
  const clone = container.cloneNode(true) as HTMLElement;
  target.insertAdjacentElement('beforeend', clone);
  container.remove();
  const refContainer = document.getElementById(clone.id);
  if (refContainer) {
    const chart = JSON.parse(refContainer.dataset.chart || '');
    const data = { ...chart };
    addChart({
      data, refContainer, newInsertion: false, backspacing: true, state, dispatch,
    });
  }
};

export const moveBackLastAny = (target: HTMLElement,
  firstElementChild,
  state: InitialState, dispatch: Function): void => {
  const headerFooter = target.classList.contains(ng.headerFooterClass);
  if (headerFooter) return;
  const container = firstElementChild as HTMLElement;
  if (!container) return;
  const computedHeight = window.getComputedStyle(container).height;
  const containerHeight = parseFloat(computedHeight);
  const flexBox = target.parentElement;
  if (!flexBox) return;
  const flexHeight = parseFloat(window.getComputedStyle(flexBox).height);
  // if not enough space to take the transferring line, return
  if (flexBox && flexHeight - target.offsetHeight < containerHeight) {
    return;
  }
  const clone = container.cloneNode(true) as HTMLElement;
  target.insertAdjacentElement('beforeend', clone);
  container.remove();
};
