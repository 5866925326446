import React from 'react';
import ListAlt from '@material-ui/icons/ListAlt';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import reEnter from '../../helpers/reFocus';
import addList from '../../helpers/addList';

const useStyles = makeStyles(() => ({
  root: {
    // minWidth: '100%',
    // padding: theme.spacing(-11),

  },
  dropdown: {
    position: 'absolute',
    display: 'inline-block',
    zIndex: 3,
    top: 15,
  },
  caret: {
    // flexGrow: 1,
    // paddingLeft: -4,
  },
  item: {
    minWidth: 200,
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

export default (props): JSX.Element => {
  const classes = useStyles();
  const { state } = props;
  const { menuFontFamily } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = (type, klass): void => {
    addList(type, klass);
    setOpen(false);
  };

  const handleToggle = () => {
    reEnter();
    setOpen(!open);
  };
  const handleMouseLeave = () => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper
        className={classes.dropdown}
        style={open ? {
          display: 'inline-block',
          maxHeight: 400,
          width: 80,
          maxWidth: 80,
          overflow: 'scroll',
        } : { display: 'none' }}
      >
        <MenuList id="split-button-menu">
          <MenuItem
            onClick={(): void => handleClick('ul', 'inherit')}
            value="inherit"
            key="inherit"
              // id={size}
            className={classes.item}
          >
            {'\u2022'}
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick('ul', 'square')}
            value="square"
            key="square"
              // id={size}
            className={classes.item}
          >
            {'\u25a1'}
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick('ol', 'decimal')}
            value="decimal"
            key="decimal"
              // id={size}
            className={classes.item}
          >
              1 2 3
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick('ol', 'upper-roman')}
            value="upperroman"
            key="upperroman"
              // id={size}
            className={classes.item}
          >
              I II III
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick('ul', 'ethiopic')}
            value="ethiopic"
            key="ethiopic"
              // id={size}
            className={classes.item}
          >
            <span style={{ fontFamily: menuFontFamily }}>ሀለሐመ</span>
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick('ul', 'lower-alpha')}
            value="loweralpha"
            key="loweralpha"
              // id={size}
            className={classes.item}
          >
            <span style={{ fontFamily: menuFontFamily }}>abc</span>
          </MenuItem>
          <MenuItem
            onClick={(): void => handleClick('ul', 'upper-alpha')}
            value="upper-alpha"
            key="upper-alpha"
              // id={size}
            className={classes.item}
          >
            <span style={{ fontFamily: menuFontFamily }}>ABC</span>
          </MenuItem>
        </MenuList>
      </Paper>
      <span ref={anchorRef} onClick={handleToggle} role="presentation" className={classes.icon}>
        <ListAlt />
      </span>
    </div>
  );
};
