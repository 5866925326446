import React from 'react';
import * as idier from '../nameGenerator';

export default (props) => {
  const { event } = props;
//   const { target } = event;
//   const headerFooter = target.classList.contains('derassi-header-footer');
//   if (headerFooter) {
//     const firstPage = idier.getIdFromString(target.id) === 0;
//     if (firstPage) {
//     }
//   }
};
