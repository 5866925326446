import uniqid from 'uniqid';
import { paragraphClass, containerClass } from '../../nameGenerator';
import { currentFont } from '../changeHistory';
// import { currentSize, currentFontFamily } from '../changeHistory';
/**
 * Creates new paragraph and asign with new id
 */
export default (state?): HTMLDivElement => {
  const div = document.createElement('div');
  div.classList.add(paragraphClass, containerClass);
  div.id = `derassi-paragraph-${uniqid()}`;
  div.style.textAlign = 'left';
  div.style.wordWrap = 'break-word';
  // div.style.fontFamily = currentFont.family || 'Mulat Abay';
  // div.style.fontSize = `${currentFont.size}pt` || '12pt';
  div.append(document.createElement('br'));
  // div.style.height = 'auto';
  div.style.minHeight = '20px';
  div.style.minWidth = '200px';
  return div;
};
