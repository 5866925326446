import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {
  FormGroup, DialogContentText,
  TextField, ListItem, ListItemIcon, ListItemText, Grid,
} from '@material-ui/core';
import {
  OpenInBrowser,
} from '@material-ui/icons';
import _ from 'lodash';
import Text from '@material-ui/icons/TextFormat';
import uniqid from 'uniqid';
import { translateWords } from '../../../translate';
import onKeyDown from '../../../eventHandlers/onKeyDown';
import addChart from './addChart';
import { textParent, currentFont } from '../../../eventHandlers/changeHistory';
import {
  dotContainerClass, chartClass, containerClass, unformattedClass, moveableClass,
} from '../../../nameGenerator';
import addTable from './addTable';
import { stateNames } from '../../..';
import { replaceCaret } from '../../../eventHandlers/helpers/moveFocus';
import attachControl, { addControl } from './attachControl';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    fontFamily: props => props.menuFontFamily,
  },
  find: {
    fontFamily: props => props.menuFontFamily,
    right: 2,
  },
  input: {
    minWidth: 400,
    fontFamily: props => props.menuFontFamily,
  },
}));

const PaperComponent = (props): JSX.Element => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);


export default (props): JSX.Element => {
  const {
    setMenuOpen, name, state, dispatch, closeSubMenu,
  } = props;
  const { menuFontFamily, lang } = state as InitialState;


  const handleClickOpen = (event): void => {
    event.stopPropagation();
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editText' });
    const { parent } = textParent;
    if (parent) {
      const container = parent.closest(dotContainerClass);
      if (container) {
        const textEditor = document.createElement('div');
        textEditor.classList.add('derassi-text', containerClass, moveableClass);
        textEditor.id = `derassi-text-${uniqid()}`;
        textEditor.innerHTML = 'X';
        textEditor.style.padding = '5px';
        // textEditor.style.minHeight = '10px';
        textEditor.style.fontSize = '30px';
        textEditor.style.fontFamily = currentFont.family;
        container.insertAdjacentElement('afterend', textEditor);
        replaceCaret(textEditor);
        textEditor.scrollIntoView({ inline: 'center', block: 'center' });
        addControl({ container: textEditor, paragraph: true });
        attachControl({ container: textEditor, paragraph: true });
      }
    }
    setMenuOpen(false);
  };


  return (
    <div>
      <ListItem
        dense
        button
        onClick={handleClickOpen}
        onMouseEnter={closeSubMenu}
      >
        <ListItemIcon><Text /></ListItemIcon>
        <ListItemText>
          <div style={{ fontFamily: menuFontFamily }}>
            { lang <= 2 ? name[lang] : name[lang]}
          </div>
        </ListItemText>
      </ListItem>
    </div>
  );
};
