import viewMenu from '../components/editor/headings/upperMenus/viewMenu';

export default (state, orders, pickerCallback, type = ''): any => {
  const {
    loading, isSignedIn, gapi,  menuFontFamily, lang,
  } = state as InitialState;
  const accessToken = localStorage.getItem('GAPI_ACCESS_TOKEN');
  const API_KEY = localStorage.getItem('GAPI_API_KEY');
  if (loading) return { loading };
  if (!accessToken) return { error: { message: 'not authorized', location: 'service/gDrive' } };
  if (!isSignedIn) return { error: { message: 'not signed in', location: 'service/gDrive' } };
  if (!gapi) return { error: { message: 'gapi not loaded', location: 'service/gDrive' } };
  const token = accessToken;
  const google = gapi.picker.api;
  const selectDocs = type === 'selectDocs';
  try {
    if (google && token) {
      const file = new google.View(google.ViewId.DOCS);
      const upload = new google.DocsUploadView();
      const photos = new google.View(google.ViewId.DOCS_IMAGES);
      const imageDoc = new google.View(google.ViewId.DOCS);
      const search = new google.View(google.ViewId.IMAGE_SEARCH);
      if (selectDocs) {
        file.setQuery('ለጉግል ድራይቭ ርዕስ ሊፈለግ የሚችል');
      } else {
        upload.setMimeTypes('image/png,image/jpeg,image/jpg');
        imageDoc.setMimeTypes('image/png,image/jpeg,image/jpg');
        photos.setMimeTypes('image/png,image/jpeg,image/jpg');
        search.setMimeTypes('image/png,image/jpeg,image/jpg');
      }
      // view.setMimeTypes('image/png,image/jpeg,image/jpg');
      // upload.setMimeTypes('image/png,image/jpeg,image/jpg');
      const group = [upload, search, imageDoc, photos];
      const picker = new google.PickerBuilder()
        .setAppId(API_KEY)
        .setOAuthToken(token)
        .setDeveloperKey('AIzaSyC9EY4t4vCiJQKkUwLcT5gEeaWqaesl3YU');
      if (selectDocs) {
        picker.addView(file);
      } else {
        orders.forEach((index) => {
          picker.addView(group[index]);
        });
      }
      if (lang <= 2) {
        picker.setLocale('am');
      }
      picker.setCallback(pickerCallback);
      const pickerBuilt = picker.build();
      pickerBuilt.setVisible(true);
    }
  } catch (err) {
    return { error: { message: err.message, location: 'service/gDrive' } };
  }
};
