import paragraph from './paragraph';

export default (para, sel, focusNode, state) => {
  const newParagraph = paragraph(state);
  const range = document.createRange();
  range.setStart(focusNode, sel.focusOffset);
  const end = para.lastElementChild ? para.lastElementChild : focusNode;
  const endLen = para.lastElementChild
    ? para.lastElementChild.childNodes.length
    : sel.focusOffset;
  range.setEnd(end, endLen || 0);
  const extract = range.extractContents();
  const span = document.createElement('span');
  span.append(extract);
  // extract should include br so over write br here.
  newParagraph.innerHTML = span.innerHTML;
  return newParagraph;
};
