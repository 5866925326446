import React from 'react';
import uniqid from 'uniqid';
import {
  ListItem, ListItemIcon, ListItemText, SvgIcon,
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import _ from 'lodash';
import drive from '../../../images/Drive.png';
import photos from '../../../images/Photos.png';


export const UpperCased = (props: {
  word: string; state: InitialState; useFont?: string;
 }): JSX.Element => {
  const { word, state, useFont } = props;
  const { menuFontFamily } = state as InitialState;
  const menuFontFamilyCaped = `${useFont || menuFontFamily} Caped`;
  return (
    <span style={{ fontFamily: menuFontFamilyCaped }}>
      {word}
    </span>
  );
};
export const Capitalized = (props: {
  word: string; state: InitialState; useFont?: string;
}): JSX.Element => {
  const {
    word, state, useFont,
  } = props;
  const { menuFontFamily } = state;
  const menuFontFamilyCaped = `${menuFontFamily} Caped`;
  if (!word) return (<></>);
  return (
    <span>
      <span style={{ fontFamily: useFont ? `${useFont} Caped` : menuFontFamilyCaped }}>
        {word[0]}
      </span>
      <span style={{ fontFamily: useFont || menuFontFamily }}>
        {word.slice(1)}
      </span>
    </span>
  );
};
export const Titlized = (props: {
  state: InitialState; sentence: string; useFont?: string;
}): JSX.Element => {
  const {
    state, sentence, useFont,
  } = props;
  if (!sentence) return (<></>);
  return (
    <span>
      {sentence.split(' ').map(word => (
        <Capitalized
          state={state}
          word={`${word} `}
          key={uniqid()}
          useFont={useFont}
        />
      ))}
    </span>
  );
};

export const Sentencized = (props) => {
  const {
    state, para, useFont,
  } = props;
  const { menuFontFamily } = state;
  if (!para) return (<></>);
  return (
    <span>
      {
        para.split(' ').map((word) => {
          if (_.startsWith(word, '/')) {
            return (<Capitalized key={uniqid()} useFont={useFont} word={`${_.trim(word, '/')} `} state={state} />);
          }
          return (
            <span key={uniqid()} style={useFont ? { fontFamily: useFont } : { fontFamily: menuFontFamily }}>
              {word}
              {' '}
            </span>
          );
        })
       // (<Capitalized word={word} state={state} />)
      }
    </span>
  );
};
