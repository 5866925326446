import React from 'react';
import ReactDOM from 'react-dom';
import uniqid from 'uniqid';
import Moveable from 'moveable';
import { image } from 'html2canvas/dist/types/css/types/image';
import insertImageContainer from './insertMovableContainer';
import * as ng from '../../../nameGenerator';
import attachControl from './attachControl';
import save from '../../../services/save';
import { onAddingChange } from '../../../eventHandlers/onChange';
import { stateNames } from '../../../index';
import { moveCaretInside } from '../../../eventHandlers/helpers/moveFocus';
import paragraph from '../../../eventHandlers/helpers/paragraph';
import getFocusPath from './getFocusPath';
import addToHistory from './addToHistory';
import { textParent, saveHistory, currentFont } from '../../../eventHandlers/changeHistory';
import { translateWords, fontFamilies } from '../../../translate';

export default (props: {
  data: any; refContainer?: HTMLElement;
  replacingContainer?: HTMLElement;
  state: InitialState; dispatch: Function;
}): void => {
  const {
    data, refContainer, replacingContainer, state, dispatch,
  } = props;
  const { bodyToggle } = state;
  const { src, name } = data;

  const img = new Image();
  img.src = src;
  try {
    dispatch({ type: stateNames.pageLoading, pageLoading: true });
    img.onload = () => {
      const { height, width } = img;

      const limit = 400;
      let newHeight = height;
      let newWidth = width;
      const someId = uniqid();
      const id = ng.generateId(someId, 'image'); // `derassi-${type}-${someId}`;
      const klass = ng.generateClass('image');
      const container = document.createElement('div');
      container.id = id;
      // container.contentEditable = 'false';
      container.classList.add(klass, ng.containerClass, ng.moveableClass);
      if (newHeight > limit || newWidth > limit) {
        const biggerHeight = newHeight >= newWidth;
        const aspectRatio = biggerHeight ? (width / height) : (height / width);
        if (biggerHeight) {
          newHeight = limit;
          newWidth = limit * aspectRatio;
        } else {
          newWidth = limit;
          newHeight = limit * aspectRatio;
        }
      }
      // if (biggerHeight) {
      //   // container.style.height = `${400}px`; // add 30 for caption
      //   if (height > limit) {
      //     newHeight = limit;
      //     // container.style.height = `${400}px`;
      //     const aspectRatio = width / height;
      //     newWidth = limit * aspectRatio;
      //     // img.height = 400;
      //     // img.width = newWidth;
      //     // container.style.width = `${newWidth}px`;
      //   }
      //   // else {
      //   //   container.style.height = `${height}px`;
      //   //   container.style.width = `${width}px`;
      //   // }
      // } else {
      //   // eslint-disable-next-line no-lonely-if
      //   if (width > limit) {
      //     // container.style.width = `${400}px`;
      //     const aspectRatio = height / width;
      //     newHeight = limit * aspectRatio;
      //     // img.width = 400;
      //     // img.height = newHeight;
      //     // container.style.height = `${newHeight}px`;
      //   }
      //   //  else {
      //   //   container.style.height = `${height}px`;
      //   //   container.style.width = `${width}px`;
      //   // }
      // }
      const imageContainer = document.createElement('div');
      imageContainer.classList.add('derassi-image-container');

      container.style.height = `${newHeight + 25}px`; // 25 room for captions
      container.style.width = `${newWidth}px`;
      container.style.breakInside = 'avoid';
      container.style.pageBreakInside = 'avoid';
      img.height = newHeight;
      img.width = newWidth;
      imageContainer.style.height = `${newHeight}px`;
      imageContainer.style.width = `${newWidth}px`;

      imageContainer.append(img);
      container.append(imageContainer);
      // container.style.maxWidth = `${600}px`;
      // container.style.paddingT = '5px';
      container.style.padding = '15px';
      // container.style.overflow = 'hidden';
      container.style.display = 'block';

      const caption = document.createElement('div');
      // caption.contentEditable = 'true';
      caption.classList.add('derassi-image-caption');
      caption.style.opacity = '0.7';
      caption.style.paddingBottom = '8px';
      caption.style.fontFamily = currentFont.family;
      caption.innerHTML = name;
      // caption.style.position = 'absolute';
      // caption.style.top = window.getComputedStyle(img).height;
      // bodyToggle ? translateWords.title[lang] : translateWords.title[lang];
      // addImage({ container, ...props });
      // container.append(img);

      container.append(caption);
      if (replacingContainer) {
        replacingContainer.replaceWith(container);
      } else if (refContainer) {
        refContainer.insertAdjacentElement('afterend', container);
      }
      dispatch({ type: stateNames.pageLoading, pageLoading: false });
      // save({ state, dispatch });
      // saveHistory();
    };
  } catch (err) {
    dispatch({ type: stateNames.pageLoading, pageLoading: false });
  }
};
// export default (props): void => {
//   const {
//     nextPage, prevPage, state, data,
//   } = props;
//   const { height, width } = data;
//   const { currentFocus } = state as InitialState;
//   const idiedContainer = document.getElementById(currentFocus.id);
//   let siblingContainer;
//   if (idiedContainer) {
//     siblingContainer = idiedContainer.closest(ng.dotContainerClass);
//   }
//   const size = {
//     minWidth: 50,
//     maxWidth: 500,
//     maxHeight: 500,
//     minHeight: 50,
//     width,
//     height,
//   };
//   const container = insertImageContainer({
//     type: 'image', nextPage, prevPage, siblingContainer, size,
//   });
//   if (container) {
//     addImage({ container, ...props });
//   }
// };
