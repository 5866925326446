
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Keyboard from '@material-ui/icons/Keyboard';
import Paper from '@material-ui/core/Paper';
import Chart from '@material-ui/icons/BarChartOutlined';
import Table from '@material-ui/icons/TableChartOutlined';
import List from '@material-ui/core/List';
import Image from '@material-ui/icons/Image';
import Upload from '@material-ui/icons/Publish';
import Search from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Capitalized } from '../../helpers/changeToUpper';
import ImageUrl from './imageUrl';
import translate, { translateWords } from '../../../../translate';
import handleDrivePicker from '../../helpers/beforeImageAdd';
import addTable from '../../helpers/addTable';
import addChart from '../../helpers/addChart';
import HeaderFooter from '../../helpers/editHeaderFooter';
import useStyles from './commonStyles';
import { stateNames } from '../../../..';
// import { stateNames } from '../../../App';

export default (props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch, history } = props;
  const { menuInited, lang, menuFontFamily } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const [imageSubOpen, setImageSubOpen] = React.useState(false);
  const { headerFooter } = state;
  const anchorRef = React.useRef(null);
  const headerRef = React.useRef(null);
  const footerRef = React.useRef(null);
  const handleToggle = (): void => {
    if (!open) {
      dispatch({ type: 'menuInited', menuInited: true });
    }
    setOpen(!open);
  };
  const handleMouseLeave = (): void => {
    if (open) {
      setOpen(false);
    }
  };
  const callDrivePicker = (e) => {
    // handleDrivePicker(e, state);
    setImageSubOpen(false);
  };
  const handleMouseEnter = (): void => {
    if (!open) {
      if (menuInited) {
        setOpen(true);
      }
    }
  };
  const goToKeyboard = () => {
    history.push('/keyboard');
  };

  React.useEffect(() => () => {
    setImageSubOpen(false);
  }, []);

  return (
    <div onMouseLeave={handleMouseLeave}>
      {/* disablePortal */}
      <Paper elevation={10} className={classes.dropdown} style={open ? { display: 'inline-block' } : { display: 'none' }}>
        <List className={classes.list} onClick={(): void => dispatch({ type: 'menuInited', menuInited: false })}>
          <Divider />
          <ListItem
            dense
            id="help"
            key="help"
            button
            onClick={() => goToKeyboard()}
          >
            <ListItemIcon><Keyboard /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.keyboard[lang] }
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            dense
            id="help-1"
            key="help-1"
            button
            disabled
            onClick={() => setOpen(false)}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.howTo[lang] }
                { `(${translateWords.commingSoon[lang]})` }
              </div>
            </ListItemText>
          </ListItem>
          <a style={{ textDecoration: 'none', color: 'inherit' }} href="mailto:mulat.derassi@gmail.com?subject='About Derassi/ስለ ደራሲ'">
            <ListItem
              dense
              button
            >
              <ListItemText>
                <div style={{ fontFamily: menuFontFamily }}>
                  { translateWords.contactUs[lang] }
                </div>
              </ListItemText>
            </ListItem>
          </a>
          <ListItem
            dense
            button
            id="help-2"
            key="help-2"
            onClick={() => setOpen(false)}
          >
            <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.youtube.com/channel/UCAzphuUVjZEhZd62ckhIq3Q">
              <ListItemText>
                <div style={{ fontFamily: menuFontFamily }}>
                  { translateWords.videos[lang] }
                </div>
              </ListItemText>
            </a>
          </ListItem>
        </List>
      </Paper>
      <IconButton
        className={classes.icon}
        onMouseEnter={handleMouseEnter}
        ref={anchorRef}
        onClick={handleToggle}
      >
        {lang <= 2 ? (
          <Capitalized
            word={translateWords.help[lang]}
            {...props}
          />
        ) : _.capitalize(translateWords.help[lang])}
      </IconButton>
    </div>
  );
};
