
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import List from '@material-ui/core/List';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import {
  ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import _ from 'lodash';
import { FormatLineSpacing, AspectRatio } from '@material-ui/icons';
import { Capitalized } from '../../helpers/changeToUpper';
import useStyles from './commonStyles';
import { stateNames } from '../../../../index';
import reEnter from '../../helpers/reFocus';
import { translateWords, fontsForMenu } from '../../../../translate';
import { dotParagraphClass } from '../../../../nameGenerator';
import { textParent } from '../../../../eventHandlers/changeHistory';
import { addControl } from '../../helpers/attachControl';

export default (props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch } = props;
  const {
    menuFontFamily, menuInited, bodyZoom, lang,
  } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const [subOpen, setSubOpen] = React.useState(false);
  const [top, setTop] = React.useState(40);
  const anchorRef = React.useRef(null);


  const handleToggle = (): void => {
    reEnter();
    if (!open) {
      dispatch({ type: 'menuInited', menuInited: true });
    }
    setOpen(!open);
  };
  const handleMouseLeave = (): void => {
    if (open) {
      setOpen(false);
    }
  };
  const handleZoomOut = (): void => {
    reEnter();
    dispatch({
      type: stateNames.bodyZoom,
      bodyZoom: bodyZoom < 0.5 ? 0.5 : bodyZoom - 0.1,
    });
  };
  const handleZoomIn = (): void => {
    reEnter();
    dispatch({
      type: stateNames.bodyZoom,
      bodyZoom: bodyZoom >= 2 ? 2 : bodyZoom + 0.1,
    });
  };
  const handleZoomOne = (): void => {
    reEnter();
    dispatch({
      type: stateNames.bodyZoom,
      bodyZoom: 1,
    });
  };
  const handleMouseEnter = (): void => {
    if (!open) {
      if (menuInited) {
        setOpen(true);
      }
    }
  };
  const handleSpacing = (): void => {
    const allParas = document.querySelectorAll(dotParagraphClass);
    Array.from(allParas).forEach((para) => {
      const { lineHeight } = window.getComputedStyle(para);
      const p = para as HTMLElement;
      if (lineHeight === 'normal') {
        p.style.lineHeight = '1.8';
      } else {
        p.style.lineHeight = 'normal';
      }
    });
  };

  const handleClick = (family): void => {
    reEnter();
    dispatch({
      type: stateNames.menuFontFamily,
      menuFontFamily: family,
    });
    setOpen(false);
    setSubOpen(false);
  };
  const handleSebMenu = (e) => {
    const { offsetTop } = (e.target as HTMLElement);
    setTop(offsetTop);
    setSubOpen(!subOpen);
  };
  // const handleEdit = () => {
  //   setOpen(false);
  //   const { parent } = textParent;
  //   if (parent) {
  //     const para = parent.closest(dotParagraphClass);
  //     if (para) {
  //       para.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' });
  //       const container = para as HTMLElement;
  //       container.style.outline = 'dotted 3px lightblue';
  //       para.addEventListener('blur', () => { container.style.outline = 'initial'; });
  //       // addControl({ container, state, dispatch });
  //       currentParagaraph.element = container;
  //       dispatch({ type: stateNames.showThirdMenu, showThirdMenu: 'editParagraph' });
  //     }
  //   }
  // };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper elevation={10} className={classes.dropdown} style={open ? { display: 'inline-block' } : { display: 'none' }}>
        <List className={classes.list} onClick={(): void => dispatch({ type: 'menuInited', menuInited: false })}>
          <Divider />
          <ListItem
            id="ZoomIn"
            key="ZoomIn"
            dense
            button
            onClick={handleZoomIn}
          >
            <ListItemIcon><ZoomIn /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                {translateWords.zoomIn[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="ZoomOut"
            key="ZoomOut"
            dense
            button
            onClick={handleZoomOut}
            onMouseEnter={() => setSubOpen(false)}
          >
            <ListItemIcon><ZoomOut /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.zoomOut[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <ListItem
            id="Zoom1"
            key="Zoom1"
            dense
            button
            onClick={handleZoomOne}
            onMouseEnter={() => setSubOpen(false)}
          >
            {/* <ListItemIcon></ListItemIcon> */}
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.reset[lang]}
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            id="spacing"
            key="spacing"
            dense
            button
            onClick={handleSpacing}
            onMouseEnter={() => setSubOpen(false)}
          >
            <ListItemIcon><FormatLineSpacing /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.spacing[lang]}
              </div>
            </ListItemText>
          </ListItem>
          {/* <Divider /> */}
          {/* <ListItem
            id="edit"
            key="edit"
            dense
            button
            onClick={handleEdit}
            onMouseEnter={() => setSubOpen(false)}
          >
            <ListItemIcon><AspectRatio /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.edit[lang]}
                {' '}
                { translateWords.paragraph[lang]}
              </div>
            </ListItemText>
          </ListItem> */}
          <Divider />
          <ListItem
            id="fonts"
            key="fonts"
            dense
            button
            onClick={(e): void => handleSebMenu(e)}
            onMouseEnter={(e): void => handleSebMenu(e)}
          >
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.title[lang]}
              </div>
            </ListItemText>
            <ChevronRight />
          </ListItem>
          <Paper
            className={classes.nested}
            style={subOpen ? { display: 'inline-block', top, left: 120 } : { display: 'none' }}
          >
            <List onMouseEnter={() => setSubOpen(true)}>
              {Object.entries(fontsForMenu).map((font, index) => {
                const [english, abesha] = font;
                const capped = (
                  <Capitalized
                    word={abesha}
                    state={state}
                    useFont={english}
                  />
                );
                return (
                  <ListItem
                    dense
                    button
                    onClick={() => handleClick(english)}
                    style={{ fontFamily: english, minWidth: 150, overflow: 'elipses' }}
                    key={english}
                    id={english}
                  >
                    {lang <= 2 ? capped : english}
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </List>
      </Paper>
      <IconButton
        onMouseEnter={handleMouseEnter}
        ref={anchorRef}
        onClick={handleToggle}
        className={classes.icon}
      >
        {lang <= 2 ? (
          <Capitalized
            word={translateWords.view[lang]}
            {...props}
          />
        ) : translateWords.view[lang]}
      </IconButton>
    </div>
  );
};
