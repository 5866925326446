/**
 * page is a printable page of give width and height, (default A4 size)
 * It includes header and footer
 * @module pageContainer
 */
import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import EditSection from './editor';
import * as ng from '../../../nameGenerator';
import onMouseDown from '../../../eventHandlers/onMouseDown';
import onMouseLeave from '../../../eventHandlers/onMouseLeave';
import onMouseEnter from '../../../eventHandlers/onMouseEnter';
import onMouseUp from '../../../eventHandlers/onMouseUp';
import { stateNames } from '../../..';
import { derassiHeader, derassiFooter } from '../../../eventHandlers/changeHistory';

const PageNumHeader = (props) => {
  const { state, pageNum } = props;
  // const { derassiHeader } = state as InitialState;
  const { showPageNumber, pageNumberSide, pageNumberLabel } = derassiHeader;
  const ref = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.visibility = showPageNumber ? 'visible' : 'hidden';
      ref.current.style.float = pageNumberSide === 'right' ? 'right' : 'left';
      ref.current.innerHTML = `${pageNumberLabel} ${pageNum + 1}`;
    }
  }, []);
  // const { pageLabel, showPageNum, pageNumSide } = der
  return (
    <span
      ref={ref}
      style={{
        visibility: 'hidden',
        float: 'right',
      }}
      className="derassi-header-pagenum"
    >
      {pageNum + 1}
    </span>
  );
};


const PageNumFooter = (props) => {
  const { state, pageNum } = props;
  // const { derassiHeader } = state as InitialState;
  const { showPageNumber, pageNumberSide, pageNumberLabel } = derassiFooter;
  const ref = React.useRef<HTMLSpanElement>(null);
  React.useEffect(() => {
    if (ref && ref.current) {
      ref.current.style.visibility = showPageNumber ? 'visible' : 'hidden';
      ref.current.style.float = pageNumberSide === 'right' ? 'right' : 'left';
      ref.current.innerHTML = `${pageNumberLabel} ${pageNum + 1}`;
    }
  }, []);
  return (
    <span
      style={{
        visibility: 'hidden',
        float: 'right',
      }}
      className="derassi-footer-pagenum"
    >
      {pageNum + 1}
    </span>
  );
};

interface PageShape {
  width: number;
  height: number;
  padding: number;
  paddingLeft: number;
  paddingRight: number;
  pageSidPading: number;
  edgeSidePading: number;
  minHeaderHeight: number;
}
const useStyles = makeStyles(theme => ({
  wrapper: (props): any => ({
    width: (props as PageShape).width,
    height: (props as PageShape).height,
    minHeight: (props as PageShape).height,
    maxHeight: (props as PageShape).height,
    backgroundColor: theme.palette.background.paper,
  }),
  header: (props): any => ({
    maxWidth: (props as PageShape).width
    - (props as PageShape).paddingLeft
    - (props as PageShape).paddingRight,
    paddingLeft: (props as PageShape).padding,
    paddingRight: (props as PageShape).padding,
    paddingTop: (props as PageShape).edgeSidePading,
    paddingBottom: (props as PageShape).pageSidPading,
    maxHeight: 0.3 * (props as PageShape).height,
    minHeight: (props as PageShape).minHeaderHeight,
    overFlow: 'hidden',
    // backgroundColor: '#008B8B',
  }),
  body: (props): any => ({
    maxWidth: (props as PageShape).width
    - (props as PageShape).paddingLeft
    - (props as PageShape).paddingRight,
    paddingLeft: (props as PageShape).padding,
    paddingRight: (props as PageShape).padding,
    // maxHeight: (props as PageShape).height
    // - 2 * ((props as PageShape).pageSidPading
    // + (props as PageShape).edgeSidePading
    // + (props as PageShape).minHeaderHeight),
    // backgroundColor: '#DEB887',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 50,
  }),
  footer: (props): any => ({
    maxWidth: (props as PageShape).width
    - (props as PageShape).paddingLeft
    - (props as PageShape).paddingRight,
    paddingLeft: (props as PageShape).padding,
    paddingRight: (props as PageShape).padding,
    paddingBottom: (props as PageShape).edgeSidePading,
    paddingTop: (props as PageShape).pageSidPading,
    maxHeight: 0.3 * (props as PageShape).height,
    minHeight: (props as PageShape).minHeaderHeight,
    overFlow: 'hidden',
    // backgroundColor: '#008B8B',
  }),
}));
/**
 * React Page Object
 * @param {Object} props
 * @returns {React.Component} - the page Object
 */
export default (props: any): JSX.Element => {
  const {
    id, state, dispatch, page,
  } = props;
  const pageShape: PageShape = {
    width: 794,
    height: 1123,
    padding: 93,
    paddingLeft: 93,
    paddingRight: 93,
    pageSidPading: 20,
    edgeSidePading: 50,
    minHeaderHeight: 20,
  };

  const classes = useStyles(pageShape);
  const pageNum: number = ng.getIdFromString(id);
  const headerId: string = ng.getHeaderIdForPage(id);
  const footerId: string = ng.getFooterIdForPage(id); // `derassi-footer-${pageNum}`;
  const bodyId: string = ng.getBodyIdForPage(id);
  const bodyKlass = `${ng.bodyClass} ${ng.contentEditableClass}`; // 'derassi-page derassi-content-editable';
  const headerKlass = `${ng.headerFooterClass} ${ng.headerClass} ${ng.contentEditableClass}`;// 'derassi-header-footer derassi-content-editable';
  const footerKlass = `${ng.headerFooterClass} ${ng.footerClass} ${ng.contentEditableClass}`;// 'derassi-header-footer derassi-content-editable';
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      id={id}
      role="presentation"
      className={`${ng.pageClass}`}
      onMouseDown={(e): void => onMouseDown({ event: e, ...props })}
      onMouseLeave={(e): void => onMouseLeave({ event: e, ...props })}
      onMouseEnter={(e): void => onMouseEnter({ event: e, ...props })}
      onMouseUp={(e): void => onMouseUp({ event: e, ...props })}
    >
      <Box
        display="flex"
        className={classes.wrapper}
        flexDirection="column"
        alignItems="auto"
        mb={1}
      >
        <Box key={1} className={classes.header} order={1} id={`derassi-header-box-${pageNum}`}>
          <PageNumHeader state={state} pageNum={pageNum} />
          <br />
          <EditSection
            klass={headerKlass}
            id={headerId}
            state={state}
            dispatch={dispatch}
            innerHTML={page.headerHTML}
          />
        </Box>
        <Box key={2} order={2} flexGrow={1} className={classes.body} id={`derassi-body-box-${pageNum}`}>
          <EditSection
            klass={bodyKlass}
            id={bodyId}
            state={state}
            dispatch={dispatch}
            innerHTML={page.bodyHTML}
          />
        </Box>
        <Box key={3} order={3} className={classes.footer} id={`derassi-footer-box-${pageNum}`}>
          <EditSection
            id={footerId}
            klass={footerKlass}
            state={state}
            dispatch={dispatch}
            innerHTML={page.footerHTML}
          />
          <br />
          <PageNumFooter state={state} pageNum={pageNum} />
        </Box>
      </Box>
    </div>
  );
};
