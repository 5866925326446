import React from 'react';
import { CircularProgress, TextField, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { translateWords } from '../../../translate';
import onKeyDown from '../../../eventHandlers/onKeyDown';
import { stateNames } from '../../../index';
import { updateMeta } from '../../../services/save';
import { currentFile } from '../../../eventHandlers/changeHistory';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '30px',
    padding: '10px',
    borderRadius: '5%',
    '&:hover': {
      outline: '2px solid #ccc',
    },
  },
}));

export default (props) => {
  const { state, dispatch } = props;
  const {
    currentTitle, menuFontFamily, loading, saving, isSignedIn, lang,
  } = state as InitialState;
  const labelRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (labelRef && labelRef.current) {
      labelRef.current.style.fontFamily = menuFontFamily;
    }
  }, []);

  React.useEffect(() => {
    if (labelRef && labelRef.current) {
      labelRef.current.value = currentTitle;
    }
  }, [currentTitle]);

  const updateName = (name) => {
    // currentFile.title = name;
    dispatch({ type: stateNames.currentTitle, currentTitle: name });
    updateMeta({ state, dispatch });
  };
  const handleKeyDown = (event): void => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      event.preventDefault();
      updateName(event.target.value);
      // dispatch({ type: stateNames.currentTitle, currentTitle: event.target.value });
    }
    onKeyDown({ event, ...props });
  };
  const handleBlur = (event) => {
    updateName(event.target.value);
    // dispatch({ type: stateNames.currentTitle, currentTitle: event.target.value });
  };

  return (
    <div>
      <TextField
        inputRef={labelRef}
        onKeyDown={(e): void => handleKeyDown(e)}
        onBlur={(e): void => handleBlur(e)}
        margin="none"
        defaultValue={currentFile.title}
      // variant="outlined"
        size="small"
      />
      <div>
        {
        saving || loading ? (<CircularProgress size={10} />)
          : (
            <span style={{ fontFamily: menuFontFamily, fontSize: 'small', color: `${isSignedIn ? 'grey' : 'tomato'}` }}>
              { isSignedIn
                ? `\u2713 ${translateWords.documentSaved[lang]}`
                : `${translateWords.loginToSaveWork[lang]}`
               }
            </span>
          )}
      </div>
    </div>
  );
};
