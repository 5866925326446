import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import FileMenu from './fileMenu';
import EditMenu from './editMenu';
import ViewMenu from './viewMenu';
import InsertMenu from './insertMenu';
import HelpMenu from './helpMenu';
import reEnter from '../../helpers/reFocus';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 5,
    paddingLeft: 50,
    // backgroundColor: 'red',
  },
}));

export default (props) => {
  const classes = useStyles();
  // const { state } = props;
  // React.useEffect(() => {
  //   console.log("....started first menu..", classes.root)
  // });
  return (
    <Grid container direction="row" className={classes.root}>
      <FileMenu id="file" key="file" {...props} />
      <EditMenu id="edit" key="edit" {...props} />
      <InsertMenu id="insert" key="insert" {...props} />
      <ViewMenu {...props} />
      {/* <EditPara {...props} /> */}
      {/* <EditTable id="table" key="table" {...props} /> */}
      {/* <EditImage id="image" key="image" {...props} /> */}
      <HelpMenu id="help" key="help" {...props} />
    </Grid>
  );
};
