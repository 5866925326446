import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import uniqid from 'uniqid';
import formatDoc from '../../helpers/formatting';
import reEnter from '../../helpers/reFocus';
import { stateNames } from '../../../..';
import { textParent, currentFont } from '../../../../eventHandlers/changeHistory';
import { moveCaretInside } from '../../../../eventHandlers/helpers/moveFocus';

const useStyles = makeStyles(() => ({
  root: {
    // minWidth: '100%',
    // padding: theme.spacing(-11),

  },
  dropdown: {
    position: 'absolute',
    display: 'inline-block',
    zIndex: 3,
    top: 35,
  },
  caret: {
    // flexGrow: 1,
    // paddingLeft: -4,
  },
  item: {
    minWidth: 200,
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

export default (props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch } = props;
  const { fontChange } = state;
  // const { fontSize } = state as InitialState;
  const [displaySize, setDisplaySize] = React.useState(currentFont.size || 12);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  React.useEffect(() => {
    setDisplaySize(currentFont.size);
  }, [fontChange]);
  const handleClick = (event): void => {
    const selected = document.querySelector('.derassi-selected');
    setDisplaySize(Number(event.target.value));
    currentFont.size = Number(event.target.value);
    //  dispatch({ type: stateNames.fontSize, fontSize: Number(event.target.value) });
    const { parent, focusOffset } = textParent;
    if (selected) {
      // dispatch({ type: stateNames.fontSize, fontSize: Number(event.target.value) });
      formatDoc({ size: Number(event.target.value) });
      setOpen(false);
    } else if (parent) {
      const textNode = parent.firstChild as Text;
      const span = document.createElement('span');
      span.style.fontFamily = event.currentTarget.id;
      span.id = `derassi-focus-${uniqid()}`;
      span.classList.add(`derassi-font-size-${Number(event.target.value)}`);
      if (textNode && textNode.nodeType === Node.TEXT_NODE && textNode.length > 0) {
        const split = (textNode as Text).splitText(focusOffset);
        parent.insertBefore(span, split);
      } else {
        parent.insertAdjacentElement('afterend', span);
      }
      textParent.parent = span;
      textParent.focusOffset = 0;
      moveCaretInside(span as HTMLElement);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    reEnter();
    setOpen(!open);
  };
  const handleMouseLeave = () => {
    if (open) {
      setOpen(false);
    }
  };

  const sizes = [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96];
  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper
        className={classes.dropdown}
        style={open ? {
          display: 'inline-block',
          maxHeight: 400,
          width: 80,
          maxWidth: 80,
          overflow: 'scroll',
        } : { display: 'none' }}
      >

        <MenuList id="split-button-menu">
          {sizes.map(size => (
            <MenuItem
              onClick={(e): void => handleClick(e)}
              value={size}
              key={size}
              // id={size}
              className={classes.item}
            >
              {size}
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
      <IconButton ref={anchorRef} onClick={handleToggle} size="small" className={classes.icon}>
        {displaySize}
        <ArrowDropDownIcon className={classes.caret} />
      </IconButton>
    </div>
  );
};
