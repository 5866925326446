/**
 * Handles key pressed inside a paragraph,
 * also handles if key pressed with capsLock on
 * @module onKeyPress
 */

import _ from 'lodash';
import keyboardMap from '../../keyboardMap';
import { replaceCaret, moveCaretInside } from './moveFocus';
import { saveHistory, currentFont, bodyToggle } from '../changeHistory';
import { dotParagraphClass, containerClass } from '../../nameGenerator';


/**
 * RegEx to check if @letter is defined as Consonant for Amharic
 * @param {String} letter - the letter typed by user
 */
const isConsonant = (letter): boolean => {
  const c = /[a-z]/;
  return letter.match(c) !== null;
};
const isNumber = (letter): boolean => {
  const c = /[0-9]/;
  return letter.match(c) !== null;
};

/**
 * Focus at the next string after the foucs node, updating focus position
 * @param {Node} focusNode - the current selection foucsNode
 * @param {Number} offset - the reqiured offset to focus on
 */
const adjustFocus = (sel, parent, offset) => {
  const range = document.createRange();
  range.setStart(parent, offset);
  range.setEnd(parent, offset);
  range.collapse(true);
  sel.removeAllRanges();
  sel.addRange(range);
};

const getSpan = (font, size, klass) => {
  const span = document.createElement('span') as HTMLSpanElement;
  span.style.fontFamily = font;
  span.style.fontSize = `${size}pt`;
  if (klass) span.classList.add(klass);
  return span;
};

/**
 * inserts pressed key into page and paragraphs
 * @param {Element} target  - the current page user is typing
 * @param {boolean} isCaps  - is Cap key is press or not
 * @param {String} key - the typed letter or key on keyboard
 * @param {Object} state - React reducer state
 */
const insertLetter = ({
  isCaps, letter, state,
}): void => {
  if (!letter) return;

  // const { fontFamily, fontFamilyCaped } = state as InitialState;
  // const fontFamilyCaped = `${fontFamily} Caped`;
  // const letter = bodyToggle ? keyboardMap[key] || key : key;
  // const text = document.createTextNode(letter);
  const sel = window.getSelection();
  if (!sel) return;
  const {
    focusNode, focusOffset,
  } = sel;
  if (!focusNode) return;
  const parent = (focusNode.nodeType === Node.TEXT_NODE
    ? focusNode.parentElement : focusNode) as HTMLElement;
  if (!parent) return;

  // const textEditingContainer = parent.closest('.derassi-text');
  // if (textEditingContainer) {
  //   const { textContent } = focusNode;
  //   if (textContent) {
  //     focusNode.textContent = `${textContent.slice(0, focusOffset)}${letter}${textContent.slice(focusOffset)}`;
  //     adjustFocus(sel, focusNode, focusOffset + 1);
  //   } else {
  //     textEditingContainer.insertAdjacentText('afterbegin', letter);
  //     replaceCaret(textEditingContainer);
  //   }
  //   return;
  // }

  const para = parent.closest(dotParagraphClass);
  const unformatted = parent.closest('.derassi-unformatted');
  // if (para) para.scrollIntoView({ inline: 'center', block: 'center' });
  // parent.scrollIntoView({ inline: 'center', block: 'center' });

  // When first letter is typed in paragraph
  if (para && !para.textContent) {
    const capitalized = getSpan(`${currentFont.family} Caped`, currentFont.size, 'derassi-caped');
    capitalized.insertAdjacentText('afterbegin', letter);
    parent.insertAdjacentElement('afterbegin', capitalized);
    replaceCaret(capitalized);
    return;
  }
  const parentCaps = parent.closest('.derassi-caped');
  const isFirstLetter = parent.closest('.derassi-first-letter');
  // if capsLock is ON and focusnode is not curretly in caps, create
  // a span with caps style/class and move focus in
  if (!parentCaps && isCaps) {
    const capitalized = getSpan(`${currentFont.family} Caped`, currentFont.size, 'derassi-caped');
    // document.createElement('span');
    // capitalized.classList.add('derassi-caped');
    // capitalized.style.fontFamily = `${currentFont.family} Caped`;
    capitalized.insertAdjacentHTML('afterbegin', letter);
    // this is a case where this is not the first letter inside the caps class
    // the second (else if case) is where this is the first letter inside the new
    // caps class/style
    if (focusNode.nodeType === Node.TEXT_NODE) {
      const nextNode = (focusNode as Text).splitText(focusOffset);
      parent.insertBefore(capitalized, nextNode);
      replaceCaret(capitalized);
    } else if (focusNode.nodeType === Node.ELEMENT_NODE) {
      // here focus nodde should be paragraph or li, or td or new caps styled span
      ////////////////////////////======>>>>>>>>>>>>>>>
      (focusNode as HTMLElement).insertAdjacentElement('afterbegin', capitalized);
      replaceCaret(focusNode);
    }
    return;
  }

  // case where focus needs to get out of caps, because user unlocked the capsLock
  if (parentCaps && !isCaps) {
    if (isFirstLetter) {
      parent.insertAdjacentText('afterbegin', letter);
      replaceCaret(parent);
      parent.classList.remove('derassi-first-letter');
      return;
    }
    const span = getSpan(currentFont.family, currentFont.size, '');
    // document.createElement('span') as HTMLSpanElement;
    span.append(document.createTextNode(letter));
    // span.style.fontFamily = currentFont.family;
    parentCaps.insertAdjacentElement('afterend', span);
    replaceCaret(span);
    return;
  }
  if (focusNode.parentElement) {
    const naked = focusNode.parentElement.classList.contains(containerClass);
    if (naked) {
      const span = getSpan(currentFont.family, currentFont.size, '');
      // document.createElement('span');
      // span.style.fontFamily = currentFont.family;
      span.appendChild(document.createTextNode(letter));
      focusNode.parentElement.replaceChild(span, focusNode);
      replaceCaret(span);
      return;
    }
  }

  // case where focus node is not in the first letter of the parent span/li/td...etc.
  // it is in the middle somewhere, and is re-writing the string
  if (focusNode.textContent && focusNode.nodeType === Node.TEXT_NODE) {
    // const text = focusNode.textContent;
    if (unformatted) {
      if (!parent.classList.contains('derass-inside-unformatted')) {
        const inUnformatted = document.createElement('span');
        inUnformatted.classList.add('derass-inside-unformatted');
        inUnformatted.style.fontFamily = currentFont.family;
        inUnformatted.insertAdjacentText('afterbegin', letter);
        parent.insertAdjacentElement('afterend', inUnformatted);
        replaceCaret(inUnformatted);
        return;
      }
    }
    const isPeriod = focusNode.textContent[focusOffset - 1] === '.';
    focusNode.textContent = `${focusNode.textContent.slice(0, focusOffset)}${letter}${focusNode.textContent.slice(focusOffset)}`;
    adjustFocus(sel, focusNode, focusOffset + 1);
    if (isPeriod && letter === ' ') {
      const capitalized = getSpan(`${currentFont.family} Caped`, currentFont.size, 'derassi-first-letter');
      //document.createElement('span');
      capitalized.classList.add('derassi-caped');
      // capitalized.style.fontFamily = `${currentFont.family} Caped`;
      // const after = (focusNode as Text).splitText(focusOffset + 1);
      const currentNode = focusNode.parentElement;
      if (currentNode) {
        currentNode.insertAdjacentElement('afterend', capitalized);
        moveCaretInside(capitalized);
      }
    }
  } else {
    // case where focusNode is paragraph, li, td
    (focusNode as HTMLElement).insertAdjacentText('afterbegin', letter);
    replaceCaret(focusNode.firstChild);
  }
};

const insertInput = (target, newLetter, replace) => {
  const thisTarget = target;
  const start = thisTarget.selectionStart;
  const space = thisTarget.value[start - 1] === ' ';
  const letter = space ? ` ${newLetter}` : newLetter;
  thisTarget.value = `${thisTarget.value.slice(0, replace ? start - 1 : start)}${letter}${thisTarget.value.slice(start)}`;
  const focusPoint = space ? start + 1 : start;
  thisTarget.selectionStart = replace ? focusPoint : focusPoint + 1;
  thisTarget.selectionEnd = replace ? focusPoint : focusPoint + 1;
};

const replaceLast = (sel, bodyToggle, letter, isTargetInput, target) => {
  const { focusNode, focusOffset } = sel;
  if (sel && focusNode) {
    if (bodyToggle) {
      // const newLetter = keyboardMap[Vkey];
      const oldString = isTargetInput ? target.value : focusNode.textContent;
      if (!oldString) return;
      if (isTargetInput) {
        insertInput(target, letter, true);
      } else {
        const space = focusNode.textContent[focusOffset - 1] === ' ';
        let ltr = letter;
        if (space) ltr = ` ${letter}`;
        focusNode.textContent = oldString.substr(0, focusOffset - 1)
        + ltr
        + oldString.substr(focusOffset, oldString.length);
        adjustFocus(sel, focusNode, space ? focusOffset + 1 : focusOffset);
      }
    }
  }
};
/**
 * Manages key pressing to insert english or Abesha letters
 * @param {React.KeyboardEvent} event - the dom event
 * @param {Object} state - the react reducer state
 */
let currentKey = '';
export default (props) => {
  const {
    event,
    state,
    // vowelState,
  } = props;
  const { target } = event;
  if (!target) {
    return;
  }
  const isTargetInput = target.nodeName === 'INPUT';
  const sel = window.getSelection();
  if (!sel) return;
  // const { bodyToggle } = state as InitialState;
  let isCaps = event.getModifierState('CapsLock');
  const isShift = event.getModifierState('Shift');

  if (isShift) {
    isCaps = !isCaps;
  }
  if (isNumber(event.key) && !bodyToggle.toggle) {
    return;
  }
  if (bodyToggle.toggle) {
    event.preventDefault();
    const key = event.key.toLowerCase();
    // return if number or none letter
    if (isNumber(key)) {
      if (isTargetInput) {
        insertInput(target, key, false);
      } else {
        insertLetter({
          isCaps: false, letter: key, ...props,
        });
      }
      return;
    }
    currentKey += key;
    currentKey = _.trim(currentKey);
    const isVowel = 'aeioutsxhc'.includes(key);
    const letter = keyboardMap[currentKey];
    if (key === ' ') {
      if (isTargetInput) {
        insertInput(target, key, false);
      } else {
        insertLetter({
          isCaps, letter: key, ...props,
        });
      }
      currentKey = '';
      return;
    }
    if (!letter) {
      const val = keyboardMap[key];
      if (isTargetInput) {
        insertInput(target, val, false);
      } else {
        insertLetter({
          isCaps, letter: val, ...props,
        });
      }
      currentKey = key;
      return;
    }
    if (currentKey && isVowel) {
      if (currentKey === key) { // when a vowel is not voweling, i for example
        if (isTargetInput) {
          insertInput(target, letter, false);
        } else {
          insertLetter({
            isCaps, letter, ...props,
          });
        }
      } else {
        replaceLast(sel, bodyToggle, letter, isTargetInput, target);
      }
      // replaceLast(sel, bodyToggle, val, isTargetInput, target);
    }
    if (currentKey && !isVowel) {
      if (isTargetInput) {
        insertInput(target, letter, false);
      } else {
        insertLetter({
          isCaps, letter, ...props,
        });
      }
    }
  }
};
