import uniqid from 'uniqid';
import { textParent } from '../changeHistory';

export default (): void => {
  const sel = window.getSelection();
  if (sel) {
    const { focusNode, focusOffset } = sel;
    if (focusNode) {
      const parent = (focusNode.nodeType === Node.TEXT_NODE
        ? focusNode.parentElement
        : focusNode) as HTMLElement;
      if (!parent.id) {
        parent.id = `derassi-focus-${uniqid()}`;
      }
      let index = 0;
      if (parent.childNodes) {
        const nodes = Array.from(parent.childNodes) as Node[];
        index = nodes.indexOf(focusNode);
      }
      textParent.parent = parent;
      textParent.focusOffset = focusOffset;
      textParent.index = index;
    }
  }
};
