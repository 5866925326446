import * as React from 'react';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import getService from '../../../services/get';
import PageContainer from './page';
import { stateNames } from '../../../index';
import {
  textParent, currentFont, derassiFooter, derassiHeader,
} from '../../../eventHandlers/changeHistory';
import reEnter from '../helpers/reFocus';
import { dotParagraphClass } from '../../../nameGenerator';
import { moveCaretInside } from '../../../eventHandlers/helpers/moveFocus';
import { translateWords } from '../../../translate';

const useStyles = makeStyles(() => ({
  root: (props): any => ({
    zoom: props.bodyZoom,
    fontFamily: props.fontFamily,
    marginLeft: 40,
    position: 'relative',
    zIndex: 20,
    top: 0,
  }),
  spinner: {
    marginLeft: '50%',
  },
}));

const Pages = (props): JSX.Element => {
  const { state } = props;
  const { pages } = state;
  return (
    <>
      {Object.entries(pages).map((entry) => {
        const [pageId, page] = entry;
        return (
          <PageContainer id={pageId} key={pageId} page={page} {...props} />
        );
      })}
    </>

  );
};

/**
 * container component for all pages at load time,
 * Here react is used to load pages and DOM takes over after that,
 * to add pages, remove pages, etc...
 */
export default (props): JSX.Element => {
  const {
    state, dispatch, match,
  } = props;
  const { params } = match;
  const { fileID } = params;
  // currentFile.id = fileID;
  const {
    isSignedIn, defaultFontFamily, bodyZoom, loading, lang, login,
  } = state as InitialState;
  const pageShape = {
    fontFamily: defaultFontFamily,
    bodyZoom,
  };
  const classes = useStyles(pageShape);
  const { enqueueSnackbar } = useSnackbar();
  const ref = React.useRef<HTMLDivElement>(null);
  const fetchDoc = (): void => {
    dispatch({ type: stateNames.pageLoading, pageLoading: true });
    getService(fileID, state, dispatch).then((result) => {
      const { doc } = result;
      if (doc) {
        const { name } = doc;
        dispatch({ type: stateNames.pages, pages: doc.pages });
        dispatch({
          type: stateNames.currentTitle,
          currentTitle: name || translateWords.title[lang],
        });
        if (doc.derassiHeader) {
          derassiHeader.set(doc.derassiHeader as DerassiHeader);
        }
        if (doc.derassiFooter) {
          derassiFooter.set(doc.derassiFooter as DerassiHeader);
        }
        if (doc.menuFontFamily) {
          dispatch({
            type: stateNames.menuFontFamily,
            menuFontFamily: doc.menuFontFamily,
          });
        }
        if (doc.fontFamily) {
          currentFont.family = doc.fontFamily;
          dispatch({
            type: stateNames.fontChange,
            fontChange: doc.fontFamily,
          });
        }
        if (doc.fontSize) {
          currentFont.size = doc.fontSize;
          dispatch({
            type: stateNames.fontChange,
            fontChange: 'didChange',
          });
        }
        if (doc.reFocus) {
          const { focusParentId, focusOffset, index } = doc.reFocus;
          const parent = document.getElementById(focusParentId);
          textParent.parent = parent;
          textParent.focusOffset = focusOffset;
          textParent.index = index;
          reEnter();
        } else {
          const para = document.querySelector(dotParagraphClass);
          if (para) {
            moveCaretInside(para);
            textParent.parent = para;
            textParent.focusOffset = 0;
            textParent.index = 0;
            reEnter();
          }
        }
      } else {
        const para = document.querySelector(dotParagraphClass);
        if (para) {
          moveCaretInside(para);
          textParent.parent = para;
          textParent.focusOffset = 0;
          textParent.index = 0;
          reEnter();
        }
      }
      dispatch({ type: stateNames.pageLoading, pageLoading: false });
    });
  };

  React.useEffect(() => {
    fetchDoc();
  }, [login, isSignedIn, loading]);

  React.useEffect(() => {
    dispatch({ type: stateNames.currentID, currentID: fileID });
    // treat as local file when fileID is temp
    // that way user can save work in localstorage
    if (fileID === 'temp' || fileID === 'ጊዜያዊ') {
      dispatch({ type: stateNames.localFile, localFile: true });
    }
    window.addEventListener('offline', () => {
      enqueueSnackbar(translateWords.errorNotOnline[lang], { variant: 'warning' });
    });
    window.addEventListener('online', () => {
      enqueueSnackbar(translateWords.backOnline[lang], { variant: 'success' });
    });
    return () => {
      derassiHeader.reset();
      derassiFooter.reset();
    };
  }, []);

  return (
    <div
      id="derassi-pages"
      ref={ref}
      key={fileID}
      className={classes.root}
      style={{ fontFamily: defaultFontFamily }}
    >
      { loading
        ? <Paper><CircularProgress className={classes.spinner} size={100} /></Paper>
        : <Pages {...props} />
      }
    </div>
  );
};
