
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListItem, List, ListItemText,
} from '@material-ui/core';
import uniqid from 'uniqid';
import _ from 'lodash';
import formatDoc from '../../helpers/formatting';
import { Capitalized } from '../../helpers/changeToUpper';
import { fontFamilies, aggrigateFonts } from '../../../../translate';
import { stateNames } from '../../../../index';
import { moveCaretInside, replaceCaret } from '../../../../eventHandlers/helpers/moveFocus';
import { textParent, currentFont } from '../../../../eventHandlers/changeHistory';
import reEnter from '../../helpers/reFocus';
import { containerClass } from '../../../../nameGenerator';

const useStyles = makeStyles(() => ({
  root: {
  },
  dropdown: {
    position: 'absolute',
    display: 'inline-block',
    zIndex: 3,
    top: 35,
  },
  caret: {
    // flexGrow: 1,
    // paddingLeft: -4,
  },
  item: {
    minWidth: 150,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#E3E3E3',
    },
  },
  icon: {
    minWidth: 150,
    fontSize: 'auto',
  },
  nested: {
    position: 'absolute',
    // display: 'inline-block',
    display: 'none',
    left: 0,
    top: 0,
    zIndex: 40,
    maxWith: 150,
  },
}));

export default (props) => {
  const classes = useStyles();
  const { state, dispatch } = props;
  const { lang, fontChange, loading } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  const [subOpen, setSubOpen] = React.useState(false);
  const [menu, setMenu] = React.useState<Array<any>>([]);
  const [subFontsInfo, setSubFontsInfo] = React.useState({ subFonts: [], left: 0, top: 0 });
  const anchorRef = React.useRef(null);
  const fontRef = React.useRef<HTMLElement>();
  const [current, setCurrent] = React.useState(currentFont.family);
 
  React.useEffect(() => {
    const aggrigated = aggrigateFonts();
    setMenu(aggrigated);
  }, []);

  React.useEffect(() => {
    setCurrent(currentFont.family || 'Mulat Abay');
  }, [fontChange]);


  const handleMouseEnter = (event, hasMore): void => {
    if (!hasMore) {
      setSubOpen(false);
      return;
    }
    if (fontRef && fontRef.current) {
      const {
        offsetLeft, offsetTop, offsetWidth, scrollTop,
      } = fontRef.current;
      const values = event.currentTarget.getAttribute('value');
      const thisTop = event.target.offsetTop;
      const subFonts = values.split(':');
      const info = { subFonts, left: offsetLeft + offsetWidth, top: offsetTop + thisTop - scrollTop };
      setSubFontsInfo(info);
      setSubOpen(true);
    }
  };

  const handleClick = (event, hasMore): void => {
    event.stopPropagation();
    setSubOpen(false);
    if (hasMore) return;
    // dispatch({ type: stateNames.fontFamily, fontFamily: event.currentTarget.id || fontFamily });
    currentFont.family = event.currentTarget.id || currentFont.family;
    setCurrent(event.currentTarget.id || currentFont.family);
    
    const selected = document.querySelector('.derassi-selected');
    if (selected) {
      formatDoc({
        attr: 'font-family', value: event.currentTarget.id || currentFont.family,
      });
      setOpen(false);
      return;
    }
    const { parent, focusOffset, index } = textParent;
    if (parent) {
      const textNode = parent.childNodes[index] as Text;
      const span = document.createElement('span');
      span.style.fontFamily = event.currentTarget.id;
      span.id = `derassi-focus-${uniqid()}`;
      if (textNode && textNode.nodeType === Node.TEXT_NODE && textNode.length > 0) {
        const split = (textNode as Text).splitText(focusOffset);
        parent.insertBefore(span, split);
      } else if (!parent.textContent && parent.classList.contains(containerClass)) {
        parent.insertAdjacentElement('afterbegin', span);
      } else {
        // This should be fixed so it can not be without a paragrah wrapper in a page
        parent.insertAdjacentElement('afterend', span);
      }
      textParent.parent = span;
      textParent.focusOffset = 0;
      moveCaretInside(span as HTMLElement);
    }
    setOpen(false);
  };
  const handleMouseLeave = (): void => {
    if (open) {
      setOpen(false);
      setSubOpen(false);
    }
  };
  const handleToggle = (): void => {
    reEnter();
    setOpen(!open);
  };
  const handleScroll = (e) => {
    setSubOpen(false);
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Paper
        ref={fontRef}
        className={classes.dropdown}
        onScroll={e => handleScroll(e)}
        style={open ? {
          display: 'inline-block',
          maxHeight: 340,
          width: 200,
          overflow: 'scroll',
        } : { display: 'none' }}
      >
        <List id="split-button-menu">
          {menu.map((fonts) => {
            const zeroth = fonts[0];
            const rest = fonts.slice(1);
            const temp: string[] = [];
            fonts.forEach((font) => {
              temp.push(_.join(font, ','));
            });
            const subs = _.join(temp, ':');
            const [english, abesha] = zeroth;
            const capped = (
              <Capitalized
                word={abesha}
                key={uniqid()}
                useFont={english}
                useFontCaped={`${english} Caped`}
                {...props}
              />
            );
            return (
              <div key={uniqid()}>
                <ListItem
                  dense
                  onMouseEnter={(e): void => handleMouseEnter(e, rest.length)}
                  onClick={(e): void => handleClick(e, rest.length)}
                  value={subs}
                  key={uniqid()}
                  id={rest.length ? abesha : english}
                  className={classes.item}
                >
                  <ListItemText>
                    <span style={{ fontFamily: english }}>
                      {lang <= 2 ? capped : english}
                    </span>
                  </ListItemText>
                  {rest.length ? (<ChevronRight style={{ fontSize: 'large', backgroundColor: 'inherit' }} />) : (<></>)}
                </ListItem>
              </div>
            );
          })}
        </List>
      </Paper>
      <Paper
        className={classes.nested}
        onMouseLeave={(): void => setSubOpen(false)}
        style={subOpen ? { display: 'inline-block', left: subFontsInfo.left, top: subFontsInfo.top } : { display: 'none' }}
      >
        <List id="temp id">
          {
          subFontsInfo.subFonts.map((f) => {
            const [eng, absh] = (f as string).split(',');
            return (
              <ListItem
                dense
                button
                id={eng}
                key={uniqid()}
                style={{ fontFamily: eng }}
                className={classes.item}
                onClick={(e): void => handleClick(e, false)}
              >
                <ListItemText>
                  <span style={{ fontFamily: eng }}>
                    {lang <= 2 ? (
                      <Capitalized
                        word={absh}
                        useFont={eng}
                        useFontCaped={`${eng} Caped`}
                        {...props}
                      />
                    ) : eng}
                  </span>
                </ListItemText>
              </ListItem>
            );
          })
        }
        </List>
      </Paper>
      <IconButton ref={anchorRef} onClick={handleToggle} className={classes.icon}>
        {lang <= 2
          ? (
            <Capitalized
              word={fontFamilies[current]}
              useFont={current}
              useFontCaped={null}
              {...props}
            />
          )
          : current}
        <ArrowDropDownIcon className={classes.caret} />
      </IconButton>
    </div>
  );
};
