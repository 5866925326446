// import iterator from 'dom-iterator';
import _ from 'lodash';
import { stateNames } from '../../..';
import { moveCaretInside, replaceCaret } from '../../../eventHandlers/helpers/moveFocus';
import { textParent } from '../../../eventHandlers/changeHistory';
import {
  moveableClass, tableClass, dotContainerClass, dotParagraphClass,
} from '../../../nameGenerator';


// export const scrollToView = (el) => {
//   const element = el as Element;
//   element.scrollIntoView();
//   element.scrollIntoView(false);
//   element.scrollIntoView({ block: 'end' });
//   element.scrollIntoView({ block: 'center', inline: 'nearest' });
// };

const findNode = (node: HTMLElement | Element | Node, path: number[]) => {
  let topNode = node;
  for (let index = 0; index < path.length; index += 1) {
    const th = path[index];
    topNode = topNode.childNodes[th];
  }
  return topNode;
};
export const cleanUpPara = (para: HTMLElement): void => {
  const spans = para.querySelectorAll('span');
  Array.from(spans).forEach((span) => {
    if (!span.textContent) {
      span.remove();
    }
  });
};
export default (): void => {
  try {
    // if there are selected (or heighlighed text) return;
    // const sel = window.getSelection();
    // if (sel && !sel.isCollapsed) {
    //   return;
    // }
    const { parent, focusOffset, index } = textParent;
    if (parent) {
      const { id } = parent;
      if (id) {
        const element = document.getElementById(id) as HTMLElement;
        // const textNode = element.firstChild || element;
        const textNode = element.childNodes[index];
        if (!element) return;
        // if empty or br element return and keep focus setting as is.s
        if (textNode.nodeType !== Node.TEXT_NODE) {
          moveCaretInside(element);
          return;
        }
        // element.focus();
        // const sel = window.getSelection();
        // if (sel) {
        const range = document.createRange();
        const len = focusOffset;
        // let len = 0;
        // if (textNode.textContent && focusOffset > textNode.textContent.length) {
        //   len = textNode.textContent.length;
        // } else {
        //   len = focusOffset;
        // }

        range.setStart(textNode, len);
        range.setEnd(textNode, len);
        const sel = window.getSelection();
        if (!sel) return;
        sel.removeAllRanges();
        sel.addRange(range);
        const container = element.closest(dotContainerClass) as HTMLElement;
        if (container && container.scrollIntoView) {
          container.scrollIntoView({ block: 'center', inline: 'nearest' });
        }
      }
      const para = parent.closest(dotParagraphClass) as HTMLElement;
      if (para) {
        //  cleanUpPara(para);
      }
    }
  } catch (err) {
    // fallback
    const para = document.querySelector(dotParagraphClass);
    if (para) {
      moveCaretInside(para);
    }
  }
};
