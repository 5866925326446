
import React from 'react';
import ReactDOM from 'react-dom';
import IconButton from '@material-ui/core/IconButton';
import { useSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Share from '@material-ui/icons/PersonAdd';
import Save from '@material-ui/icons/Save';
import Add from '@material-ui/icons/Add';
import FileCopy from '@material-ui/icons/FileCopy';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';

import {
  Divider, ListItem, ListItemIcon, ListItemText, CircularProgress,
} from '@material-ui/core';
import _ from 'lodash';
import { Delete, OpenWith, OpenInNew } from '@material-ui/icons';
import { Capitalized } from '../../helpers/changeToUpper';
import { translateWords } from '../../../../translate';
import createService from '../../../../services/create';
import deleteService from '../../../../services/delete';
import useStyles from './commonStyles';
import { stateNames } from '../../../../index';
import OpenPDF from '../../../pdf/openPDF';
import OpenPrint from '../../../pdf/openPrint';
import DownloadPDF from '../../../pdf/downloadPDF';
import reEnter from '../../helpers/reFocus';
import gShare from '../../../../services/gShare';
import save, { downloadPages } from '../../../../services/save';
// import { updateLocal } from '../../../../services/save';
import Home from '../../../home';
import devLog from '../../../../devLog';

export default (props): JSX.Element => {
  const {
    state, dispatch, history, match,
  } = props;
  const {
    isSignedIn, menuInited, currentTitle, menuFontFamily, lang, localFile,
  } = state as InitialState;

  const { params } = match;
  const { fileID } = params;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  //const [] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = React.useRef(null);

  const handleAdd = (copy?) => {
    try {
      if (!isSignedIn) {
        return;
      }
      setWorking(true);
      createService({
        data: { name: currentTitle }, state, copy, id: fileID,
      }).then((result) => {
        const { id, name } = result;
        history.push(`edit/${id}`, { name });
        enqueueSnackbar(translateWords.createdDocument[lang], { variant: 'success' });
      });
      setWorking(false);
      setOpen(false);
    } catch (err) {
      enqueueSnackbar(translateWords.error[lang], { variant: 'error' });
      setWorking(false);
      setOpen(false);
    }
  };

  const handleShare = (): void => {
    gShare(state);
    setOpen(false);
  };
  const handleToggle = () => {
    reEnter();
    if (!open) {
      dispatch({ type: 'menuInited', menuInited: true });
    }
    dispatch({ type: stateNames.showThirdMenu, showThirdMenu: '' });
    setOpen(!open);
  };
  const handleMouseLeave = (): void => {
    if (open) {
      setOpen(false);
    }
  };
  const handleMouseEnter = (): void => {
    if (!open) {
      if (menuInited) {
        setOpen(true);
      }
    }
  };
  const handleCloudUpload = () => {
    if (isSignedIn) {
      setWorking(true);
      createService({ data: { name: currentTitle }, state }).then((result) => {
        const { id } = result;
        history.push(id);
        dispatch({ type: stateNames.currentID, currentID: id });
        dispatch({ type: stateNames.localFile, localFile: false });
        save({ id, state, dispatch });
        setOpen(false);
        setWorking(false);
        enqueueSnackbar(`${lang <= 2 ? 'አዲስ ፋይል ተፈጥሯል' : 'New Ducment Created'}`, { variant: 'success' });
      }).catch((err) => {
        setOpen(false);
        setWorking(false);
        enqueueSnackbar(`${lang <= 2 ? 'ችግር ነበር: ' : 'There was a problem: '} ${err.message}`, { variant: 'error' });
      });
    }
  };
  const handleSave = (): void => {
    downloadPages(state, dispatch);
    setOpen(false);
  };
  const invoke = (ans) => {
    if (ans === translateWords.delete[lang]) {
      if (isSignedIn) {
        // TODO: delete on
        deleteService(state, dispatch).then(() => history.push('/'));
      } else {
        // delete local;
        localStorage.setItem('derassiDoc', '');
        history.push('/');
      }
    }
    setOpen(false);
  };
  const handleDelete = () => {
    const { openAlert } = state as InitialState;
    dispatch({
      type: stateNames.openAlert,
      openAlert: {
        title: `${translateWords.delete[lang]} ${translateWords.file[lang]}`,
        contentText: translateWords.areYouSure[lang],
        first: translateWords.delete[lang],
        second: translateWords.cancel[lang],
        open: true,
        choice: '',
        invoke,
      },
    });
  };

  const handleFromFile = (e): void => {
    const file = e.target.files[0];
    const { name } = file;
    // file.blob().then((blob) => {
    const reader = new FileReader();
    // dispatch({ type: stateNames.loading, loading: true });
    reader.onload = (): void => {
      if (reader.result) {
        const text = reader.result;
        // dispatch({ type: stateNames.loading, loading: false });
        dispatch({ type: stateNames.localFile, localFile: true });
        // dispatch({ type: stateNames.resetPages }); // clean up, React gives memory leak
        localStorage.setItem('derassiDoc', text as string);
        // history.push('/');
        // history.push(`edit/${id}`, { name });
        // const pages = document.getElementById('derassi-pages');
        // if (pages && pages.parentElement) {
        //   ReactDOM.unmountComponentAtNode(pages.parentElement);
        // history.push('/');
        history.replace(`${_.trimEnd(name, '.json')}`);
        if (window.location) window.location.reload();
        //}
        const json = JSON.parse(text as string);
      }
    };
    reader.onerror = (): void => devLog(reader.error);
    reader.readAsText(file, 'utf-8');
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      {/* disablePortal */}
      <Paper elevation={10} className={classes.dropdown} style={open ? { display: 'inline-block' } : { display: 'none' }}>
        <List className={classes.list} onClick={(): void => dispatch({ type: 'menuInited', menuInited: false })}>
          <Divider />
          <ListItem
            dense
            id="share"
            key="share"
            button
            disabled
            onClick={handleShare}
          >
            <ListItemIcon><Share /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.share[lang] }
                { `(${translateWords.commingSoon[lang]})`}
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          { localFile
            ? (
              <>
                {isSignedIn
                  ? (
                    <ListItem
                      dense
                      id="cloudUpload"
                      key="cloudUpload"
                      button
                      onClick={handleCloudUpload}
                    >
                      <ListItemIcon>{working ? <CircularProgress size={14} /> : <CloudUpload />}</ListItemIcon>
                      <ListItemText>
                        <div style={{ fontFamily: menuFontFamily }}>
                          { translateWords.save[lang] }
                        </div>
                      </ListItemText>
                    </ListItem>
                  )
                  : <></>}
              </>
            )
            : (
              <>
                <ListItem
                  dense
                  id="add"
                  key="add"
                  button
                  onClick={handleAdd}
                  disabled={!isSignedIn}
                >
                  <ListItemIcon>{working ? <CircularProgress size={14} /> : <Add />}</ListItemIcon>
                  <ListItemText>
                    <div style={{ fontFamily: menuFontFamily }}>
                      { translateWords.new[lang] }
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem
                  dense
                  id="copy"
                  key="copy"
                  button
                  onClick={() => handleAdd(true)}
                  disabled={!isSignedIn}
                >
                  <ListItemIcon>{working ? <CircularProgress size={14} /> : <FileCopy />}</ListItemIcon>
                  <ListItemText>
                    <div style={{ fontFamily: menuFontFamily }}>
                      { translateWords.new[lang] }
                      {'  '}
                      { translateWords.copy[lang] }
                    </div>
                  </ListItemText>
                </ListItem>
              </>
            )}
          <Divider />
          <ListItem
            dense
            id="open"
            key="open"
            button
          >
            <label
              htmlFor="fileupload"
              style={{ paddingBottom: 5, fontSize: 'small' }}
            >
              <OpenInNew style={{ opacity: 0.7, paddingRight: 30, transform: 'translateY(7px)' }} />
              <span style={{ fontFamily: menuFontFamily }}>
                { translateWords.fromComputer[lang] }
              </span>
              <input onChange={(e): void => handleFromFile(e)} id="fileupload" name="fileupload" style={{ opacity: 0, height: 1, maxWidth: 100 }} type="file" accept="application/json" />

            </label>
          </ListItem>
          <Divider />
          <ListItem
            dense
            id="delete"
            key="delete"
            button
            onClick={handleDelete}
          >
            <ListItemIcon><Delete /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.delete[lang] }
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            dense
            id="save"
            key="save"
            button
            onClick={handleSave}
          >
            <ListItemIcon><CloudDownload /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.save[lang] }
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
          <OpenPDF {...props} setOpen={setOpen} />
          <DownloadPDF {...props} setOpen={setOpen} />
          <Divider />
          <OpenPrint {...props} setOpen={setOpen} />
          {/* <Divider /> */}
          {/* <ListItem
            dense
            id="settings"
            key="settings"
            button
            onClick={handleSettings}
          >
            <ListItemIcon><Settings /></ListItemIcon>
            <ListItemText>
              <div style={{ fontFamily: menuFontFamily }}>
                { translateWords.setting[lang] }
              </div>
            </ListItemText>
          </ListItem> */}
        </List>
      </Paper>
      <IconButton
        className={classes.icon}
        onMouseEnter={handleMouseEnter}
        ref={anchorRef}
        onClick={handleToggle}
      >
        {lang <= 2 ? (
          <Capitalized
            word={translateWords.file[lang]}
            {...props}
          />
        ) : _.capitalize(translateWords.file[lang])}
      </IconButton>
    </div>
  );
};
