import React from 'react';
import { stateNames } from '../index';

export const login = (state, dispatch) => {
  const { gapi } = state as InitialState;
  if (gapi) {
    const auth = gapi.auth2;
    if (auth) {
      const getAuth = auth.getAuthInstance();
      if (getAuth) {
        dispatch({ type: stateNames.loading, laoding: true });
        getAuth.signIn().then((googleUser) => {
          const basicProfile = googleUser.getBasicProfile();
          const name = basicProfile.getName();
          const email = basicProfile.getEmail();
          const imageURL = basicProfile.getImageUrl();
          const isSignedIn = getAuth.isSignedIn.get();
          dispatch({ type: stateNames.name, name });
          dispatch({ type: stateNames.email, email });
          dispatch({ type: stateNames.imageURL, imageURL });
          dispatch({ type: stateNames.isSignedIn, isSignedIn });
          dispatch({ type: stateNames.loading, loading: false });
        });
      }
      dispatch({ type: stateNames.login, login: false });
    }
  }
  // setGapiStatus({ ...gapiStatus, isSignedIn: false });
};

export const logout = (state, dispatch) => {
  const { gapi } = state as InitialState;
  if (gapi) {
    const auth = gapi.auth2;
    if (auth) {
      const getAuth = auth.getAuthInstance();
      if (getAuth) {
        dispatch({ type: stateNames.loading, loading: true });
        getAuth.signOut().then(() => {
          dispatch({ type: stateNames.name, name: '' });
          dispatch({ type: stateNames.email, email: '' });
          dispatch({ type: stateNames.imageURL, imageURL: '' });
          dispatch({ type: stateNames.isSignedIn, isSignedIn: false });
          dispatch({ type: stateNames.loading, loading: false });
        });
      }
    }
  }
};
// useGapi
export default (state, dispatch): void => {
  const API_KEY = 'AIzaSyC9EY4t4vCiJQKkUwLcT5gEeaWqaesl3YU';
  const CLIENT_ID = '445507936382-u8rv41rlunpdqik1iibjs8g29gpjfsrg.apps.googleusercontent.com';
  const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];
  const SCOPE = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.photos.readonly';

  const ref = React.useRef();
  React.useEffect(() => {
    dispatch({ type: stateNames.loading, loading: true });
    if (window || (window && (window as any).gapi)) {
      ref.current = (window as any).gapi;
      const gapi = ref.current as any;
      if (!gapi) return;
      dispatch({ type: stateNames.gapi, gapi });
      // const script = document.createElement('script');
      // script.src = 'https://apis.google.com/js/api.js';
      // script.onload = () => {
      gapi.load('client:auth2:picker:drive-share', () => {
        gapi.client.init({
          // eslint-disable-next-line @typescript-eslint/camelcase
          api_key: API_KEY,
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id: CLIENT_ID,
          // eslint-disable-next-line @typescript-eslint/camelcase
          discovery_docs: DISCOVERY_DOCS,
          scope: SCOPE,
        }).then(() => {
          const getToken = gapi.client.getToken();
          if (getToken && getToken.access_token) {
            const accessToken = getToken.access_token;
            localStorage.setItem('GAPI_ACCESS_TOKEN', accessToken);
            localStorage.setItem('GAPI_API_KEY', accessToken);
            // dispatch({ type: stateNames.accessToken, accessToken });
            // dispatch({ type: stateNames.API_KEY, API_KEY });
          }
          const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
          const googleUser = gapi.auth2.getAuthInstance().currentUser.get();
          const basicProfile = googleUser.getBasicProfile();
          const name = basicProfile.getName();
          const email = basicProfile.getEmail();
          const imageURL = basicProfile.getImageUrl();
          dispatch({ type: stateNames.isSignedIn, isSignedIn });
          dispatch({ type: stateNames.name, name });
          dispatch({ type: stateNames.email, email });
          dispatch({ type: stateNames.imageURL, imageURL });
          dispatch({ type: stateNames.loading, loading: false });
          gapi.auth2.getAuthInstance().isSignedIn.listen((updatedStatus) => {
            dispatch({ type: stateNames.isSignedIn, isSignedIn: updatedStatus });
          });
        }).catch((err) => {
          dispatch({ type: stateNames.loading, loading: false });
        });
      });
      // };
      // document.body.append(script);
    }
  }, []);
};
