/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router, Route, Switch, HashRouter,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import '../App.css';
import Editor from './editor';

import useGapi from '../services/useGapi';


const Home = React.lazy(() => import('./home'));
const KeyboardPage = React.lazy(() => import('./keyboardPage'));
const Help = React.lazy(() => import('./help'));
// const Editor = React.lazy(() => import('./editor'));

export default (props): JSX.Element => {
  const { state, dispatch } = props;
  useGapi(state, dispatch);
  const useStyles = makeStyles({
    root: {
    },
    fontFamily: {
      fontFamily: (state as InitialState).menuFontFamily,
    },
  });

  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={1}
      classes={{
        variantSuccess: classes.fontFamily,
        variantError: classes.fontFamily,
        variantWarning: classes.fontFamily,
        variantInfo: classes.fontFamily,
      }}
    >
      <React.Suspense fallback={<div>Loading..</div>}>
        <Router>
          <Switch>
            <Route path="/" exact render={(args): JSX.Element => <Home {...args} state={state as InitialState} dispatch={dispatch} />} />
            <Route path="/edit/:fileID" render={(args): JSX.Element => <Editor {...args} state={state as InitialState} dispatch={dispatch} />} />
            <Route path="/keyboard/" render={(args): JSX.Element => <KeyboardPage {...args} state={state as InitialState} dispatch={dispatch} />} />
            <Route path="/help/" render={(): JSX.Element => <Help />} />
            <Route render={(args): JSX.Element => <Home {...args} state={state as InitialState} dispatch={dispatch} />} />
          </Switch>
        </Router>
      </React.Suspense>
    </SnackbarProvider>

  );
};
