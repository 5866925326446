/**
 * A content editable part where users can enter text, graphs, tables, and lists
 * @module editor
 */
import * as React from 'react';
import _ from 'lodash';
import onKeyDown from '../../../eventHandlers/onKeyDown';
import onPaste from '../../../eventHandlers/onPaste';
import onFocus from '../../../eventHandlers/onFocus';
import onBlur from '../../../eventHandlers/onBlur';
import * as ng from '../../../nameGenerator';
import { onAddingChange, onDeletingChange } from '../../../eventHandlers/onChange';
import { stateNames } from '../../../index';
import addChart from '../helpers/addChart';
import save from '../../../services/save';
import attachControl from '../helpers/attachControl';
import { undoState, saveHistory } from '../../../eventHandlers/changeHistory';
import highLight from '../../../eventHandlers/helpers/highLight';
import setFocus from '../../../eventHandlers/helpers/setFocus';


/**
 * @param props the id, innerHTML for starting klass (header, footer, body)
 * <br> and reducer state/dipatch
 */
export default (props): JSX.Element => {
  const {
    id, klass, state, dispatch, innerHTML,
  } = props;
  const {
    derassiFooter, derassiHeader, currentID, pages,
  } = state as InitialState;
  // const [pageLoaded, setPageLoaded] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  /**
   * notify changes so page areas can be recalculated </br>
   * transfered to next, or previous pages
   * @param backspacing boolean to tell backspace key is hit so forwarding chages can be stopped
   * @param saveNow boolean used to optionally flush debouncer
   */
  const notifyChanges = (): void => {
    if (ref && ref.current) {
      const { current } = ref;
      onAddingChange({ target: current, state, dispatch });
      if (current.classList.contains(ng.headerFooterClass)) {
        const body = document.getElementById(ng.getBodyIdForPage(id));
        onAddingChange({ target: body, state, dispatch });
      }
    }
  };


  const debounce = _.debounce(() => {
    save({ id: currentID, state, dispatch });
  }, 3000);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    const alt = e.altKey || e.shiftKey || e.metaKey;
    if (e.key === 'Backspace') {
      if (ref && ref.current) {
        // if in this editor, images dont' have image, remove them
        // this could be true with most containers
        const images = ref.current.querySelectorAll(ng.dotImageClass);
        if (images.length > 0) {
          Array.from(images).forEach((imgCont) => {
            const hasImage = Boolean(imgCont.querySelector('img'));
            if (!hasImage) {
              imgCont.remove();
            }
          });
        }
      }
      const selecteds = document.querySelectorAll('.derassi-selected');
      if (selecteds) {
        Array.from(selecteds).forEach((selected) => {
          selected.remove();
        });
        setFocus();
      }
      if (ref && ref.current) {
        onDeletingChange({ target: ref.current, state, dispatch });
      }
    }
    if (!alt) {
      setFocus();
    }
    const ctrl = e.altKey || e.shiftKey || e.metaKey || e.key === 'Backspace' || e.key === 'Enter';
    if (!ctrl) {
      debounce();
    }
    if (!ctrl) {
      saveHistory();
    }
  };
  /**
   * notifies changes
   * @param event KeyboardEvent
   */
  const handleKeyDown = (event): void => {
    if (ref && ref.current) {
      if (ref.current.classList.contains(ng.headerFooterClass)) {
        const { offsetHeight } = ref.current;
        const goesOver = offsetHeight >= 320;
        if (goesOver && event.key !== 'Backspace') {
          const page = ref.current.closest(ng.dotPageClass);
          if (page) {
            const body = page.querySelector(ng.dotBodyClass);
            if (body) {
              if (ref.current.classList.contains(ng.headerClass)) {
                event.preventDefault();
                return;
              }
            }
          }
          return;
        }
      }
    }

    if (event.key === 'Backspace') {
      undoState.backspacing = true;
      return;
    }
    undoState.backspacing = false;

    notifyChanges();
    onKeyDown({ event, ...props });
  };

  /**
   * Mostly performes Header or Footer notificaions when user focus on them
   * @param event FocusEvent
   */
  const handleFocus = (event): void => {
    if (ref && ref.current) {
      if (ref.current.classList.contains(ng.headerFooterClass)) {
        ref.current.style.outline = '4px solid #44ddff';
      }
    }
    onFocus({ event, ...props });
  };
  const handleMouseEnter = (): void => {
    if (ref && ref.current) {
      if (ref.current.classList.contains(ng.headerFooterClass)) {
        ref.current.style.outline = 'solid 1px #44ddff';
      }
    }
  };
  const handleMouseLeave = (): void => {
    if (ref && ref.current) {
      if (ref.current.classList.contains(ng.headerFooterClass)) {
        ref.current.style.outline = '';
      }
    }
  };
  const handleMouseDown = (): void => {
    // if (ref && ref.current) {
    //   if (ref.current.classList.contains(ng.headerFooterClass)) {
    //     editHeaderFooter(ref);
    //   }
    // }
    // reEnter();
    setFocus();
  };
  const handleDoubleClick = (): void => {
    // if (ref && ref.current) {
    //   if (ref.current.classList.contains(ng.headerFooterClass)) {
    //     editHeaderFooter(ref);
    //   }
    // }
  };
  /**
   * resets looks of Header or Footer on blur
   * @param event Blur Event
   */
  const handleBlur = (event): void => {
    // updateHeaderFooter();
    if (ref && ref.current) {
      if (ref.current.classList.contains(ng.headerFooterClass)) {
        ref.current.style.outline = '';
        const header = ref.current.classList.contains(ng.headerClass);
        const footer = ref.current.classList.contains(ng.footerClass);
        const isFirstPage = ng.getIdFromString(ref.current.id) === 0;
        if (isFirstPage) {
          if (header) {
            derassiHeader.firstPageContent = ref.current.innerHTML;
          }
          if (footer) {
            derassiFooter.firstPageContent = ref.current.innerHTML;
          }
        } else {
          if (header) {
            derassiHeader.otherPagesContent = ref.current.innerHTML;
            const allHeads = document.querySelectorAll(ng.dotHeaderClass);
            const rest = _.drop(Array.from(allHeads));
            rest.forEach((r) => {
              const h = r as HTMLElement;
              h.innerHTML = derassiHeader.otherPagesContent;
            });
          }
          if (footer) {
            derassiFooter.otherPagesContent = ref.current.innerHTML;
            const allFoots = document.querySelectorAll(ng.dotFooterClass);
            const rest = _.drop(Array.from(allFoots));
            rest.forEach((r) => {
              const f = r as HTMLElement;
              f.innerHTML = derassiFooter.otherPagesContent;
            });
          }
        }
      }
    }
    onBlur({ event, ...props });
  };
  const handleSelect = (event): void => {
    const sel = document.getSelection();
    if (sel && !sel.isCollapsed) {
      const range = sel.getRangeAt(0);
      highLight(range);
    }
  };

  const handlePaste = (event): void => {
    event.persist();
    onPaste({ event, ...props });
  };


  React.useEffect((): any => {
    if (ref && ref.current) {
      const { current } = ref;
      if (current.classList.contains(ng.headerFooterClass)) {
        current.style.maxHeight = '320px';
        current.style.overflow = 'hidden';
      }

      const observer = new MutationObserver((records) => {
        if (undoState.unDoing || undoState.reDoing || undoState.backspacing) {
          undoState.unDoing = false;
          undoState.reDoing = false;
          if (undoState.backspacing) {
            records.forEach((record) => {
              const { target, removedNodes } = record;
              removedNodes.forEach((n) => {
                const node = n as HTMLElement;
                if (node.classList.contains(ng.containerClass)) {
                  onDeletingChange({ target: ref.current, state, dispatch });

                  const currentTarget = target as HTMLElement;
                  const thisBodyIdNum = ng.getIdFromString(currentTarget.id);
                  const nextBodyId = ng.generateBodyId(thisBodyIdNum + 1);
                  // const bodyCount = document.querySelectorAll(ng.dotBodyClass).length - 1;
                  const nextBody = document.getElementById(nextBodyId);
                  const nextPageId = ng.generatePageId(thisBodyIdNum + 1);
                  const bodies = document.querySelectorAll(ng.dotBodyClass);
                  const lastBodyId = bodies[bodies.length - 1].id;
                  const isLastBody = nextBodyId === lastBodyId;
                  if (nextBody && isLastBody) {
                    const hasMoveables = nextBody.querySelector(ng.dotMoveableClass);
                    const hasText = nextBody.textContent || (nextBody.textContent && nextBody.textContent.length > 0);
                    if (!(hasText || hasMoveables)) {
                      const lastPage = document.getElementById(nextPageId);
                      if (lastPage) {
                        lastPage.remove();
                      }
                    }
                  }
                }
              });
            });
          }
          return;
        }
        records.forEach((record) => {
          const { addedNodes } = record;
          if (addedNodes.length <= 0) return;
          addedNodes.forEach((node) => {
            if (node && (node as HTMLElement).classList.contains(ng.containerClass)) {
              const container = node as HTMLElement;
              if (container.classList.contains(ng.chartClass)) {
                const chart = JSON.parse(container.dataset.chart || '');
                const data = { ...chart };
                addChart({
                  data, refContainer: container, newInsertion: false, state, dispatch,
                });
              }
              if (container.classList.contains(ng.tableClass)) {
                attachControl({
                  container, table: true, state, dispatch,
                });
              }
              if (container.classList.contains(ng.imageClass)) {
                attachControl({
                  container, image: true, state, dispatch,
                });
              }
              if (container.classList.contains(ng.unformattedClass)) {
                // attachControl({
                //   container, state, dispatch,
                // });
              }
              if (container.classList.contains('derassi-text')) {
                attachControl({
                  container, state, dispatch, text: true,
                });
              }
              if (container.classList.contains(ng.containerClass)) {
                const target = container.closest(ng.dotContentEditableClass);
                onAddingChange({ target, state, dispatch });
              }
            }
          });
        });
      });
      observer.observe(ref.current, {
        childList: true, subtree: false, attributes: false,
      });
      return () => {
        observer.disconnect();
      };
    }
    return null;
  }, []);


  const setHeaderFooter = () => {
    if (ref && ref.current) {
      const header = ref.current.classList.contains(ng.headerClass);
      const footer = ref.current.classList.contains(ng.footerClass);
      const isFirstPage = ng.getIdFromString(ref.current.id) === 0;
      const kase1 = header && isFirstPage;
      const kase2 = footer && isFirstPage;
      const kase3 = header && !isFirstPage;
      const kase4 = footer && !isFirstPage;
      if (kase1) {
        ref.current.innerHTML = derassiHeader.firstPageContent;
      }
      if (kase2) {
        ref.current.innerHTML = derassiFooter.firstPageContent;
      }
      if (kase3) {
        ref.current.innerHTML = derassiHeader.otherPagesContent;
      }
      if (kase4) {
        ref.current.innerHTML = derassiFooter.otherPagesContent;
      }
    }
  };
  /**
   * used to load innerHTML string to DOM when page first loads or ref.current html changes
   */
  React.useEffect(() => {
    if (ref && ref.current) {
      ref.current.innerHTML = innerHTML;
    }
    return (): void => {
      dispatch({ type: stateNames.pageLoading, pageLoading: false });
    };
  }, [innerHTML]);


  React.useEffect(() => {
    // prevetn from running at first load and
    // only run when adding new page
    //  const callTimes = _.keys(pages).length;
    if (ref && ref.current) {
      const count = _.keys(pages).length - 1;
      const thisId = ng.getIdFromString(ref.current.id);
      if (thisId > count) {
        setHeaderFooter();
      }
    }
  }, []);

  return (
    <div
      role="presentation"
      className={klass}
      contentEditable
      style={{ overflow: 'hidden' }}
      id={id}
      ref={ref}
      onKeyDown={(e): void => handleKeyDown(e)}
      onKeyUp={(e): void => handleKeyUp(e)}
      onFocus={(e): void => handleFocus(e)}
      onBlur={(e): void => handleBlur(e)}
      onPaste={(e): void => handlePaste(e)}
      onSelect={(e): void => handleSelect(e)}
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};
