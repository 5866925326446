import html2pdf from 'html2pdf.js';
import devLog from '../../devLog';

export default async (): Promise<Blob | null> => {
  try {
    const element = document.getElementById('derassi-pages');
    if (!element) return null;
    window.scrollTo(0, 0);
    const clone = element.cloneNode(true) as HTMLElement;
    clone.style.transform = 'translate(-40px, 0px)';
    const worker = html2pdf().from(clone);
    const blob = worker.outputPdf('blob', 'application/pdf');
    element.style.position = 'initial';
    return Promise.resolve(blob);
  } catch (err) {
    devLog(err);
    return Promise.reject(err);
  }
};
