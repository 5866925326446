import uniqid from 'uniqid';
import sanitizeHtml from 'sanitize-html';
import { textParent, alertAnswer } from './changeHistory';
import {
  dotContainerClass, paragraphClass,
  dotParagraphClass, containerClass, unformattedClass, moveableClass,
} from '../nameGenerator';
import reEnter from '../components/editor/helpers/reFocus';
import addImage from '../components/editor/helpers/addImage';
import { stateNames } from '..';
import paragraph from './helpers/paragraph';
import { replaceCaret, moveCaretInside } from './helpers/moveFocus';
import splitPara from './helpers/splitPara';
import { translateWords } from '../translate';
import unHighlight from './helpers/unHighlight';

const insertText = (clipText, state) => {
  const { parent, focusOffset, index } = textParent;
  if (!parent) return;
  const span = document.createElement('span');
  span.classList.add('derassi-selected');
  span.innerText = clipText || '';
  const textNode = parent.childNodes[index] as Text;
  const unformatted = parent.closest('.derassi-unformatted');
  const container = parent.closest(dotContainerClass);
  if (textNode && !unformatted) {
    const split = textNode.splitText(focusOffset);
    if (split.parentElement) {
      split.parentElement.insertBefore(span, split);
      replaceCaret(span);
    }
  } else if (container) {
    const para = paragraph(state);
    para.insertAdjacentElement('afterbegin', span);
    container.insertAdjacentElement('afterend', para);
  }
};

export default (props) => {
  const { event, state, dispatch } = props;
  const { lang } = state as InitialState;
  const { parent } = textParent;
  unHighlight();
  if (!event) { // comming from clicking
    navigator.clipboard.readText().then(
      (clipText) => {
        insertText(clipText, state);
      },
    );
    return;
  }

  if (event.preventDefault) {
    event.preventDefault();
  }
  const text = event.clipboardData.getData('text');
  const html = event.clipboardData.getData('text/html');
  const div = document.createElement('div');
  div.innerHTML = sanitizeHtml(html);
  if (!parent) return;
  const doPaste = (ans) => {
    if (ans === translateWords.formatted[lang]) {
      insertText(text, state);
    } else {
      const unformatted = document.createElement('div');
      unformatted.classList.add(unformattedClass, moveableClass, containerClass);
      unformatted.innerHTML = html;
      const currentContainer = parent.closest(dotContainerClass);
      if (currentContainer) {
        currentContainer.insertAdjacentElement('afterend', unformatted);
        replaceCaret(unformatted);
        unformatted.scrollIntoView({ block: 'center', inline: 'center' });
      }
    }
  };

  dispatch({
    type: stateNames.openAlert,
    openAlert: {
      title: translateWords.paste[lang],
      contentText: `${translateWords.paste[lang]} ${translateWords.edit[lang]}`,
      first: translateWords.unformatted[lang],
      second: translateWords.formatted[lang],
      open: true,
      choice: '',
      invoke: doPaste,
    },
  });
};
