import normalizePara from './normalizePara';
import { dotParagraphClass } from '../../nameGenerator';

export default () => {
  const selecteds = document.querySelectorAll('.derassi-selected');
  selecteds.forEach((element) => {
    element.classList.remove('derassi-selected');
    // if (element.classList.length === 0 && element.getAttribute('class') === '') {
    //   const { childNodes } = element;
    //   element.replaceWith(...Array.from(childNodes));
    // }
    const para = element.closest(dotParagraphClass);
    if (para) {
      normalizePara(para);
    }
  });
};
