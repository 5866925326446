import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatStrikethrough from '@material-ui/icons/FormatStrikethrough';
import FormatSize from '@material-ui/icons/FormatSize';
import FormatIndentIncrease from '@material-ui/icons/FormatIndentIncrease';
import FormatIndentDecrease from '@material-ui/icons/FormatIndentDecrease';


import ButtonBase from '@material-ui/core/ButtonBase/ButtonBase';
import Undo from '@material-ui/icons/Undo';
import Redo from '@material-ui/icons/Redo';
import Print from '@material-ui/icons/Print';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import EditFont from './editFont';
import EditSize from './editSize';
import EditList from './EditList';
import EditColor from './editColor';
import EditBorder from './editBorder';
import EditHeading from './editHeading';
import formatDoc from '../../helpers/formatting';
import reEnter from '../../helpers/reFocus';
import { stateNames } from '../../../../index';
import undo from '../../helpers/undo';
import redo from '../../helpers/redo';
import { currentFont, bodyToggle } from '../../../../eventHandlers/changeHistory';


const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    marginBottom: 0,
    marginTop: 0,
    marginLeft: 30,
    '& svg': {
      marginLeft: 5,
      marginRight: 5,
      cursor: 'pointer',
      fontSize: 14,
      color: 'black',
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: 'blue',
      },
    },
    '& hr': {
      height: 10,
      borderLeft: '2px solid gainsboro',
    },
    '& button': {
      textAlign: 'center',
      fontSize: 'medium',
      color: 'black',
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: 'blue',
      },
    },
  },
}));

export default ({ state, dispatch }) => {
  const classes = useStyles();
  const {
    menuFontFamily,
  } = state as InitialState;
  const handleSwitch = () => {
    reEnter();
    bodyToggle.toggle = !bodyToggle.toggle;
    dispatch({ type: stateNames.bodyToggle });
  };
  return (
    <Toolbar variant="dense" className={classes.root}>
      <span style={{ paddingRight: 20 }}>
        <span style={{ fontFamily: menuFontFamily, top: 2 }}>ሀለመ</span>
        <Switch
          centerRipple
          size="small"
          checked={!state.bodyToggle}
          color="default"
          onChange={handleSwitch}
        />
        <span style={{ fontFamily: menuFontFamily, top: 2 }}>abc</span>
      </span>
      <Divider />
      <ButtonBase><Undo onClick={(): any => undo({ state, dispatch })} /></ButtonBase>
      <ButtonBase><Redo onClick={(): any => redo({ state, dispatch })} /></ButtonBase>
      <Divider />
      <ButtonBase><Print onClick={(): void => { reEnter(); window.print(); }} /></ButtonBase>
      <Divider />
      <EditFont state={state} dispatch={dispatch} />
      <Divider />
      <EditSize state={state} dispatch={dispatch} />
      <Divider />
      <EditHeading state={state} dispatch={dispatch} />
      <Divider />
      {/* <ButtonBase><ToUpperCased state={state} /></ButtonBase> */}
      <ButtonBase title="uppercase Geez">
        <FormatSize onClick={(): void => {
          formatDoc({ attr: 'font-family', value: `${currentFont.family} Caped` });
          formatDoc({ klass: 'derassi-caped' });
        }}
        />
      </ButtonBase>
      <Divider />
      <ButtonBase>
        <FormatBoldIcon onClick={(): void => formatDoc({ klass: 'derassi-bold' })} />
      </ButtonBase>
      <ButtonBase>
        <FormatItalicIcon onClick={(): void => formatDoc({ klass: 'derassi-italic' })} />
      </ButtonBase>
      <ButtonBase>
        <FormatUnderlinedIcon onClick={(): void => formatDoc({
          attr: 'text-decoration', value: 'underline',
        })}
        />
      </ButtonBase>
      <ButtonBase>
        <FormatStrikethrough onClick={(): void => formatDoc({
          attr: 'text-decoration', value: 'line-through',
        })}
        />
      </ButtonBase>
      <Divider />
      <ButtonBase title="text color">
        <EditColor cmd="color" state={state} dispatch={dispatch} />
      </ButtonBase>
      <ButtonBase title="background color">
        <EditColor cmd="background-color" state={state} dispatch={dispatch} />
      </ButtonBase>
      <ButtonBase title="forground color">
        <EditColor cmd="forground-color" state={state} dispatch={dispatch} />
      </ButtonBase>
      <Divider />
      <ButtonBase title="add border">
        <EditBorder state={state} dispatch={dispatch} />
      </ButtonBase>
      <ButtonBase title="border color">
        <EditColor cmd="border-color" state={state} dispatch={dispatch} />
      </ButtonBase>
      <Divider />
      <ButtonBase>
        <FormatAlignLeftIcon onClick={(): void => formatDoc({
          attr: 'text-align', value: 'left', fullParagraph: true, state, dispatch,
        })}
        />
      </ButtonBase>
      <ButtonBase>
        <FormatAlignCenterIcon onClick={(): void => formatDoc({
          attr: 'text-align', value: 'center', fullParagraph: true, state, dispatch,
        })}
        />
      </ButtonBase>
      <ButtonBase>
        <FormatAlignRightIcon onClick={(): void => formatDoc({
          attr: 'text-align', value: 'right', fullParagraph: true, state, dispatch,
        })}
        />
      </ButtonBase>
      <ButtonBase>
        <FormatAlignJustify onClick={(): void => formatDoc({
          attr: 'text-align', value: 'justify', fullParagraph: true, state, dispatch,
        })}
        />
      </ButtonBase>
      <ButtonBase>
        <FormatIndentIncrease onClick={(): void => formatDoc({
          attr: 'text-indent', value: '20em', fullParagraph: true, state, dispatch,
        })}
        />
      </ButtonBase>
      <ButtonBase>
        <FormatIndentDecrease onClick={(): void => formatDoc({
          attr: 'text-indent', value: '0em', fullParagraph: true, state, dispatch,
        })}
        />
      </ButtonBase>
      <Divider />
      <ButtonBase><EditList state={state} dispatch={dispatch} /></ButtonBase>
      {/* <ButtonBase><FormatListBulleted onClick={(): void => addList('ul')} /></ButtonBase> */}
    </Toolbar>

  );
};
