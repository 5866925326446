import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {
  FormGroup, DialogContentText,
  TextField, ListItem, ListItemIcon, ListItemText, Grid,
} from '@material-ui/core';
import {
  OpenInBrowser,
} from '@material-ui/icons';
import _ from 'lodash';
import Table from '@material-ui/icons/TableChart';
import { translateWords } from '../../../translate';
import onKeyDown from '../../../eventHandlers/onKeyDown';
import addChart from './addChart';
import { textParent } from '../../../eventHandlers/changeHistory';
import { dotContainerClass, chartClass } from '../../../nameGenerator';
import addTable from './addTable';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    fontFamily: props => props.menuFontFamily,
  },
  find: {
    fontFamily: props => props.menuFontFamily,
    right: 2,
  },
  input: {
    minWidth: 400,
    fontFamily: props => props.menuFontFamily,
  },
}));

const PaperComponent = (props): JSX.Element => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);


export default (props): JSX.Element => {
  const {
    setMenuOpen, name, state, dispatch, closeSubMenu,
  } = props;
  const {
     menuFontFamily, lang,
  } = state as InitialState;
  const [open, setOpen] = React.useState(false);
  // const [editing, setEditing] = React.useState({ isEditing: false, containerId: '' });
  const [table, setTable] = React.useState({
    title: '',
    error: '',
    // existingDatasets: [],
  });
  const labelRef = React.useRef<HTMLInputElement>(null);
  const valueRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (labelRef && labelRef.current) {
      labelRef.current.style.fontFamily = menuFontFamily;
    }
    if (valueRef && valueRef.current) {
      valueRef.current.style.fontFamily = menuFontFamily;
    }
    if (titleRef && titleRef.current) {
      titleRef.current.style.fontFamily = menuFontFamily;
    }
  });

  const classes = useStyles({ menuFontFamily });

  const handleTitle = (event): void => {
    event.stopPropagation();
    onKeyDown({ event, ...props });
    if (titleRef && titleRef.current) {
      setTable({ ...table, title: event.target.value });
    }
  };
  const handleTableClick = (): void => {
    const defaultDataEnglish = {
      rows: [
        ['Title', 'head 1', 'head 2', 'head 3'],
        ['Example 1', 1.0, 2, 3],
        ['Example 2', 'he', 'l', '0o'],
      ],
      caption: 'example table',
    };
    const defaultDataAbesha = {
      rows: [
        ['ርዕስ', 'ላይ 1', 'ላይ 2', 'ላይ 3'],
        ['ምሳሌ 1', 1.0, 2, 3],
        ['ምሳሌ 2', 'ሰ', 'ላ', 'ም'],
      ],
      caption: 'ምሳሌ ሰንጠረዥ',
    };

    const data = lang <= 2 ? defaultDataAbesha : defaultDataEnglish;
    const { parent } = textParent;
    if (parent) {
      const refContainer = parent.closest(dotContainerClass) as HTMLElement;
      addTable({
        refContainer, insertion: 'after', state, dispatch, data,
      });
    }
    setOpen(false);
  };
  const handleClickOpen = (event): void => {
    event.stopPropagation();
    setOpen(true);
    // bring focus out of documents
    if (labelRef && labelRef.current) {
      labelRef.current.focus();
    }
    if (setMenuOpen) {
      setMenuOpen(false);
    }
  };
  const handleClose = (event): void => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleFromComputer = (e) => {
    const csv = e.target.files[0];
    if (csv.text) {
      csv.text().then((res) => {
        const lines = _.split(res, '\n');
        const data = _.map(lines, l => l.split(/[;,]/));
        const newData = {
          rows: data,
          caption: table.title,
        };
        try {
          const { parent } = textParent;
          if (parent) {
            const refContainer = parent.closest(dotContainerClass) as HTMLElement;
            addTable({
              refContainer, insertion: 'after', state, dispatch, data: newData,
            });
            setOpen(false);
          }
        } catch (err) {
          setTable({ ...table, error: 'something went wrong..' });
        }
      });
    }
  };

  return (
    <div>
      <ListItem
        dense
        button
        onClick={handleClickOpen}
        onMouseEnter={closeSubMenu}
      >
        <ListItemIcon><Table /></ListItemIcon>
        <ListItemText>
          <div style={{ fontFamily: menuFontFamily }}>
            { lang <= 2 ? name[lang] : name[lang]}
          </div>
        </ListItemText>
      </ListItem>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div style={{ fontFamily: menuFontFamily }}>
            { lang <= 2 ? name[lang] : name[lang] }
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: menuFontFamily }}>
            { translateWords.pastTable[lang] }
          </DialogContentText>
          <FormGroup>
            <TextField
              inputRef={titleRef}
              onKeyDown={(e): void => handleTitle(e)}
              margin="dense"
              label={(
                <div style={{ fontFamily: menuFontFamily }}>
                  { translateWords.title[lang]
                    }
                </div>
                  )}
              variant="outlined"
            />
          </FormGroup>
          <br />
          <Grid container direction="row">
            <Grid>
              <label htmlFor="fileupload">
                <span
                  className="derassi-upload-button"
                  style={{ fontFamily: menuFontFamily }}
                >
                  { translateWords.fromComputer[lang] }
                  {<OpenInBrowser style={{ transform: 'translate(4px,7px)' }} />}
                </span>
                <input onChange={e => handleFromComputer(e)} id="fileupload" name="fileupload" style={{ opacity: 0, width: 5 }} type="file" accept=".csv" />
              </label>
            </Grid>
            <Grid> </Grid>
            <Grid>
              <Button style={{ textTransform: 'none' }} color="primary" variant="outlined" onClick={handleTableClick}>Add Sample table</Button>
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button className={classes.button} color="secondary" onClick={(e): void => handleClose(e)}>
            { translateWords.cancel[lang]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
